import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { AuthInterceptor } from './_interceptors/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AuthGuard } from './shared/guard/auth.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { FormModule } from './components/forms/forms.module';
import { BaseModule } from './components/bonus-ui/base/base.module';
import { NovaGodinaModalComponent } from './pages/nova-godina-modal/nova-godina-modal.component';
import { ModifiedEntitiesListComponent } from './pages/modified-entity/modified-entities-list/modified-entities-list.component';
import { ModifiedEntitiesPreviewComponent } from './pages/modified-entity/modified-entities-preview/modified-entities-preview.component';
import { DeletedEntitiesListComponent } from './pages/deleted-entity/deleted-entities-list/deleted-entities-list.component';
import { DeletedEntitiesPreviewComponent } from './pages/deleted-entity/deleted-entities-preview/deleted-entities-preview.component';
import { RouterModule, TitleStrategy } from '@angular/router';
import { TemplatePageTitleStrategy } from './_interceptors/templatePageTitleStrategy';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TourNgxBootstrapModule } from 'ngx-ui-tour-ngx-bootstrap';
import { NgxFreshChatModule } from 'src/lib/ngx-freshchat-lib.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    NovaGodinaModalComponent,
    ModifiedEntitiesListComponent,
    ModifiedEntitiesPreviewComponent,
    DeletedEntitiesListComponent,
    DeletedEntitiesPreviewComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BaseModule,
    FormModule,
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,


    ModalModule.forRoot(),
    RouterModule,
    TourNgxBootstrapModule,
    NgxFreshChatModule,
    NgSelectModule,
    CanvasWhiteboardModule,
  ],
  providers: [
    AuthGuard,
    SecureInnerPagesGuard,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },

    /*  provideRouter(
       childRoutes, // Your route configurations
       withViewTransitions() // Enable View Transitions
     ), */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
