import { BaseModel } from "./base.model";
import { DMSFilesDto } from "./dms-files";
import { FiWeDmsTipoviDto } from "./dms-tipovi.model";

export class FiweDmsDto extends BaseModel {
    autoBr: number;
    vrsta: number;
    naziv: string;
    opis: string;
    datum: string;
    komitent: number | null;
    proknjizzeno: boolean;
    fiweDmsFiles: DMSFilesDto[];
    fiWeDmsTipovi: FiWeDmsTipoviDto;
}