<app-breadcrumb [title]="'Accordion'" [items]="['Bonus UI', 'Base']" [active_item]="'Accordion'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Basic Accordion</h5>
        </div>
        <div class="card-body default-accordion">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel title="Simple">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <span>&#9733; <b>Fancy</b> title &#9733;</span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel title="Disabled" [disabled]="true">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>One open panel at a time</h5>
        </div>
        <div class="card-body">
          <ngb-accordion [closeOthers]="true" activeIds="static-1">
            <ngb-panel id="static-1" title="Simple">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-2">
              <ng-template ngbPanelTitle>
                <span>&#9733; <b>Fancy</b> title &#9733;</span>
              </ng-template>
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="static-3" title="Disabled" [disabled]="true">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Toggle panels</h5>
        </div>
        <div class="card-body">
          <ngb-accordion #accT="ngbAccordion">
            <ngb-panel id="toggle-1" title="First panel">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="toggle-2" title="Second">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

          <hr />
          <div class="btn-showcase">
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="accT.toggle('toggle-1')">Toggle first</button>
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="accT.toggle('toggle-2')">Toggle second</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Prevent panel toggle</h5>
        </div>
        <div class="card-body">
          <ngb-accordion (panelChange)="beforeChange($event)">
            <ngb-panel id="preventchange-1" title="Simple">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="preventchange-2" title="I can't be toggled...">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
            <ngb-panel id="preventchange-3" title="I can be opened, but not closed...">
              <ng-template ngbPanelContent>
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia
                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                moon tempor,
                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim
                keffiyeh helvetica,
                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                Leggings
                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
                accusamus
                labore sustainable VHS.
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>
</div>