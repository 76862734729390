import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-touchspin',
  templateUrl: './touchspin.component.html',
  styleUrls: ['./touchspin.component.scss']
})
export class TouchspinComponent implements OnInit {

  @Input() control: UntypedFormControl

  constructor() {

   }

  
  public increment() {
    const value = this.control.value + 1;
    this.control.setValue(value);
  }

  public decrement() {
   if(this.control.value > 1) {
    const value = this.control.value - 1;
    this.control.setValue(value);
   }
  }
  
  ngOnInit(): void {
  }

}
