import { BaseModel } from "./base.model";
import { UntypedFormGroup, Validators } from '@angular/forms';
import { RimVrsteDokumenata } from '../../helper/RimVrsteDokumenata';

export class ArticleModel extends BaseModel {
  [x: string]: any;
  podgrupa: number;
  sifra: number;
  tip: string;
  dobavljaccc: number;
  barKod: string;
  naziv: string;
  katalosskiBrojevi: string;
  katalosskiBroj2: string;
  katalosskiBroj3: string;
  pdvTarifa: string;
  jm: string;
  jmUPakovanju: number;
  tezzina: number;
  minimalnaZaliha: number;
  minimalnaNarudzzba: number;
  status: number;
  created: string
  createdBy: number;
  modified: string;
  modifiedBy: number;
  napomena: string;
  brojDanaIsporuke: number;
  ukupnoNarucccivanje: true;
  stopaOtpisa: number;
  inventurniBroj: string;
  konto: string;
  datumNabavke: string;
  datumOtpisa: string;
  vrsta: number;
  ulaz: number;
  izlaz: number;
  koristiZaCjenovnik: true;
  zapremina: number;
  neRacccunajSskart: true;
  planskaCijena: number;
  godinaProizvodnje: string;
  sirovinskiSastav: string;
  zemljaPorijekla: string;
  cijena: number;
  slika: string;
  izdvojen: true;
  nPodgrupa: string;
  nPDVTarifa: string;
  nDobavljac: string
  kolicccina: number | string;
  deviznaFakturnaCijena: number;
  nabavnaCijena: number;
  cijenaSaRabatom: number;
  artikli: any;
  staraCijena: number;
  cijenaSaPdv: number;
  nArtikal: string;
  cijenaBezPdv: number;
  dodatniRabat: any;
  zavisniTrosskovi: any;
  ruc: any;
  ruc__: any;
  dokument: number;
  ulazniPdv: any;
  ukupnoInfo: any;
  sku: string;
  otpisanaVrijednost: number;
  novaVrijednost: number;

  get value() {
    return this.sifra;
  }

  get label() {
    return this.naziv;
  }

  get lookupString() {
    return `${this.naziv}, ${this.barKod}`
  }

  constructor(attributes?: any) {
    super(attributes);
    if (this.artikli) {
      this.sifra = this.artikli.sifra;
      this.napomena = this.opis;
    }
  }

  static setFormValue(form: UntypedFormGroup, item: ArticleModel): void {
    for (let control in form.controls) {
      form.controls[control].setValue(item[control]);
    }
  }

  getForm(document?: number): UntypedFormGroup {
    return this.fb.group({
      artikal: [this.sifra ? this.sifra : null, Validators.required],
      opis: this.opis ? this.opis : '',
      jm: this.jm ? this.jm : null,
      kolicccina: [this.kolicccina ? this.kolicccina : 1, this.getQuantityValidators(document)],
      ulaz: [this.ulaz ? this.ulaz : 1, this.getInvoiceInputValidators(document)],
      izlaz: [this.izlaz ? this.izlaz : 1, this.getInvoiceOutputValidators(document)],
      staraCijena: this.staraCijena ? this.staraCijena : 0,
      nabavnaCijena: [this.nabavnaCijena ? this.nabavnaCijena : 0, this.getNabavnaCijenaValidators(document)],
      rabat: 0,
      rabat__: 0,
      skonto__: 0,
      cijenaSaRabatom: 0,
      pdv__: 0,
      cijenaBezPdv: [0, [this.isNotZero, Validators.required]],
      cijenaSaPdv: [0, [this.isNotZero, Validators.required]],
      deviznaFakturnaCijena: this.deviznaFakturnaCijena ? this.deviznaFakturnaCijena : 0,
      fakturnaCijena: this.fakturnaCijena ? this.fakturnaCijena : 0,
      fakturnaCijenaSaPdv: this.fakturnaCijenaSaPdv ? this.fakturnaCijenaSaPdv : 0,
      dodatniRabat: this.dodatniRabat ? this.dodatniRabat : 0,
      zavisniTrosskovi: this.zavisniTrosskovi ? this.zavisniTrosskovi : 0,
      ruc: this.ruc ? this.ruc : 0,
      ruc__: this.ruc__ ? this.ruc__ : 0,
      fakturniIznosSaPdv: this.fakturniIznosSaPdv ? this.fakturniIznosSaPdv : 0,
      fakturniIznosBezPdv: this.fakturniIznosBezPdv ? this.fakturniIznosBezPdv : 0,
      dokument: this.dokument ? this.dokument : 0,
      ulazniPdv: this.ulazniPdv ? this.ulazniPdv : 0,
      ukupnoInfo: this.ukupnoInfo ? this.ukupnoInfo : 0,
      sku: this.sku ? this.sku : 0,
      otpisanaVrijednost: [this.otpisanaVrijednost ? this.otpisanaVrijednost : 0],
      novaVrijednost: [this.novaVrijednost ? this.novaVrijednost : 0],
    });
  }

  private isNotZero(control) {
    return !(control.value === 0 || control.value === '0' || !control.value) ? null : {
      isNotZero: {
        valid: false
      }
    };
  }

  private isNotNegative(control) {
    return !(control.value < 0 || control.value < '0') ? null : {
      isNotNegative: {
        valid: false
      }
    };
  }

  private isNegative(control) {
    return (control.value < 0 || control.value < '0') ? null : {
      isNegative: {
        valid: true
      }
    };
  }

  private getQuantityValidators(document) {
    if (document == RimVrsteDokumenata.PROFAKTURA) { // || document == RimVrsteDokumenata.UGOVOR || document == RimVrsteDokumenata.FAKTURA) {
      return [this.isNotNegative, this.isNotZero, Validators.required];
    }
    /*  if (document == RimVrsteDokumenata.STORNO_FAKTURA_AVANS) {
       return [this.isNegative, Validators.required];
     } */
    return [];
  }

  private getInvoiceInputValidators(document) {
    if (document == RimVrsteDokumenata.ULAZNA_KALKULACIJA) {
      return [this.isNotNegative, this.isNotZero, Validators.required];
    }
    if (document == RimVrsteDokumenata.POVRAT_ROBE_DOBAVLJACU) {
      return [this.isNegative, this.isNotZero, Validators.required];
    }
    return [Validators.required];
  }


  private getInvoiceOutputValidators(document) {
    if (document == RimVrsteDokumenata.POVRAT_ROBE_OD_KUPACA) {
      return [this.isNegative, this.isNotZero, Validators.required];
    }
    if (document != 31 && document != 28 && document != 21 && document < 100) {
      return [this.isNotNegative, this.isNotZero, , Validators.required];
    }
    return [Validators.required];
  }

  private getNabavnaCijenaValidators(document) {
    if (document == RimVrsteDokumenata.ULAZNA_KALKULACIJA) {
      return [this.isNotZero, Validators.required];
    }
    return [];
  }

}



export class ArticleTypesModel extends BaseModel {
  key: string;
  value: string;
}

export class VATRatingsModel extends BaseModel {
  tarifniBroj: number;
  stopa: number;
  opis: string;
  ziroRacun: any;
  get lookupString() {
    return `${this.opis}  (${this.stopa} %)`
  }
}
