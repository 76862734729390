export const tooltips = {
    assist: {
        createInvoice: 'Novi dokument može biti dodat samo za trenutnu godinu'
    },
    invoice: {
        invoiceNumber: 'Upišite broj fakture',
        client: '',
        date: '',
        deliveryDate: '',
        dueDate: '',
        paymentType: 1,
        paymentCurrency: 1,
        deliveryNoteDateNumber: '',
        workOrder: '',
        roomNumber: '',
        discount: '',
        item: {
            actions: "Kliknite da bi obrisali željeni item iz fakture"
        },
        toggle: 'Kliknite da bi prikazali dodatna polja'
    }
};