import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();
  text: string;
  confirmed = false;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void { }

  closeModal(value) {
    this.confirmed = value; //koristi se za change guard
    this.updateInputValue.emit(value);
    this.bsModalRef.hide();
  }

}
