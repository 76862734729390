import { BaseModel } from "../base.model";

export class qALL_rpt_FF_NE_OBILASCI_FILTER_2 extends BaseModel {
    aktivan: boolean;
    sifra: number;
    komitent: string;
    identifikacioni_broj: string;
    grad: number;
    nGrad: string;
    obilasciDatum: string;
    tagovi: string;
    posstanski_broj: string;
    adresa: string;
    telefon: string;
    komercijalist: number;
    nKomercijalist: string;
    obilasciTipForme: string;
    notesDatum: string;

    get aktivanFormatted() {
        return (this.aktivan == true) ? "DA" : "NE";
    }

    get tagoviArray() {
        return (this.tagovi == undefined) ? [] : this.tagovi.split(";");
    }

}