<div class="card-body" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <h5 class="mb-3 border-bottom">{{"DASHBOARD.Fiskus field zadatak" | translate}}</h5>
    <form #formElement [formGroup]="form">
        <div class="row">

            <div class="col-12 form-group">
                <label class="font-weight-bold"> {{"PROPERTIES.Komitent" | translate}}
                    <i class="icofont icofont-brand-viber ml-2" (click)="callViber()"> Viber</i>
                    <i class="icofont icofont-brand-whatsapp ml-2" (click)="callWhatsapp()"> Whatsapp</i>
                    <i class="fa fa-phone ml-2" (click)="callPhone()"> {{"Mobilni" | translate }}</i>
                </label>
                <div class="input-group custom-group">
                    <input class="form-control digits" disabled value="{{task?.komitenti?.komitent}}" />
                    <div class="input-group-append">
                        <span class="input-group-text p-0">
                            <button tabindex="-1" (click)="openObilasciKomitent()" type="button"
                                class="btn btn-link btn-sm"
                                [ngbTooltip]="'PROPERTIES.Pregledaj obilaske za komitenta' | translate">
                                <i class="fa fa-angle-double-right txt-primary"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-12 form-group">
                <label class="font-weight-bold"> {{"PROPERTIES.Za korisnika" | translate}} </label>
                <input class="form-control digits" disabled value="{{task?.user}}" />
            </div>

            <div class="col-12 form-group">
                <label class="font-weight-bold"> {{'PROPERTIES.Opis' | translate }} </label>
                <textarea class="form-control digits" disabled rows="4" value="{{task?.opis}}"></textarea>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Od datuma" | translate}} </label>
                    <div class="form-group">
                        <input class="form-control digits" disabled
                            value="{{task.od_datuma | date: 'dd.MM.yyyy. HH:mm:ss':'UTC'}}" />
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Do datuma" | translate}} </label>
                    <div class="form-group">
                        <div>
                            <input class="form-control digits" disabled
                                value="{{task.do_datuma | date: 'dd.MM.yyyy. HH:mm:ss':'UTC'}}" />
                        </div>

                    </div>
                </div>
            </div>



            <div class="col-12 mt-4 text-right">
                <button class="btn btn-light btn-sm" type="button" (click)="forceCloseModal()">
                    {{ "PROPERTIES.Otkazi" | translate }}
                </button>

                @if (task.zavrseno == true) {
                <button class="btn btn-primary btn-sm ml-1" [disabled]="true">
                    {{ "PROPERTIES.Zadatak završen" | translate }} - {{task.zavrsenoDatum | date : 'dd.MM.yyyy HH:mm'}}
                </button>
                }
                @else {
                <button class="btn btn-primary btn-sm ml-1" (click)="zavrsiZadatak()" [hidden]="!isCurrentYear()">
                    {{ "PROPERTIES.Završi" | translate }}
                    @if (saving) {
                    <i class="fa fa-spin fa-spinner"></i>
                    }
                </button>

                <button class="btn btn-primary btn-sm ml-1" (click)="kreirajObilazak()" [hidden]="!isCurrentYear()">
                    <i class="fa fa-plus"></i> {{ "PROPERTIES.Obilazak" | translate }}
                </button>

                <span class="mt-3 mt-lg-0" [hidden]="isCurrentYear()">
                    <a [routerLink]="['/settings']" [queryParams]="{newYear: 'true'}" class="btn btn-primary ml-3"
                        (click)="closeModal()">{{
                        "PROPERTIES.OtvoriPoslovnuGodinu" | translate }} <i class="fa fa-calendar ml-1"></i></a>
                </span>
                }

            </div>
        </div>
    </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <small class="text-danger">{{ message | translate }}</small>
    </ng-container>
</ng-template>