import { ArticleModel } from 'src/app/shared/models/article.model';
import { Invoice, PrometArtikalaDto } from '../models/invoice.model';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { PagedResponse } from '../models/paged-response.model';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { AccountService } from './account.service';
import { RimVrsteDokumenata } from '../../helper/RimVrsteDokumenata';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getAcceptLanguage } from '../helpers/getAcceptLanguage';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private api: BaseApiService,
    private accountService: AccountService,
    private router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }

  addNewInvoice(params: any = {}): Observable<any> {
    return this.api
      .post('rim/fakture/create-faktura', params)
      .pipe(
        catchError(this.handleError<Invoice[]>(`rim/fakture/create-faktura`))
      );
  }

  updateInvoice(params: any = {}, customContext?: any): Observable<any> {
    return this.api
      .post(
        'rim/fakture/update-faktura',
        params,
        {},
        { headers: this.api.getHeaders(customContext) }
      )
      .pipe(
        catchError(this.handleError<Invoice[]>(`rim/fakture/update-faktura`))
      );
  }

  addFisclaNumberToInvoice(params: any = {}): Observable<any> {
    return this.api
      .post('rim/fakture/upisi-fiskalni-broj-na-fakturu', params)
      .pipe(
        catchError(
          this.handleError<Invoice[]>(
            `rim/fakture/upisi-fiskalni-broj-na-fakturu`
          )
        )
      );
  }

  addFisclaNumberToInvoiceRS(params: any = {}): Observable<any> {
    return this.api
      .post('rim/fakture/upisi-fiskalni-broj-na-fakturu-rs', params)
      .pipe(
        catchError(
          this.handleError<Invoice[]>(
            `rim/fakture/upisi-fiskalni-broj-na-fakturu-rs`
          )
        )
      );
  }

  getInvoiceById(autoBr: number, customContext?: any): Observable<any> {
    const options = customContext
      ? { headers: this.api.getHeaders(customContext) }
      : {};
    return this.api
      .post(
        'rim/fakture/fakture-with-podaci-by-autobr',
        { autoBr },
        {},
        options
      )
      .pipe(
        map((res) => {
          const invoice = new Invoice(res.body);
          invoice.prometArtikalaPodaci = invoice.prometArtikalaPodaci.map(
            (i) => new ArticleModel(i)
          );
          return invoice;
        }),
        catchError(
          this.handleError<Invoice[]>(
            `rim/fakture/fakture-with-podaci-by-autobr`
          )
        )
      );
  }

  getPovratByFiskalniBroj(
    fiskalniBroj: number,
    customContext?: any
  ): Observable<any> {
    const options = customContext
      ? { headers: this.api.getHeaders(customContext) }
      : {};
    return this.api
      .post(
        'rim/fakture/povrat-with-podaci-by-fiskalnibroj',
        { fiskalniBroj: fiskalniBroj },
        {},
        options
      )
      .pipe(
        map((res) => {
          const invoice = new Invoice(res.body);
          invoice.prometArtikalaPodaci = invoice.prometArtikalaPodaci.map(
            (i) => new ArticleModel(i)
          );
          return invoice;
        }),
        catchError(
          this.handleError<Invoice[]>(
            `rim/fakture/povrat-with-podaci-by-fiskalnibroj`
          )
        )
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }

  getFakturaByBrojDatumOptremince(params: any): Observable<any> {
    return this.api
      .post('rim/fakture/faktura-by-brojdatumotpremnice', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/faktura-by-brojdatumotpremnice`)));
  }

  getFakturaByObilazak(params: any): Observable<any> {
    return this.api
      .post('rim/fakture/faktura-by-obilazak', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/faktura-by-obilazak`)));
  }

  getNextOrderNumber(params: any) {
    return this.api
      .post('rim/fakture/next-broj', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/next-broj`)));
  }

  sendSingleEmail(params: any) {
    return this.api
      .post('rim/fakture/single-email', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/single-email`)));
  }

  sendMultipleEmails(params: any) {
    return this.api
      .post('rim/fakture/multiple-emails', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/multiple-emails`)));
  }

  getPagedInvoices(params: any): Observable<PagedResponse<PrometArtikalaDto>> {
    return this.api.post('rim/fakture/paged', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<PrometArtikalaDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map(
          (i) => new PrometArtikalaDto(i)
        );
        return pagedModel;
      })
    );
  }

  deleteInvoice(autoBr: number): Observable<any> {
    return this.api
      .post('rim/fakture/delete-faktura', { autoBr })
      .pipe(
        catchError(this.handleError<Invoice[]>(`rim/fakture/delete-faktura`))
      );
  }

  getPdf(invoice: Invoice) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': (invoice.dokument == RimVrsteDokumenata.IZVOZNA_FAKTURA) ? 'en-EN' : 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}rim/fakture/a4-pdf`,
      {
        od: invoice.broj,
        do: invoice.broj,
        dokument: invoice.dokument,
        PJ: invoice.poslovnaJedinica,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getOtpremniListPdf(invoice: Invoice) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': (invoice.dokument == RimVrsteDokumenata.IZVOZNA_FAKTURA) ? 'en-EN' : 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}rim/fakture/otpremni-list-pdf`,
      {
        od: invoice.broj,
        do: invoice.broj,
        dokument: invoice.dokument,
        PJ: invoice.poslovnaJedinica,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getPdfRange(params: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}rim/fakture/a4-pdf`,
      {
        od: params.from,
        do: params.to,
        dokument: params.documentType,
        PJ: params.businessUnit,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  sendInvoiceToReturns(invoiceId: number) {
    return new Observable((subscriber) => {
      this.getInvoiceById(invoiceId).subscribe((res) => {
        this.addNewInvoice(
          this.translateDocumentForReturns(res).createApiObject(true)
        ).subscribe((response) => {
          if (response.body.result) {
            this.toastr.success(this.translate.instant("MESSAGE.Povrat robe uspješno kreiran"));
            subscriber.next(response);
            subscriber.complete();
          }
        });
      });
    });
  }

  private translateDocumentForReturns(invoice: Invoice) {
    invoice.prometArtikalaPodaci.forEach((element, index) => {
      element.dokument = RimVrsteDokumenata.POVRAT_ROBE_OD_KUPACA;
      element.izlaz = element.izlaz * -1;
      element.autoBr = 0;
      element.autoBr_ = 0;
    });

    invoice.datum = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    invoice.autoBr = 0;
    invoice.fakturniIznos *= -1;
    invoice.ukupanIznosIzlaz *= -1;
    invoice.ukupanIznosKolicina *= -1;
    invoice.dokument = RimVrsteDokumenata.POVRAT_ROBE_OD_KUPACA;
    console.log(invoice);
    return invoice;
  }

  getDocumentTypeForDocument(params: any): Observable<any> {
    return this.api
      .post('rim/fakture/document-type-for-document', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/document-type-for-document`)));
  }


  getPdfBosonaSalesReport(payload) {
    var lang = getAcceptLanguage.getLocalStorageLanguage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}rim/fakture/bosona-sales-report-excel`,
      {
        od: payload.od,
        do: payload.do,
        dokument: payload.dokument,
        PJ: payload.poslovnaJedinica,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  fakturaIzProfakture(params: any): Observable<any> {
    return this.api
      .post('rim/fakture/faktura-iz-profakture', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/faktura-iz-profakture`)));
  }

  fakturaIzOtpremnice(params: any): Observable<any> {
    return this.api
      .post('rim/fakture/faktura-iz-otpremnice', params)
      .pipe(catchError(this.handleError<[]>(`rim/fakture/faktura-iz-otpremnice`)));
  }


  updateDostava(params: any = {}): Observable<any> {
    return this.api
      .post('rim/fakture/update-dostava', params)
      .pipe(
        catchError(
          this.handleError<Invoice[]>(
            `rim/fakture/update-dostava`
          )
        )
      );
  }


  getInvoiceExcel(payload) {
    var lang = getAcceptLanguage.getLocalStorageLanguage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}rim/fakture/invoice-list-excel`,
      {
        od: payload.od,
        do: payload.do,
        dokument: payload.dokument,
        PJ: payload.PJ,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  updateDMS(params: any = {}): Observable<any> {
    return this.api
      .post('rim/fakture/update-dms', params)
      .pipe(catchError(this.handleError<[]>('rim/fakture/update-dms')));
  }

}
