import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CanvasWhiteboardComponent, CanvasWhiteboardOptions } from 'ng2-canvas-whiteboard';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { base64ToFile } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { ffTipoviPoljaEnum } from 'src/app/_constants/ff-tipovi-polja-constants';
import { FILE_TYPES } from 'src/app/_constants/fileTypes';
import { MAX_BYTES } from 'src/app/_constants/maxFileSize';
import { AddNewClientComponent } from 'src/app/shared/components/modals/add-new-client/add-new-client.component';
import { TakePictureModalComponent } from 'src/app/shared/components/modals/take-picture-modal/take-picture-modal.component';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { FiskusFieldService } from 'src/app/shared/services/fiskus-field.service';
import { FFObilasciService } from 'src/app/shared/services/fiskusfield/ff-obilasci.service';
import { FfTipoviFormiService } from 'src/app/shared/services/fiskusfield/ff-tipovi-formi.service';
import { GeolocationService } from 'src/app/shared/services/location/geolocation.service';
import { ObilasciArtikliItemFormComponent } from '../obilasci-artikli-item-form/obilasci-artikli-item-form.component';

@Component({
  selector: 'app-ff-add-new-obilazak',
  templateUrl: './ff-add-new-obilazak.component.html',
  styleUrl: './ff-add-new-obilazak.component.scss',
  viewProviders: [CanvasWhiteboardComponent],
})
export class FfAddNewObilazakComponent {
  tipForme;
  forma;
  komitenti;
  komitentiAll;
  tagovi;
  tipoviPolja = ffTipoviPoljaEnum;
  saving;
  taskId = null;
  komitentId = null;
  articles;
  @Input() updateInputValue = new EventEmitter();
  @ViewChild('canvasWhiteboard') canvasWhiteboard: CanvasWhiteboardComponent;
  public files: any[] = [];
  fileTypes = FILE_TYPES;
  maxFilesNumber: number = 10;
  @ViewChild(ObilasciArtikliItemFormComponent) artikliItemForm: ObilasciArtikliItemFormComponent;
  komitentiFiltered: boolean = false;

  canvasOptions: CanvasWhiteboardOptions = {
    drawingEnabled: true,
    shapeSelectorEnabled: false,
    drawButtonEnabled: false,
    drawButtonClass: "drawButtonClass",
    clearButtonEnabled: true,
    clearButtonClass: "clearButtonClass",
    clearButtonText: this.translate.instant("PROPERTIES.Očisti"),
    undoButtonText: this.translate.instant("PROPERTIES.Nazad"),
    undoButtonEnabled: true,
    redoButtonText: this.translate.instant("PROPERTIES.Naprijed"),
    redoButtonEnabled: true,
    lineWidth: 2,
  };

  artikliArray = this.fb.array([]);
  _artikliForm: UntypedFormGroup = this.getartikliForm();

  articleIndex = 1;

  formArray = this.fb.array([]);
  form = new UntypedFormGroup({
    tipForme: new UntypedFormControl(),
    komitent: new UntypedFormControl('', Validators.required),
    longitude: new UntypedFormControl(''),
    latitude: new UntypedFormControl(''),
    checkIn: new UntypedFormControl(''),
    podaci: new UntypedFormControl(''),
    taskId: new UntypedFormControl(null),
    fFObilasciPodaci: this.formArray,
  });

  get itemsFormGroup(): UntypedFormGroup {
    return this._artikliForm;
  }

  getartikliForm() {
    return new UntypedFormGroup({
      artikal: new UntypedFormControl(null, Validators.required),
      kolicccina: new UntypedFormControl(1, Validators.required),
    });
  }

  constructor(
    public bsModalRef: BsModalRef,
    private ffTipoviFormiService: FfTipoviFormiService,
    private fb: UntypedFormBuilder,
    private clientService: ClientService,
    private fiskusFieldService: FiskusFieldService,
    private translate: TranslateService,
    private ffObilasciService: FFObilasciService,
    private locatioService: GeolocationService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private articlesService: ArticlesService,

  ) { }

  ngOnInit(): void {
    this.form.controls.tipForme.setValue(this.tipForme);
    this.tipFormeById();
    this.getKomitente(false); //koriste se komitenti u blizini
    this.getTagove();
    this.getCurrentLocation();
    this.getAllArticles();

    if (this.taskId != null) {
      this.form.controls.taskId.setValue(this.taskId);
    }
    if (this.komitentId != null) {
      this.form.controls.komitent.setValue(this.komitentId);
    }
  }

  async getCurrentLocation() {
    const position: any = await this.locatioService.getCurrentLocation();
    this.form.controls.latitude.setValue(position.lat);
    this.form.controls.longitude.setValue(position.lng);

    this.getKomitenteNearby();
  }

  getAllArticles() {
    this.articlesService.getAllArticles().subscribe(resp => {
      this.articles = resp;

      this.articles = this.articles.map((o) => {
        o.search_label = ` ${o.sifra!} ${o.barKod!} ${o.naziv!} ${o.katalosskiBrojevi!} ${o.katalosskiBroj2!}
         ${o.katalosskiBroj3!} ${o.nPodgrupa!} ${o.nPDVTarifa!} ${o.nDobavljac!} `;
        return o;
      });
    })
  }

  getTagove() {
    this.fiskusFieldService.getTagoveZaFirmu().subscribe(resp => {
      this.tagovi = resp;
    })
  }

  getKomitente(added) {
    this.clientService.getAllClients().subscribe(resp => {
      this.komitentiAll = resp;
      if (added) {
        this.komitenti = resp;
      }
    })
  }

  getKomitenteNearby() {
    var payload = {
      latitude: this.form.controls.latitude.value,
      longitude: this.form.controls.longitude.value
    }
    this.clientService.getNearby(payload).subscribe(resp => {
      this.komitenti = resp.body.result;
    })
  }

  tipFormeById() {
    this.ffTipoviFormiService.getBySifra(this.tipForme).subscribe(resp => {
      this.forma = resp;
      this.addFormControls();
    })
  }

  addFormControls() {
    this.forma.ffTipoviFormiPodaci.forEach(element => {
      const data = this.fb.group({});

      var dropDown: string[] = [];
      var vrijednost;
      if (element.polja.tipPolja == this.tipoviPolja.DropDown) {
        dropDown = element.nazivPolja.split(";");
      }
      else if (element.polja.tipPolja == this.tipoviPolja.Datum) {
        vrijednost = formatDate(new Date(Date.now()), 'yyyy-MM-dd HH:mm', 'en');
      }
      else if (element.polja.tipPolja == this.tipoviPolja.Boolean) {
        vrijednost = false;
      }
      else {
        vrijednost = "";
      }

      data.addControl("naziv", new UntypedFormControl((element.polja.tipPolja != this.tipoviPolja.DropDown) ? element.nazivPolja : element.polja.naziv));
      data.addControl("obavezno", new UntypedFormControl(element.obaveznoPolje));
      data.addControl("tipPolja", new UntypedFormControl(element.polja.tipPolja));
      data.addControl("polje", new UntypedFormControl(element.polja.sifra));
      data.addControl("vrijednost", new UntypedFormControl(vrijednost, (element.obaveznoPolje == true &&
        element.polja.tipPolja != this.tipoviPolja.Potpis &&
        element.polja.tipPolja != this.tipoviPolja.Fotografija &&
        element.polja.tipPolja != this.tipoviPolja.Artikli) ? Validators.required : null));
      data.addControl("potpis", new UntypedFormControl(''));
      data.addControl("dropDown", new UntypedFormControl(dropDown));

      this.formArray.push(data);
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  forceCloseModal() {
    this.updateInputValue.emit(false);
    this.bsModalRef.hide();
  }

  onSubmit() {
    //Returns base64 string representation of the canvas
    let potpisBase64 = this.canvasWhiteboard?.generateCanvasDataUrl("image/jpeg", 1);
    for (let control of this.formArray.controls) {
      if (control.value.tipPolja == this.tipoviPolja.Potpis) {
        control.value.potpis = potpisBase64;
      }
      if (control.value.vrijednost == true || control.value.vrijednost == false) {
        control.value.vrijednost = control.value.vrijednost.toString();
      }

      if (control.value.tipPolja == this.tipoviPolja.Tag) {
        if (control.value.vrijednost != '') {
          var value = control.value.vrijednost?.map(x => x)?.join(";")
          control.value.vrijednost = value;
        }
      }

      if (control.value.tipPolja == this.tipoviPolja.Artikli) {
        if (this.artikliArray.length == 0) {
          if (control.value?.obavezno == true) {
            this.toastr.error(this.translate.instant("MESSAGE.Potrebno unijeti barem jedan artikal"))
            return;
          }
        }
        else {
          for (let i = 0; i < this.form.controls.fFObilasciPodaci.value.length; i++) { // 'field' is a string
            var controlVal = this.form.controls.fFObilasciPodaci.value[i];
            if (controlVal) {
              if (controlVal.tipPolja == this.tipoviPolja.Artikli) {
                var val = JSON.stringify(this.artikliArray.value);
                controlVal.vrijednost = val;
              }
            }
          }
        }
      }
    }

    if (this?.artikliItemForm) {
      this.artikliItemForm?.clearItemForm();
    }

    if (this.form.valid) {

      this.saving = true;
      var podaci = JSON.stringify(this.form.controls.fFObilasciPodaci.value);
      this.form.controls.podaci.setValue(podaci);

      var mappedFiles = this.files.map(x => { return x.file });
      this.ffObilasciService.create(this.form.value, mappedFiles).subscribe(resp => {
        this.saving = false;
        this.toastr.success(this.translate.instant("MESSAGE.Forma uspješno kreirana"))
        this.updateInputValue.emit(resp.body.result);
        this.closeModal();
      }, error => {
        this.saving = false;
      })
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  openWebcam() {
    if (this.files.length >= this.maxFilesNumber) {
      this.toastr.error(this.translate.instant("MESSAGE.Maksimalan broj dozvoljenih dokumenata je 10"));
      return;
    }

    const initialState: ModalOptions = {
      initialState: {
      },
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    };
    this.bsModalRef = this.modalService.show(TakePictureModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        var phonoName = this.translate.instant("PROPERTIES.Fotografija") + "-" + (this.files.length + 1) + ".png";
        const imagePath = base64ToFile(res?.imageAsDataUrl);
        var imgFile = new File([imagePath], phonoName, {
          type: imagePath.type
        });

        var payload = {
          file: imgFile,
          base64: res?.imageAsDataUrl
        }
        this.files.push(payload);
      }
    });
  }

  removeFile(item) {
    this.files.splice(this.files.indexOf(item), 1);
  }

  onFileChange($event) {
    var file = $event.target.files[0];

    if (this.files.length >= this.maxFilesNumber) {
      this.toastr.error(this.translate.instant("MESSAGE.Maksimalan broj dozvoljenih dokumenata je 10"));
      return;
    }

    if (file.size > MAX_BYTES) {
      this.toastr.error(this.translate.instant("MESSAGE.Maksimalna dopuštena veličina dokumenta je 5MB"));
      return;
    }

    var isAlowed = this.fileTypes.find(x => x.value == file.type);
    if (isAlowed) {
      var payload = {
        file: file,
        base64: ""
      }
      this.files.push(payload);
    }
    else {
      this.toastr.error(this.translate.instant("MESSAGE.Tip dokumenta nije podržan"));
      return;
    }
  }

  saveItem(e) {
    if (e.updatingItem !== -1) {
      this.artikliArray[e.updatingItem] = e.item;
    } else {
      this.artikliArray.push(e.item);
      this._artikliForm = this.getartikliForm();
      this.articleIndex += 1;
      setTimeout(() => {
        const table = document.querySelector('.table');
        table.scrollTo(0, table.scrollHeight);
      }, 250);
    }
  }

  findArticleName(sifra: number): string {
    return this.articles.find((article) => article.sifra == sifra)?.naziv;
  }

  deleteItem(i: number) {
    this.articleIndex -= 1;
    if (this.articleIndex < 0) this.articleIndex = 0;
    this.artikliArray.removeAt(i);
  }

  openAddNewClientModal() {
    const initialState: ModalOptions = {
      /* initialState: {
      }, */
      class: "modal-dialog-centered modal-lg"
    };
    this.bsModalRef = this.modalService.show(AddNewClientComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.getKomitente(true);
        this.form.controls.komitent.setValue(res);
      }
    });
  }

  komitentiSearchActivated($event) {
    if (this.komitentiFiltered == false) {
      this.komitentiFiltered = true;
      this.komitenti = this.komitentiAll;
    }
  }

}
