import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FormHelper } from '../helpers/form.helper';
import * as appconstatnts from 'src/app/_constants/appMessages';
import { getAcceptLanguage } from '../helpers/getAcceptLanguage';
import { AccountGlobalService } from './account-global.service';

const CONTEXT_KEY = 'userContext';
const CONTEXT_FIRM_DATA = 'contextFirmData';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  protected constructor(
    private http: HttpClient,
    private account: AccountGlobalService
  ) { }

  public newRequest(
    method: string,
    path: string = '',
    body = {} as any,
    queryParams: any = {},
    options: any = {}
  ): Observable<any> {
    if (body && Object.keys(body).length !== 0) {
      return this.http[method]<any>(`${path}`, body, {
        params: queryParams,
        ...options,
      } as any);
    } else {
      return this.http[method](`${path}`, {
        params: queryParams,
        ...options,
      } as any);
    }
  }

  public get(
    path: string = '',
    queryParams: any = {},
    options: any = {}
  ): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, {
      headers: this.getHeaders(),
      observe: 'response',
      params: queryParams,
      ...options
    });
  }

  public getCustom(path: string = '', firmaId, queryParams: any = {}, options: any = {}): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, {
      headers: this.getHeadersCustomFirma(firmaId),
      observe: 'response',
      params: queryParams,
      ...options
    });
  }

  public getHub(
    path: string = '',
    queryParams: any = {},
    options: any = {}
  ): Observable<any> {
    return this.http.get(`${environment.hubUrl}${path}`, {
      headers: this.getHeaders(),
      observe: 'response',
      params: queryParams,
      ...options
    });
  }

  public post(
    path: string,
    body: any,
    queryParams: any = {},
    options = {}
  ): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}${path}`, body, {
      headers: this.getHeaders(),
      observe: 'response',
      params: queryParams,
      ...options,
    });
  }

  public postCustom(path: string, body: any, firmaId, queryParams: any = {}, options = {}): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}${path}`, body, {
      headers: this.getHeadersCustomFirma(firmaId),
      observe: 'response',
      params: queryParams,
      ...options,
    });
  }

  public postFormData(
    path: string,
    body: any,
    queryParams: any = {},
    options = {}
  ): Observable<HttpResponse<any>> {
    const formData = FormHelper.generateFormData(body);
    let headers = this.getHeaders();
    headers = headers.delete('Content-Type', 'application/json')
    return this.http.post(`${environment.apiUrl}${path}`, formData, {
      headers,
      observe: 'response',
      params: queryParams,
      ...options,
    });
  }

  public postFormDataMultipleFiles(
    path: string,
    body: any,
    files,
    queryParams: any = {},
    options = {}
  ): Observable<HttpResponse<any>> {
    const formData = FormHelper.generateFormData(body);
    files.forEach((file) => { formData.append('files', file); });
    
    let headers = this.getHeaders();
    headers = headers.delete('Content-Type', 'application/json')
    return this.http.post(`${environment.apiUrl}${path}`, formData, {
      headers,
      observe: 'response',
      params: queryParams,
      ...options,
    });
  }

  public putFormData(
    path: string,
    body: any,
    queryParams: any = {},
    options = {}
  ): Observable<HttpResponse<any>> {
    const formData = FormHelper.generateFormData(body);
    let headers = this.getHeaders();
    headers = headers.delete('Content-Type', 'application/json')
    return this.http.put(`${environment.apiUrl}${path}`, formData, {
      headers,
      observe: 'response',
      params: queryParams,
      ...options,
    });
  }

  public put(
    path: string = '',
    body: any,
    queryParams: any = {}
  ): Observable<HttpResponse<any>> {
    return this.http.put(`${environment.apiUrl}${path}`, body, {
      headers: this.getHeaders(),
      observe: 'response',
      params: queryParams,
    });
  }

  public delete(
    path: string = '',
    queryParams: any = {}
  ): Observable<HttpResponse<any>> {
    return this.http.delete(`${environment.apiUrl}${path}`, {
      headers: this.getHeaders(),
      observe: 'response',
      params: queryParams,
    });
  }

  public getHeaders(customContext?: any): HttpHeaders {
    let context = customContext || this.account.context;
    if (!context) {
      const contextString = localStorage.getItem(CONTEXT_KEY);
      if (contextString) {
        context = JSON.parse(contextString);
      }
    }
    var lang = getAcceptLanguage.getLocalStorageLanguage();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: context?.organizationId?.toString() || '',
      godina: context?.year?.toString() || '',
      poslovnajedinica: context?.businessUnit?.toString() || '',
    });
    let auth = this.account.accessToken;
    if (!auth) {
      const storage = localStorage.getItem(appconstatnts.accessToken);
      if (storage) {
        auth = JSON.parse(storage);
      }
    }

    if (auth && auth.token) {
      headers = headers.append('Authorization', 'Bearer ' + auth.token);
    }
    return headers;
  }


  public getHeadersCustomFirma(firmaId: number, customContext?: any): HttpHeaders {
    let context = customContext || this.account.context;
    if (!context) {
      const contextString = localStorage.getItem(CONTEXT_KEY);
      if (contextString) {
        context = JSON.parse(contextString);
      }
    }
    var lang = getAcceptLanguage.getLocalStorageLanguage();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: firmaId.toString(),
      godina: context?.year?.toString() || '',
      poslovnajedinica: context?.businessUnit?.toString() || '',
    });
    let auth = this.account.accessToken;
    if (!auth) {
      const storage = localStorage.getItem(appconstatnts.accessToken);
      if (storage) {
        auth = JSON.parse(storage);
      }
    }

    if (auth && auth.token) {
      headers = headers.append('Authorization', 'Bearer ' + auth.token);
    }
    return headers;
  }

  public get attachmentHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    let auth = this.account.accessToken;
    if (!auth) {
      const storage = localStorage.getItem(appconstatnts.accessToken);
      if (storage) {
        auth = JSON.parse(storage);
      }
    }

    if (auth && auth.token) {
      headers = headers.append('Authorization', 'Bearer ' + auth.token);
    }
    return headers;
  }
}
