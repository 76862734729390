import { Component, Input, OnInit } from '@angular/core';
import { tooltips } from './tooltips';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input() key: string;

  tooltip: string;

  constructor() {
  }

  ngOnInit(): void {
    this.tooltip = this.getTooltipForKey();
  }

  private getTooltipForKey() {
    let sub: any = tooltips;
    for (let k of this.key.split('.')) {
      if (sub[k] instanceof Object) {
        sub = sub[k];
      } else {
        return sub[k];
      }
    }

    return 'No hint available.';
  }

}
