import { AccountService } from 'src/app/shared/services/account.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PaymentInfoService } from 'src/app/shared/services/payment-info.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { FISCAL_DEVICE_ERROR } from '../../../../../_constants/appMessages';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-fiscalization-modal',
  templateUrl: './fiscalization-modal.component.html',
  styleUrls: ['./fiscalization-modal.component.scss'],
})
export class FiscalizationModalComponent implements OnInit, OnDestroy {
  paymentMethods;
  formatedFromDateTime;
  formatedToDateTime;
  loading = {};

  todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  todayMidnightFormatted = moment(this.todayMidnight).format(
    'YYYY-MM-DDTHH:mm'
  );
  todayDayEnd = new Date(new Date().setHours(23, 59, 59, 0));
  todayDayEndFormatted = moment(this.todayDayEnd).format(
    'YYYY-MM-DDTHH:mm'
  );

  private cdrInterval;


  constructor(
    private paymentInfoService: PaymentInfoService,
    private printService: PrintService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private accountService: AccountService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
  ) { }

  roleClaims = this.accountService.roleClaims;

  isAuthorized(claim) {
    if (this.roleClaims.includes(`permission.${claim}`)) {
      return true;
    } else {
      return false;
    }
  }

  requestTypes = [
    {
      id: 3,
      label: 'Fiskalni račun',
      NSCValue: 'F',
      printCommand: 'stampatiduplikatfiskalnogracuna',
    },
    {
      id: 4,
      label: 'Reklamirani račun',
      NSCValue: 'R',
      printCommand: 'stampatiduplikatreklamiranogracuna',
    },
    {
      id: 8,
      label: 'Dnevni izvještaj',
      NSCValue: 'Z',
      printCommand: 'stampatiduplikatdnevnogizvjestaja',
    },
    {
      id: 6,
      label: 'Presjek stanja',
      NSCValue: 'X',
      printCommand: 'stampatiduplikatpresjekastanja',
    },
    {
      id: 10,
      label: 'Periodični izvještaj',
      NSCValue: 'P',
      printCommand: 'stampatiduplikatperiodicnogizvjestaja',
    },
  ];

  documentCopyform = new UntypedFormGroup({
    documentType: new UntypedFormControl('', Validators.required),
    documentNumber: new UntypedFormControl('', Validators.required),
  });

  importMoneyform = new UntypedFormGroup({
    value: new UntypedFormControl('', Validators.required),
    paymentMethod: new UntypedFormControl('GOTOVINA', Validators.required),
  });

  exportMoneyform = new UntypedFormGroup({
    value: new UntypedFormControl('', Validators.required),
    paymentMethod: new UntypedFormControl('GOTOVINA', Validators.required),
  });

  periodicReportForm = new UntypedFormGroup({
    from: new UntypedFormControl(this.todayMidnightFormatted, Validators.required),
    to: new UntypedFormControl(this.todayDayEndFormatted, Validators.required),
  });

  ngOnInit(): void {
    this.getPaymentMethods();
    // FIXME: Change detection won't fire in dynamically generated components. So as a workaround we call it in intervals
    this.cdrInterval = setInterval(() => {
      this.cdr.detectChanges();
    }, 1000);

  }

  ngOnDestroy(): void {
    clearInterval(this.cdrInterval);
  }

  getPaymentMethods() {
    this.paymentInfoService.getAllPaymentMethods().subscribe((res) => {
      this.paymentMethods = res;
      let indexOfCheck = this.paymentMethods.findIndex(function (item, index) {
        return item.nacccinPlaccanja === 'ČEK' ? true : false;
      });
      this.paymentMethods.splice(indexOfCheck, 1);
    });
  }

  onSubmitimportMoneyform() {
    console.table(this.importMoneyform.controls.value)
    if (this.importMoneyform.valid) {
      this.importMoneyform.controls.paymentMethod.setValue(
        this.importMoneyform.controls.paymentMethod.value.toLowerCase()
      );
      let paymentMethod =
        this.importMoneyform.controls.paymentMethod.value
          .charAt(0)
          .toUpperCase() +
        this.importMoneyform.controls.paymentMethod.value.slice(1);
      let value = this.importMoneyform.controls.value.value;
      let printCommand = 'unosnovca';

      this.loading['moneyImport'] = true;
      this.printService.printMoneyImport(value, paymentMethod, printCommand).subscribe(response => {
        this.loading['moneyImport'] = false;
        if (response.e) {
          this.toastr.error(FISCAL_DEVICE_ERROR);
          return;
        }
        this.closeModal();
      });
    } else {
      this.importMoneyform.markAllAsTouched();
    }
  }

  onSubmitExportMoneyform() {
    if (this.exportMoneyform.valid) {
      this.exportMoneyform.controls.paymentMethod.setValue(
        this.exportMoneyform.controls.paymentMethod.value.toLowerCase()
      );
      let paymentMethod =
        this.exportMoneyform.controls.paymentMethod.value
          .charAt(0)
          .toUpperCase() +
        this.exportMoneyform.controls.paymentMethod.value.slice(1);
      let value = this.exportMoneyform.controls.value.value;
      let printCommand = 'povratnovca';
      this.loading['moneyExport'] = true;
      this.printService.printMoneyExport(value, paymentMethod, printCommand).subscribe(response => {
        this.loading['moneyExport'] = false;
        if (response.e) {
          this.toastr.error(FISCAL_DEVICE_ERROR);
          return;
        }
        this.closeModal();
      });
    } else {
      this.exportMoneyform.markAllAsTouched();
    }
  }

  onSubmitPeriodicReport() {
    if (this.periodicReportForm.valid) {
      let fromDateTime = this.periodicReportForm.controls.from.value;
      this.formatedFromDateTime = moment(fromDateTime).format(
        'DD-MM-YYYY HH:MM:SS'
      );
      let toDateTime = this.periodicReportForm.controls.to.value;
      this.formatedToDateTime = moment(toDateTime).format(
        'DD-MM-YYYY HH:MM:SS'
      );
      this.loading['periodic'] = true;
      this.printService.printPeriodicReport(
        this.formatedFromDateTime,
        this.formatedToDateTime
      ).subscribe(response => {
        this.loading['periodic'] = false;
        if (response.e) {
          this.toastr.error(FISCAL_DEVICE_ERROR);
          return;
        }
        this.closeModal();
      });
    } else {
      this.periodicReportForm.markAllAsTouched();
    }
  }

  onDocumentCopySubmit() {
    if (this.documentCopyform.valid) {
      const requestType = this.documentCopyform.controls.documentType.value;
      const documentNumber =
        this.documentCopyform.controls.documentNumber.value;
      const printCmd = this.requestTypes.find(
        (type) => type.id == this.documentCopyform.controls.documentType.value.id
      )?.printCommand;
      this.loading['copy'] = true;
      this.printService.printDocumentCopy(
        requestType,
        documentNumber,
        printCmd
      ).subscribe(response => {
        this.loading['copy'] = false;
        if (response.e) {
          this.toastr.error(FISCAL_DEVICE_ERROR);
          return;
        }
        this.closeModal();
      });
    } else {
      this.documentCopyform.markAllAsTouched();
    }
  }

  printOverview() {
    this.loading['overview'] = true;
    this.printService.printOverview().subscribe(response => {
      this.loading['overview'] = false;
      if (response.e) {
        this.toastr.error(FISCAL_DEVICE_ERROR);
        return;
      }
      this.closeModal();
    });
  }

  printDailyReport() {
    const initialState: ModalOptions = {
      initialState: {
        text: this.translate.instant("PROPERTIES.Da li ste sigurni da zelite da odštampate dnevni izvještaj?")
      },
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.loading['dailyReport'] = true;
        this.printService.printDailyReport().subscribe(response => {
          this.loading['dailyReport'] = false;
          if (response.e) {
            this.toastr.error(FISCAL_DEVICE_ERROR);
            this.cdr.detectChanges();
            return;
          }
          this.closeModal();
        });
      }
    });
  }

  prekiniRacun() {
    this.printService.printCancelInvoice().subscribe(resp => {
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
