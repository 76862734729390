const items: ClipboardTypesDto[] = [
    { value: 0, label: "Žiro računi" },
    { value: 1, label: "Artikli" },
    { value: 2, label: "Komitenti" },
    { value: 3, label: "Kontni plan" },
    { value: 4, label: "Faktura" },
    { value: 5, label: "Profaktura" },
    { value: 6, label: "Sheme knjiženja" }
];

export enum ClipboardTypesEnum {
    ZiroRacuni = 0,
    Artikli = 1,
    Komitenti = 2,
    KontniPlan = 3,
    Faktura = 4,
    Profaktura = 5,
    SemeKnjizenja = 6
}

export class ClipboardTypesDto {
    value: number;
    label: string;
}

export class ClipboardTypesArray {
    array: ClipboardTypesDto[] = [];
    constructor() {
        this.array = items;
    }
}