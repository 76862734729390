<app-breadcrumb [title]="'Form Wizard Four'" [items]="['Forms', 'Form Layout']" [active_item]="'Form Wizard Four'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Form Vertical Wizard</h5>
        </div>
        <div class="card-body">
        
          <!-- Smart Wizard Ends-->
        </div>
      </div>
    </div>
  </div>
</div>