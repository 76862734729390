import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    public accountService: AccountService,
    public router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuth();
  }


  checkAuth() {
    // Guard for user is login or not
    let user = this.accountService.currentUser
    if (!user || user === null) {
      this.router.navigate(['/authentication/login']).then();
      return true;
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/authentication/login']).then();
        return true;
      }
    }
    return true;
  }

}
