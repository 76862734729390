import { UntypedFormBuilder } from '@angular/forms';

export class BaseModel {

  protected fb = new UntypedFormBuilder();

    constructor(attributes?: any) {
        this.setAttributes(this.initialValues);
        if (attributes) {
            this.setAttributes(attributes);
        }
    }

    get initialValues() {
        return {};
    }

    get childModels() {
        return {};
    }

    get unsafeApiAttributes(): string[] {
        return ['sifra', 'fb'];
    }

    setAttributes(input: any): this {
        Object.assign(this, input);

        for (const attribute of Object.keys(this.childModels)) {
            const Model = this.childModels[attribute];
            let value = input[attribute];

            if (value !== undefined) {
                if (this.isArray(value)) {
                    value = value.map(item => new Model(item));
                } else if (this.isObject(value)) {
                    value = new Model(value);
                }
                this[attribute] = value;
            }
        }

        return this;
    }

    toApi(model?: this) {
        const apiArray = {};
        const object = model || this;

        for (const [k, v] of Object.entries(object)) {
            const isSafeAttribute = this.isFunction(object.isSafeAttribute) ? object.isSafeAttribute(k) : true;
            if (v !== undefined && isSafeAttribute) {
                if (this.isArray(v)) {
                    apiArray[k] = v.map(item => this.toApi(item));
                } else if (this.isObject(v)) {
                    const Model = this.childModels[k];
                    if (Model !== undefined) {
                        apiArray[k] = new Model(v).toApi();
                    } else {
                        apiArray[k] = this.toApi(v);
                    }
                } else {
                    apiArray[k] = v;
                }
            }
        }

        return apiArray;
    }

    private isSafeAttribute(attribute: string): boolean {
        return this.unsafeApiAttributes.indexOf(attribute) === -1;
    }

    private keysToCamel(o: any) {
        if (this.isObject(o)) {
            const n = {};

            Object.keys(o).forEach((k) => {
                n[this.toCamel(k)] = this.keysToCamel(o[k]);
            });

            return n;
        } else if (this.isArray(o)) {
            return o.map((i) => {
                return this.keysToCamel(i);
            });
        }

        return o;
    }

    private keysToUndercore(o: any) {
        if (this.isObject(o)) {
            const n = {};

            Object.keys(o).forEach((k) => {
                n[this.toUnderscore(k)] = this.keysToUndercore(o[k]);
            });

            return n;
        } else if (this.isArray(o)) {
            return o.map((i) => {
                return this.keysToUndercore(i);
            });
        }

        return o;
    }

    private toCamel(s: string) {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    }

    private toUnderscore(s: string) {
        return s.split(/(?=[A-Z])/).join('_').toLowerCase();
    }

    private isArray(a: any) {
        return Array.isArray(a);
    }

    private isObject(o: any) {
        return o === Object(o) && !this.isArray(o) && typeof o !== 'function';
    }

    private isFunction(f: any) {
        return typeof f === 'function';
    }
}
