import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  hubUrl = environment.hubUrl;
  private hubConnection: HubConnection;
 // private onlineUSersSource = new BehaviorSubject<string[]>([]);
 // onlineUsers$ = this.onlineUSersSource.asObservable();

  constructor(private toastr: ToastrService,
    private api: BaseApiService) { }

  createHubConnection(result: any) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl + 'presence', {
        accessTokenFactory: () => result.accessToken.token
      })
      .withAutomaticReconnect()
      .build()

    this.hubConnection.start()
      .catch(error => {
        console.log(error)
      }
      );

    this.hubConnection.on("UserIsOnline", username => {
      this.toastr.info(username + ' je spojen');
    })

    this.hubConnection.on("UserIsOffline", username => {
      this.toastr.warning(username + ' je odspojen')
    })
  }

  stopHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(
        error => console.log(error)
      );
    }
  }
 
}
