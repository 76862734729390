import { AfterViewInit, ChangeDetectorRef, Directive, HostListener } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[focusInputAfterSubmit]'
})
export class SelectFirstInputDirective implements AfterViewInit {

  get form(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }

  constructor(
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef
  ) {
  }


  ngAfterViewInit() {
  }

  @HostListener('ngSubmit', ['$event', 'form'])
  public keyPress(e) {
    this.focusInput(e);
  }

  focusInput(e) {
    let input = e.target.querySelector('input');
    if (input) {
      input.focus();
    }
    this.cdr.detectChanges();
    /* setTimeout(() => {
      if (input) {
        input.blur();
        for (let name in this.form.controls) {
          this.form.controls[name].markAsUntouched();
          this.form.controls[name].markAsPristine();
        }
      }
    }, 5000); */
  }
}
