import { BaseModel } from './base.model';

export class PagedResponse<T> extends BaseModel {
  currentPage: number;
  endPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: T[];
  pageCount: number;
  pageSize: number;
  rowCount: number;
  startPage: number;

}
