import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input'
})
export class SelectInputContentOnFocusDirective {

  constructor() { }

  @HostListener('focus', ['$event']) public focus(e) {
    e.target.select();
  }

}
