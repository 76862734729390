<div class="container-fluid mb-2">
    <div class="row mt-2">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="mb-4 h6">{{'PROPERTIES.Neproknjiženi dokumenti' | translate}}</div>
 
                    <div class="table-responsive invoice-table" id="table">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Firma" | translate}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Adresa" | translate}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="h6 p-2 mb-0 d-flex align-items-center">
                                            {{"PROPERTIES.Broj dokumenata" | translate}}
                                        </div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of documents; index as i" class="dms-lists">
                                    <td (click)="firmaClicked(item)">
                                        <p class="itemtext">{{ item?.naziv }}</p>
                                    </td>

                                    <td (click)="firmaClicked(item)">
                                        <p class="itemtext">{{ item?.firmeInformacije?.adresa }}</p>
                                    </td>

                                    <td (click)="firmaClicked(item)">
                                        <p class="itemtext">{{ item?.unregistered }}
                                            @if (item?.error) {
                                            {{ item?.error }}
                                            }
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div *ngIf="loading" class="loader-box">
                            <div class="loader-2"></div>
                        </div>

                        @if (showZatvori) {
                        <div class="col-12 mt-4 text-right">
                            <button class="btn btn-light btn-sm" type="button" (click)="forceCloseModal()">
                                {{ "PROPERTIES.Zatvori" | translate }}
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>