<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex">
        <h3 class="d-inline">{{ title }}</h3>
        <button
          *ngIf="buttonText || buttonIcon"
          class="btn btn-{{buttonColor}} pull-right ml-3"
          type="button"
          (click)="emmitClick()"
        >
          {{ buttonText | translate }}
          <i *ngIf="buttonIcon" class="fa {{ buttonIcon }} fa-lg fa-fw"></i>
        </button>
        <span [disableTooltip]="!disabled" ngbTooltip="{{ tooltipText }}" placement="top">
          <a
            [class.disabled]="disabled"
            *ngIf="link"
            class="btn btn-primary ml-3"
            routerLink="{{ link }}"
          >
            {{ linkText }} <span></span>
            <i class="fa {{ icon }}"></i>
          </a>
        </span>
      </div>

      <div class="d-none d-lg-inline col-4 col-md-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item | translate }}</li>
          <li class="breadcrumb-item active">{{ active_item | translate}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
