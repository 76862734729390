import { ToastrService } from 'ngx-toastr';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SubGroupModel } from './../../../models/subGroup.model';
import { ArticleTypesModel, VATRatingsModel, } from './../../../models/article.model';
import { ArticlesService } from './../../../services/articles.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubGroupService } from 'src/app/shared/services/subGroups.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PoreskiObveznik } from 'src/app/_constants/appMessages';
import { PostavkeAllService } from 'src/app/shared/services/postavke-all.service';

@Component({
  selector: 'app-add-new-article',
  templateUrl: './add-new-article.component.html',
  styleUrls: ['./add-new-article.component.scss'],
})
export class AddNewArticleComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();
  subGroups: Array<SubGroupModel>;
  articleTypes: Array<ArticleTypesModel>;
  vatRatings: Array<VATRatingsModel>;
  poreskiObveznik;

  newArticleForm = new UntypedFormGroup({
    podgrupa: new UntypedFormControl(null, Validators.required),
    tip: new UntypedFormControl(null, Validators.required),
    barKod: new UntypedFormControl('', this.isMoreThanFifteenCharacters),
    naziv: new UntypedFormControl('', Validators.required),
    pdvTarifa: new UntypedFormControl(null, Validators.required),
    stopaOtpisa: new UntypedFormControl(null),
    jm: new UntypedFormControl('kom', [
      Validators.required,
      this.isMoreThanFourCharacters,
    ]),
    dobavljaccc: new UntypedFormControl(1),
    katalosskiBrojevi: new UntypedFormControl(''),
    katalosskiBroj2: new UntypedFormControl(''),
    katalosskiBroj3: new UntypedFormControl(''),
  });

  constructor(
    private articleService: ArticlesService,
    private subGroupService: SubGroupService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
    private postavkeService: PostavkeAllService
  ) { }

  private isMoreThanFourCharacters(control) {
    return !(control.value.length >= 4)
      ? null
      : {
        isMoreThanFourCharacters: {
          valid: false,
        },
      };
  }
  private isMoreThanFifteenCharacters(control) {
    return !(control.value.length >= 15)
      ? null
      : {
        isMoreThanFifteenCharacters: {
          valid: false,
        },
      };
  }

  setVATpercent() {
    this.newArticleForm.controls.stopaOtpisa.setValue(
      this.newArticleForm.value.pdvTarifa
    );
  }

  onSubmit() {
    if (this.newArticleForm.valid) {
      this.createNewArticle();
    } else {
      this.newArticleForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.getAllArticleTypes();
    this.getAllSubGroups();
    this.getAllVatRatings();

    this.poreskiObveznik = parseInt(
      this.postavkeService.findPostavku(
        PoreskiObveznik.toString()
      )
    );
  }

  getAllSubGroups() {
    this.subGroupService.getAllSubGroups().subscribe((res) => {
      this.subGroups = res;
      this.newArticleForm.controls.podgrupa.setValue(this.subGroups[0].podgrupa);
    });
  }

  getAllArticleTypes() {
    this.articleService.getAllArticlesTypes().subscribe((res) => {
      this.articleTypes = res;
      this.newArticleForm.controls.tip.setValue(this.articleTypes[0].key);
    });
  }

  getAllVatRatings() {
    this.articleService.getVatRatings().subscribe((res) => {
      this.vatRatings = res;

      var currStopa;
      if (this.poreskiObveznik == 2) {
        currStopa = this.vatRatings.find(x => x.stopa == 0);
      }
      else {
        currStopa = this.vatRatings.find(x => x.stopa == 17);
      }

      if (currStopa == null) {
        this.newArticleForm.controls.pdvTarifa.setValue(this.vatRatings[0].tarifniBroj);
      }
      else {
        this.newArticleForm.controls.pdvTarifa.setValue(currStopa.tarifniBroj);
      }
    });
  }

  createNewArticle() {
    this.articleService.addNewArticle(this.newArticleForm.value).subscribe(
      (res) => {
        this.toastr.success(this.translate.instant("MESSAGE.Uspješno dodan artikal"));
        this.updateInputValue.emit(res.body.result.item);
        this.closeModal();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
