import { BaseModel } from './base.model';
export class PrintModel extends BaseModel {
  datum: string;
  kupac: CustomerPrintModel;
  stavkeRacuna: RecipeItemsPrintModel[];
  vrstePlacanja: PaymnetMethodsPrintModel[];
  napomena: string;
  brojRacuna: any;
  get childModels(): {} {
    return {
      kupac: CustomerPrintModel,
      stavkeRacuna: RecipeItemsPrintModel,
      vrstePlacanja: PaymnetMethodsPrintModel,
    };
  }
}

export class ArticlePrintModel extends BaseModel {
  sifra: string;
  naziv: string;
  jm: string;
  cijena: string;
  stopa: any;
  grupa: any;
  plu: any;

  get vatMark() {
    if (this.stopa == 0) {
      return 'A'
    }
    else if (this.stopa == 1) {
      return 'E';
    }
    else if (this.stopa == 2) {
      return 'J';
    }
    else if (this.stopa == 3) {
      return 'K';
    }
    else {
      return 'M'
    }
  }
}

export class CustomerPrintModel extends BaseModel {
  iDbroj: string;
  pdVbroj: string;
  naziv: string;
  adresa: string;
  postanskiBroj: string;
  grad: string;
}

export class RecipeItemsPrintModel extends BaseModel {
  artikal: ArticlePrintModel;
  kolicina: any;
  rabat: any;
  get childModels(): {} {
    return {
      artikal: ArticlePrintModel,
    };
  }
}

export class PaymnetMethodsPrintModel extends BaseModel {
  oznaka: any;
  iznos: any;
}
