import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { SearchDto } from '../models/search.model';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private api: BaseApiService) { }

  search(params: any = {}): Observable<any> {
    return this.api
      .post('search', params)
      .pipe(catchError(this.handleError<[]>(`search`)));
  }




  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
