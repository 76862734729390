import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { AmortizacijskeSkupineDto } from '../models/amortizacijske-skupine.model';
import { PagedResponse } from '../models/paged-response.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { AccountService } from './account.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StalnaSredstvaService {
  constructor(
    private api: BaseApiService,
    private accountService: AccountService,
  ) { }

  getAll(): Observable<AmortizacijskeSkupineDto[]> {
    return this.api.get('stalna-sredstva/all').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new AmortizacijskeSkupineDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<AmortizacijskeSkupineDto[]>(`getAllAS`))
    );
  }

  getPaged(params: any): Observable<PagedResponse<AmortizacijskeSkupineDto>> {
    return this.api.post('stalna-sredstva/paged', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<AmortizacijskeSkupineDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new AmortizacijskeSkupineDto(i));
        return pagedModel;
      })
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('stalna-sredstva/by-id', params)
      .pipe(catchError(this.handleError<[]>(`stalna-sredstva/by-id`)));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('stalna-sredstva/update', params)
      .pipe(catchError(this.handleError<[]>('stalna-sredstva/update')));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('stalna-sredstva/create', params)
      .pipe(catchError(this.handleError<[]>('stalna-sredstva/create')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('stalna-sredstva/delete', params)
      .pipe(catchError(this.handleError<[]>('stalna-sredstva/delete')));
  }


  getSaldaKartica(payload: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}stalna-sredstva/salda-kartica`,
      {
        odPJ: payload.odPJ,
        doPJ: payload.doPJ,
        odArtikla: payload.odArtikla,
        doArtikla: payload.doArtikla,
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  registarSredstava(payload: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}stalna-sredstva/registar-sredstava`,
      {
        odPJ: payload.odPJ,
        doPJ: payload.doPJ,
        odArtikla: payload.odArtikla,
        doArtikla: payload.doArtikla,
        odDatuma: payload.odDatuma,
        doDatuma: payload.doDatuma,
        projekt: payload.projekt
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }

}
