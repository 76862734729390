import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ff-add-new-task-obilazak',
  standalone: false,
  templateUrl: './ff-add-new-task-obilazak.component.html',
  styleUrl: './ff-add-new-task-obilazak.component.scss'
})
export class FfAddNewTaskObilazakComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  closeModal(type) {
    this.updateInputValue.emit(type);
    this.bsModalRef.hide();
  }

}
