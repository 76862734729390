import { Component, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmsService } from 'src/app/shared/services/dms.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dms-unregistered-modal',
  templateUrl: './dms-unregistered-modal.component.html',
  styleUrl: './dms-unregistered-modal.component.scss'
})
export class DmsUnregisteredModalComponent {
  static CONTEXT_CHANGED = new EventEmitter();
  @Input() updateInputValue = new EventEmitter();
  @Input() showZatvori = true;
  documents;
  loading;

  constructor(
    private dmsService: DmsService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments() {
    this.loading = true;
    //if (this.isAuthorized("dms.pristup-svim-firmama")) {
    this.dmsService.getUnregisteredAllFirms().subscribe(resp => {
      this.documents = resp.body.result;
      this.loading = false;
    });
  }

  firmaClicked(item) {
    DmsUnregisteredModalComponent.CONTEXT_CHANGED.emit(item);
    this.updateInputValue.emit(item);
  }

  forceCloseModal() {
    this.updateInputValue.emit(false);
    this.bsModalRef.hide();
  }
}
