
  <!-- Bootstrap TouchSpin Spinners start-->
  <div class="bootstrap-touchspin">
    <fieldset>
      <div class="touchspin-vertical-tab">
        <div class="input-group bootstrap-touchspin">
          <input class="touchspin-vertical form-control" type="text" [formControl]="control">
          <span class="input-group-btn-vertical">
            <button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" (click)=increment()>
              <i class="fa fa-angle-up"></i></button>
            <button class="btn btn-primary btn-square bootstrap-touchspin-down" type="button" (click)=decrement()>
              <i class="fa fa-angle-down"></i></button>
          </span>
        </div>
      </div>
    </fieldset>
  </div>
<!-- Bootstrap TouchSpin Spinners End-->
