<div class="card card-body mb-0">
    <div class="mb-4 h6">{{ 'FORME.DodajNovi'| translate}}</div>
    <div class="d-flex justify-content-end">
        <button class="btn btn-light mr-3" (click)="closeModal('task')">
            {{ "PROPERTIES.Zadatak" | translate}}
        </button>
        <button class="btn btn-primary" (click)="closeModal('obilazak')">
            {{ "PROPERTIES.Obilazak" | translate}}
        </button>
    </div>
</div>