<div class="container-fluid" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div class="row mt-2">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">

                    <div class="row text-center">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div class="btn-showcase">
                                <button type="button"
                                    class="fc-prev-button fc-button fc-state-default btn btn-light fc-corner-left btn-sm"
                                    mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                                    (viewDateChange)="viewDateChange($event)">
                                    <i class="fa fa-angle-left"></i></button>
                                <button type="button"
                                    class="fc-next-button fc-button fc-state-default btn btn-light fc-corner-right btn-sm"
                                    mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                                    (viewDateChange)="viewDateChange($event)">
                                    <i class="fa fa-angle-right"></i></button>
                                <div class="btn btn-light btn-sm" mwlCalendarToday [(viewDate)]="viewDate"
                                    (click)="todayClicked()" *ngIf="view == calendarView.Day">
                                    {{ "PROPERTIES.Danas" | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-2">
                            <h4>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h4>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                            <div class="fc-button-group">
                                <button
                                    class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left fc-state-active btn-sm"
                                    (click)="monthButtonClicked()" [class.active]="view === calendarView.Month">
                                    {{ "PROPERTIES.Mjesec" | translate}}
                                </button>
                                <button
                                    class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left ml-1 btn-sm"
                                    (click)="weekButtonClicked()" [class.active]="view === calendarView.Week">
                                    {{ "PROPERTIES.Sedmica" | translate}}
                                </button>
                                <button
                                    class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left ml-1 btn-sm"
                                    (click)="dayButtonClicked()" [class.active]="view === calendarView.Day">
                                    {{ "PROPERTIES.Dan" | translate}}
                                </button>

                                <!--  @if (!isMobile) {
                                <button class="fc-button fc-state-default btn btn-light fc-corner-left ml-1 btn-sm"
                                    (click)="addNew()">
                                    {{ "PROPERTIES.Dodaj" | translate}}
                                    <i class="fa fa-plus"></i>
                                </button>
                                }
                                @else {
                                <button class="fc-button fc-state-default btn btn-light fc-corner-left ml-4 btn-sm mt-2"
                                    (click)="addNew()">
                                    {{ "PROPERTIES.Dodaj" | translate}}
                                    <i class="fa fa-plus"></i>
                                </button> 
                                }-->
                            </div>
                        </div>

                        @if (isAuthorized('fiskusfield.admin')) {
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 m-t-10">
                            <ng-select [items]="operateri" loadingText="Ucitavanje..." [virtualScroll]="true"
                                bindLabel="fullName" bindValue="id" [(ngModel)]="operater" [selectOnTab]="true"
                                (change)="operaterChanged($event)">
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <div>{{ item.fullName }}</div>
                                </ng-template>
                            </ng-select>
                        </div>
                        }
                    </div>
                    <br>

                    <div [ngSwitch]="view">
                        <mwl-calendar-month-view *ngSwitchCase="calendarView.Month" [viewDate]="viewDate"
                            [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                            (dayClicked)="dayClicked($event.day)" (eventClicked)="eventClicked($event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh"
                            [dayStartHour]="dayStartHour">
                        </mwl-calendar-month-view>
                        <mwl-calendar-week-view *ngSwitchCase="calendarView.Week" [viewDate]="viewDate"
                            [events]="events" [refresh]="refresh" (eventClicked)="eventClicked($event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh"
                            [daysInWeek]="userSettings?.ffNumberWorkingDays" [dayStartHour]="dayStartHour"
                            (mwlClick)="weekOnEmptyClick($event)"
                            [currentTimeMarkerTemplate]="currentTimeMarkerTemplate">
                        </mwl-calendar-week-view>
                        <mwl-calendar-day-view *ngSwitchCase="calendarView.Day" [viewDate]="viewDate" [events]="events"
                            [refresh]="refresh" (eventClicked)="eventClicked($event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)" [refresh]="refresh"
                            [dayStartHour]="dayStartHour" (hourSegmentClicked)="dayOnEmptyClick($event)"
                            [currentTimeMarkerTemplate]="currentTimeMarkerTemplate">
                        </mwl-calendar-day-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>