import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationsService } from 'src/app/_services/services/configurations.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ClipboardListModalComponent } from 'src/app/shared/components/modals/clipboard-list-modal/clipboard-list-modal.component';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrl: './user-dropdown.component.scss'
})
export class UserDropdownComponent {
  public openDropdown: boolean = false;
  accountInfo: any = localStorage.getItem('accountInfo');
  isDesktop: boolean = window.innerWidth > 990; // && window.outerWidth > 990;
  bsModalRef: BsModalRef;

  get firstName() {
    return JSON.parse(this.accountInfo)?.firstName;
  }
  get lastName() {
    return JSON.parse(this.accountInfo)?.lastName;
  }

  constructor(
    private configurationService: ConfigurationsService,
    public accountService: AccountService,
    private modalService: BsModalService,
  ) { }

  toggleDropdownMobile() {
    this.openDropdown = !this.openDropdown;
  }


  downloadTringExtension() {
    this.configurationService.getTringExtension().subscribe((resp) => {
      const blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);

      var anchor = document.createElement('a');
      anchor.download = 'fiwe-tring.zip';
      anchor.href = url;
      anchor.click();
    });
  }

  downloadNSCExtension() {
    this.configurationService.getNSCExtension().subscribe((resp) => {
      const blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);

      var anchor = document.createElement('a');
      anchor.download = 'fiwe-nsc.zip';
      anchor.href = url;
      anchor.click();
    });
  }

  logout() {
    this.accountService.logout();
  }

  openClipboard() {
    const initialState: ModalOptions = {
      initialState: {},
      class: 'modal-dialog-centered modal-xl'
    };
    this.bsModalRef = this.modalService.show(ClipboardListModalComponent, initialState);
  }

}
