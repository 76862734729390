<div class="card-body">
    <h5 class="mb-3 border-bottom">{{"DASHBOARD.Brzi unos grada" | translate}}</h5>
    <form #formElement [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-6 mb-2">
                <label class="font-weight-bold">
                    <span class="">{{ "PROPERTIES.Naziv" | translate }}</span>
                </label>
                <input formControlName="grad" class="form-control" type="text" />
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                        control: this.form.controls.grad
                      }">
                </ng-container>
            </div>

            <div class="col-6 mb-2">
                <label class="font-weight-bold">
                    <span class="">{{ "PROPERTIES.PostanskiBroj" | translate }}</span>
                </label>
                <input formControlName="posstanskiBroj" class="form-control" type="text" maxlength="10" />
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                        control: this.form.controls.posstanskiBroj
                      }">
                </ng-container>
            </div>

            <div class="col-6 mb-2">
                <label class="font-weight-bold">
                    <span class="">{{ "PROPERTIES.Kanton" | translate }}</span>
                    <app-tooltip key="invoice.date"></app-tooltip>
                </label>
                <div class="input-group custom-group">
                    <ng-select class="col p-0" [items]="kantoni" formControlName="kanton" loadingText="Ucitavanje..."
                        bindLabel="kanton" bindValue="sifra" [clearable]="false" [virtualScroll]="true"
                        [selectOnTab]="true">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item.kanton }}</div>
                        </ng-template>
                    </ng-select>
                </div>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                        control: this.form.controls.kanton
                      }">
                </ng-container>
            </div>

            <div class="col-6 mb-2">
                <label class="font-weight-bold">
                    <span class="">{{ "PROPERTIES.SifraPU" | translate }}</span>
                </label>
                <input formControlName="sifraPu" class="form-control" type="number" />
            </div>

            <div class="col-6 mb-2">
                <label class="font-weight-bold">
                    <span class="">{{ "PROPERTIES.OznakaOpcine" | translate }}</span>
                </label>
                <input formControlName="oznakaOpccine" class="form-control" type="text" maxlength="3" />
            </div>
        </div>

        <div class="col12 mt-4 text-right">
            <button class="btn btn-light mr-3" type="button" (click)="closeModal()">
                {{ "PROPERTIES.Otkazi" | translate }}
            </button>
            <button class="btn btn-primary">
                {{ "PROPERTIES.SpremiIzmjene" | translate }}
            </button>
        </div>
    </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <small class="text-danger">{{ message | translate }}</small>
    </ng-container>
</ng-template>