import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { FFTagoviDto } from '../models/fiskus-field/FFTagoviDto';
import { OperatoriZaFirmuDto } from '../models/fiskus-field/OperatoriZaFirmuDto';
import { qALL_FF_TIPOVI_FORMI_OBILAZAK } from '../models/fiskus-field/qALL_FF_TIPOVI_FORMI_OBILAZAK';
import { qALL_rpt_FF_NE_OBILASCI_FILTER_2 } from '../models/fiskus-field/qALL_rpt_FF_NE_OBILASCI_FILTER_2';
import { qALL_rpt_FF_OBILASCI_FILTERResult } from '../models/fiskus-field/qALL_rpt_FF_OBILASCI_FILTERResult';
import { qALL_rpt_FF_OBILASCI_KOMITENTI_FORME_SUM } from '../models/fiskus-field/qALL_rpt_FF_OBILASCI_KOMITENTI_FORME_SUM';
import { qALL_rpt_FF_OBILASCI_ZADNJIResult } from '../models/fiskus-field/qALL_rpt_FF_OBILASCI_ZADNJIResult';
import { AccountService } from './account.service';
import { BaseApiService } from './base-api.service';
import { getAcceptLanguage } from '../helpers/getAcceptLanguage';
import { PagedResponse } from '../models/paged-response.model';
import { FFObilasciDto } from '../models/fiskus-field/ffObilasciDto';

@Injectable({
  providedIn: 'root'
})
export class FiskusFieldService {
  lang = getAcceptLanguage.getLocalStorageLanguage();

  constructor(private api: BaseApiService, private accountService: AccountService) { }
  
  getNeobilasciFilter(payload) {
    return this.api.post('fiskus-field/neobilasci-filter', payload).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.item.map(
            (obilasci) => new qALL_rpt_FF_NE_OBILASCI_FILTER_2(obilasci)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<qALL_rpt_FF_NE_OBILASCI_FILTER_2[]>(`fiskus-field/neobilasci-filter`))
    );
  }

  getObilasciKomitentiFormeSum(payload) {
    return this.api.post('fiskus-field/obilasci-komitenti-forme-sum', payload).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.item.map(
            (obilasci) => new qALL_rpt_FF_OBILASCI_KOMITENTI_FORME_SUM(obilasci)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<qALL_rpt_FF_OBILASCI_KOMITENTI_FORME_SUM[]>(`fiskus-field/obilasci-komitenti-forme-sum`))
    );
  }

  getTagoveZaFirmu() {
    return this.api.get('fiskus-field/get-tagovi').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.item.map(
            (obilasci) => new FFTagoviDto(obilasci)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<FFTagoviDto[]>(`fiskus-field/get-tagovi`))
    );
  }

  getFotografija(autoBr) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': this.lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}fiskus-field/get-fotografija`,
      {
        autoBr: autoBr,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getPotpis(autoBr) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': this.lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}fiskus-field/get-potpis`,
      {
        autoBr: autoBr,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }
 
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
