<div class="card-header">
    <h4 class="mt-2">{{ "PROPERTIES.Međuspremnik" | translate }}</h4>
</div>
<div class="card card-body mb-0">
    <!--  <h4>{{ "PROPERTIES.Greške" | translate }}:</h4> -->
    <div class="row" id="report-export">
        <div class="col-12">
            <div class="form-group">
                <div class="table-responsive invoice-table" id="table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td>
                                    <div class="h6 p-2 mb-0 d-flex align-items-center">
                                        {{"PROPERTIES.Firma" | translate}}
                                    </div>
                                </td>

                                <td>
                                    <div class="h6 p-2 mb-0 d-flex align-items-center">
                                        {{"PROPERTIES.Tip" | translate}}
                                    </div>
                                </td>

                                <!--  <td>
                                    <div class="h6 p-2 mb-0 d-flex align-items-center">
                                        {{"PROPERTIES.Podaci" | translate}}
                                    </div>
                                </td> -->

                                <td>
                                    <div class="h6 p-2 mb-0 d-flex align-items-center">
                                        {{"PROPERTIES.Datum" | translate}}
                                    </div>
                                </td>

                                <td class="icons-column"></td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let item of clips; index as i">
                                <td>
                                    <p class="itemtext">{{ item?.firme?.naziv }}</p>
                                </td>

                                <td>
                                    <p class="itemtext">{{ getTip( item?.tip ) }}</p>
                                </td>

                                <!-- <td>
                                    <p class="itemtext">{{ getValueFromClip( item?.clip ) }}</p>
                                </td> -->

                                <td>
                                    <p class="itemtext">{{ item?.date | date :"dd.MM.yyyy. HH:mm" }}</p>
                                </td>

                                <td class="icons-column">
                                    <div class="d-flex justify-content-center flex-nowrap">
                                        <div class="d-flex align-items-center px-2" (click)="pasteClipboard(item)">
                                            <div class="d-flex justify-content-center align-items-center px-1">
                                                <a placement="top" *ngIf="!isPasting"
                                                    [ngbTooltip]="'PROPERTIES.Zalijepi kopirano' | translate"
                                                    class="btn btn-xs btn-light icon-button"><i
                                                        class="fa fa-paste fa-fw fa-lg"></i>
                                                </a>

                                                <span class="spinner-border spinner-border-sm text-dark ml-1"
                                                    role="status" *ngIf="isPasting">
                                                    <span class="sr-only">Loading...</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="d-flex align-items-center px-2" (click)="delete(item)">
                                            <div class="d-flex justify-content-center align-items-center px-1">
                                                <a placement="top" [ngbTooltip]="'PROPERTIES.Obriši' | translate"
                                                    class="btn btn-xs btn-light icon-button"><i
                                                        class="fa fa-trash fa-fw fa-lg"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="loading" class="loader-box">
                        <div class="loader-2"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 text-right mt-2 mb-0 mr-2">
            <button class="btn btn-light mr-2 ml-2" (click)="closeModal( )" type="button">
                {{"PROPERTIES.Zatvori" | translate}}
            </button>
        </div>

    </div>
</div>