<app-breadcrumb [title]="'Form Wizard'" [items]="['Forms', 'Form Layout']" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h5>Simple Form Wizard</h5><span>Please Make sure fill all the filed before click on next button</span>
        </div>
        <div class="card-body">
          <form class="form-wizard" id="regForm">
          </form>
        </div>
      </div>
    </div>
  </div>
</div>