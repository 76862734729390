import { Injectable } from '@angular/core';
import { AccessToken } from 'src/app/models/login/AuthenticateResultDto';
import * as appconstatnts from 'src/app/_constants/appMessages';

const CONTEXT_KEY = 'userContext';

@Injectable({
  providedIn: 'root'
})
export class AccountGlobalService {


  constructor() { }


  set context(value) {
    localStorage.setItem(CONTEXT_KEY, JSON.stringify(value));
  }

  get context() {
    const contextString = localStorage.getItem(CONTEXT_KEY);
    if (contextString) {
      return JSON.parse(contextString);
    }
    return {};
  }

  get accessToken(): AccessToken {
    const storage = localStorage.getItem(appconstatnts.accessToken);
    if (storage) {
      return JSON.parse(storage) as AccessToken;
    }
    return {} as AccessToken;
  }

}
