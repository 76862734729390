import { BaseModel } from './base.model';

export class CitiesModel extends BaseModel {
    sifra: number;
    sifraPu: number;
    grad: string;
    posstanskiBroj: string;
    oznakaOpccine: string;
    kanton: number | null;
    created: string | null;
    createdBy: number | null;
    modified: string | null;
    modifiedBy: number | null;
    status: number | null;

    get lookupString () {
        return `${this.grad}, ${this.posstanskiBroj}`
    }
}