import { BaseModel } from "src/app/shared/models/base.model";

export class UserWithIdDto extends BaseModel {
    id: number;
    firstName: string;
    lastName: string;

    get fullName() {
        return this.firstName + ' ' + this.lastName;
    }
}