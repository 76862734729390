import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { KantoniService } from 'src/app/shared/services/kantoni.service';

@Component({
  selector: 'app-add-new-city',
  templateUrl: './add-new-city.component.html',
  styleUrls: ['./add-new-city.component.scss'],
})
export class AddNewCityComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();
  kantoni;

  form = new UntypedFormGroup({
    grad: new UntypedFormControl('', Validators.required),
    posstanskiBroj: new UntypedFormControl('', Validators.required),
    kanton: new UntypedFormControl(6, Validators.required),
    sifraPu: new UntypedFormControl('0'),
    oznakaOpccine: new UntypedFormControl(''),
  });

  constructor(
    private toastr: ToastrService,
    private gradoviService: CitiesService,
    private kantoniService: KantoniService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.getKantone();
  }

  getKantone() {
    this.kantoniService.getAll().subscribe((resp) => {
      this.kantoni = resp;
      this.form.controls.kanton.setValue(this.kantoni[0].sifra);
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.createNewGrad();
    } else {
      this.form.markAllAsTouched();
    }
  }

  createNewGrad() {
    this.form.removeControl('sifra');
    this.gradoviService.create(this.form.value).subscribe(
      (res) => {
        this.toastr.success(this.translate.instant("MESSAGE.Uspješno dodan grad"));
        this.updateInputValue.emit(res.body.result.item.sifra);
        this.closeModal();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
