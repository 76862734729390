export const USER_ADMIN_PREVIEW = 'permission.useradmin.preview';
export const USER_ADMIN_CREATE = 'permission.useradmin.create';
export const USER_ADMIN_EDIT = 'permission.useradmin.edit';

export const ARTIKLI_PREVIEW = 'permission.artikli.preview';
export const ARTIKLI_CREATE = 'permission.artikli.create';
export const ARTIKLI_EDIT = 'permission.artikli.edit';
export const ARTIKLI_DELETE = 'permission.artikli.delete';

export const KOMITENTI_PREVIEW = 'permission.komitenti.preview';
export const KOMITENTI_CREATE = 'permission.komitenti.create';
export const KOMITENTI_EDIT = 'permission.komitenti.edit';
export const KOMITENTI_DELETE = 'permission.komitenti.delete';

export const IMPORT_CALCULATION_PREVIEW = 'permission.kalkulacije.preview';
export const IMPORT_CALCULATION_CREATE = 'permission.kalkulacije.create';
export const IMPORT_CALCULATION_EDIT = 'permission.kalkulacije.edit';
export const IMPORT_CALCULATION_DELETE = 'permission.kalkulacije.delete';
export const IMPORT_CALCULATION_PRINT = 'permission.kalkulacije.print';
export const IMPORT_CALCULATION_ACTIONS = 'permission.kalkulacije.akcije';

export const STOCK_LIST_PREVIEW = 'permission.lagerlista.preview';
export const STOCK_LIST_PRINT = 'permission.lagerlista.print';

export const FIRME_ADMINISTRATION_PREVIEW = 'permission.firmeadministration.preview';
export const FIRME_ADMINISTRATION_CREATE = 'permission.firmeadministration.create';
export const FIRME_ADMINISTRATION_EDIT = 'permission.firmeadministration.edit';