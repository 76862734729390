import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class CallService {
    constructor(
        private translate: TranslateService,
        private toastr: ToastrService) { }


    callViber(phoneNumber) {
        var phone = this.getPhoneNumber(phoneNumber);
        if (phone == "") return;
        window.open(`viber://chat?number=${phone}`);
    }

    callPhone(phoneNumber) {
        var phone = this.getPhoneNumber(phoneNumber);
        if (phone == "") return;
        window.open(`tel:${phone}`);
    }

    callWhatsapp(phoneNumber) {
        var phone = this.getPhoneNumber(phoneNumber);
        if (phone == "") return;
        window.open(`https://api.whatsapp.com/send?phone=${phone}`);
    }

    getPhoneNumber(phoneNumber) {
        var phoneNo: string = phoneNumber;

        if (phoneNo == undefined || phoneNo == null || phoneNo == '') {
            this.toastr.error(this.translate.instant("MESSAGE.Nije unešen broj telefona za komitenta"))
            return "";
        }

        if (phoneNo.includes("+")) {
            phoneNo = phoneNo.replace("+", "00");
        }
        if (phoneNo.startsWith("387")) {
            phoneNo = phoneNo.replace("387", "00387");
        }

        //viber i whatsapp trebaju biti +  umjesto 00 na androidu
        if (phoneNo.startsWith("00")) {
            phoneNo = phoneNo.replace("00", "%2B");
        }

        return phoneNo;
    }



}
