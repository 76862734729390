import { BaseModel } from "../base.model";

export class qFIN_rpt_GRESKA_IZNOSI_DOKUMENATA_NEUKNJIZENJE_STAVKE extends BaseModel {
    nalog: number;
    nDokument: string;
    broj: string;
    datum: string;
    duguje: number;
    potrazzuje: number;
    objassnjenje: string;


    get dateFormatted() {
        var datum = new Date(this.datum).toLocaleDateString();
        return datum;
    }
}