import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { ClientModel } from '../models/client.model';
import { PagedResponse } from '../models/paged-response.model';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private api: BaseApiService) { }

  getAllClients(params: any = {}): Observable<ClientModel[]> {
    return this.api.get('komitenti/all', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new ClientModel(client));
        }
        return undefined;
      }),
      catchError(this.handleError<ClientModel[]>(`getAllClients`))
    );
  }

  getNextId(): Observable<any> {
    return this.api
      .get('komitenti/next-sifra')
      .pipe(catchError(this.handleError<[]>(`komitenti/next-sifra`)));
  }

  addNewClient(params: any): Observable<any> {
    return this.api
      .post('komitenti/create', params)
      .pipe(catchError(this.handleError<[]>(`komitenti/create`)));
  }

  getClientById(params: any): Observable<any> {
    return this.api
      .post('komitenti/by-id', params)
      .pipe(catchError(this.handleError<[]>(`komitenti/by-id`)));
  }

  getNearby(params: any): Observable<any> {
    return this.api
      .post('komitenti/nearby', params)
      .pipe(catchError(this.handleError<[]>(`komitenti/nearby`)));
  }

  getPagedClients(params: any): Observable<PagedResponse<ClientModel>> {
    return this.api.post('komitenti', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<ClientModel>(response.body.result);
        pagedModel.items = pagedModel.items?.map((i) => new ClientModel(i));
        return pagedModel;
      })
    );
  }

  deleteClient(params: any): Observable<any> {
    return this.api
      .post('komitenti/delete', params)
      .pipe(catchError(this.handleError<[]>('komitenti/delete')));
  }

  updateClient(params: any = {}): Observable<any> {
    return this.api
      .post('komitenti/update', params)
      .pipe(catchError(this.handleError<[]>('komitenti/update')));
  }

  getCreatePropertyList() {
    return this.api
      .get('komitenti/create-property-list')
      .pipe(catchError(this.handleError<[]>(`komitenti/create-property-list`)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
