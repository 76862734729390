import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { ClipboardDto } from '../models/clipboard.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { PagedResponse } from '../models/paged-response.model';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  constructor(private api: BaseApiService) { }

  getPaged(params: any): Observable<PagedResponse<ClipboardDto>> {
    return this.api.post('clipboard', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<ClipboardDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new ClipboardDto(i));
        return pagedModel;
      })
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('clipboard/by-id', params)
      .pipe(catchError(this.handleError<[]>(`clipboard/by-id`)));
  }

  copy(params: any = {}): Observable<any> {
    return this.api
      .post('clipboard/copy', params)
      .pipe(catchError(this.handleError<[]>('clipboard/copy')));
  }

  delete(params: any = {}): Observable<any> {
    return this.api
      .post('clipboard/delete', params)
      .pipe(catchError(this.handleError<[]>('clipboard/delete')));
  }

  ifExists(params: any = {}): Observable<any> {
    return this.api
      .post('clipboard/exists', params)
      .pipe(catchError(this.handleError<[]>('clipboard/exists')));
  }

  paste(params: any = {}): Observable<any> {
    return this.api
      .post('clipboard/paste', params)
      .pipe(catchError(this.handleError<[]>('clipboard/paste')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
