<input
        [(ngModel)]="param"
        (ngModelChange)="doInput()"
        (blur)="doBlur()"
        class="form-control text-right"
        type="text"
        (keyup)="doKeyUp($event)"
        placeholder="0,00"
        mask="separator.{{numberOfDecimals}}" thousandSeparator="."
        tabIndex="{{tabIndex}}"
        inputmode="tel"
        [allowNegativeNumbers]="allowMinusSign"
        [readonly]="readonly"
        value="{{inputValue}}"
        #myInput
/>
