<div class="card-body">
  <h5 class="mb-3 border-bottom">{{"DASHBOARD.Dodaj komitenta" | translate }}</h5>
  <form [formGroup]="clientForm" (keyup.enter)="onSubmit()" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.Komitent" | translate }}</label>
        <input class="form-control" type="text" formControlName="komitent" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.clientForm.controls.komitent
          }">
        </ng-container>
      </div>
      <div class="col-6">
        <label class="font-weight-bold">{{ "PROPERTIES.Identifikacioni broj" | translate }}</label>
        <input class="form-control" maxlength="13" type="text" formControlName="identifikacioniBroj" inputmode="numeric"
          placeholder="" />
        <div class="d-flex flex-column">
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
              validation: 'required',
              message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
              control: this.clientForm.controls.identifikacioniBroj
            }">
          </ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
              validation: 'isExactLength',
              message: 'VALIDATORS.IDBroj13Znamenki',
              control: this.clientForm.controls.identifikacioniBroj
            }">
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <label class="font-weight-bold">{{ "PROPERTIES.PDVBroj" | translate }}</label>
        <input class="form-control" type="text" formControlName="pdvBroj" inputmode="numeric" maxlength="12" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'isVATNumberExactLength',
            message: 'VALIDATORS.PDVBroj12Znamenki',
            control: this.clientForm.controls.pdvBroj
          }">
        </ng-container>
      </div>
      <div class="col-md-6 form-group">
        <label class="font-weight-bold">{{ "PROPERTIES.Grad" | translate }}</label>
        <div class="input-group custom-group">
          <ng-select [items]="cities" class="col p-0" loadingText="Ucitavanje..." [virtualScroll]="true"
            bindLabel="lookupString" bindValue="sifra" formControlName="grad" [selectOnTab]="true">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>{{ item.lookupString }}</div>
              <!--<div class="text-muted small">JIB: {{item.identifikacioniBroj}}</div>-->
            </ng-template>
          </ng-select>

          <div class="input-group-append">
            <span class="input-group-text p-0">
              <button tabindex="-1" (click)="openAddNewCityModal()" type="button" class="btn btn-link btn-sm">
                <i class="fa fa-plus"></i>
              </button>
            </span>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.clientForm.controls.grad
          }">
        </ng-container>


      </div>
      <div class="col-12">
        <label class="font-weight-bold">{{ "PROPERTIES.Adresa" | translate }} </label>
        <input class="form-control" type="text" formControlName="adresa" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.clientForm.controls.adresa
          }">
        </ng-container>
      </div>

      <div class="col-6">
        <label class="font-weight-bold">
          <span class="">{{ "PROPERTIES.Telefon" | translate }}</span>
          <app-tooltip key="invoice.date"></app-tooltip>
        </label>
        <input formControlName="telefon" class="form-control" type="email" />
      </div>

      <div class="col-6">
        <label class="font-weight-bold">
          <span class="">{{ "PROPERTIES.Email" | translate }}</span>
          <app-tooltip key="invoice.date"></app-tooltip>
        </label>
        <input formControlName="eMail" class="form-control" type="text" />

        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'email',
          message: 'VALIDATORS.EmailFormat',
          control: this.clientForm.controls.eMail
        }">
        </ng-container>

        <div class="col12 mt-4 text-right">
          <button class="btn btn-light mr-3" type="button" (click)="closeModal()">
            {{ "PROPERTIES.Otkazi" | translate }}
          </button>
          <button class="btn btn-primary" type="submit">
            {{ "PROPERTIES.SpremiIzmjene" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && control.touched">
    <small class="text-danger">{{ message | translate }}</small>
  </ng-container>
</ng-template>