export const MAX_SAFE_INTEGER = 2147483647;

export const currentFirma = 'currentFirma';
export const accessToken = 'accessToken';
export const refreshToken = 'refreshToken';
export const accountInfo = 'accountInfo';
export const roleClaims = 'roleClaims';
export const firmePodaciUseri = 'firmePodaciUseri';
export const firmePoslovneJedinice = 'firmePoslovneJedinice';
export const APP_LAYOUT = 'appLayout';
export const BUSINESS_UNIT = 'businessUnit';
export const ALL_POSTAVKE = 'allSettings';
export const userSettings = 'userSettings';
export const LAST_INSERTED_DMS = 'LAST_INSERTED_DMS';

export const artikli = "Artikli";
export const artikalUspjesnoSpremljen = "Artikal uspješno spremljen";
export const artikalUspješnoKreiran = "Artikal uspješno kreiran";
export const zeliteLiObrisatiArtikal = "Želite li obrisati artikal?";
export const artikalUspjesnoObrisan = "Artikal uspješno obrisan";
export const FISCAL_DEVICE_ERROR = 'Fiskalni uređaj nije pravilno podešen.';

export const zeliteLiObrisatiFakturu = "Želite li obrisati fakturu?";
export const zeliteLiObrisatiStavku = "Želite li obrisati stavku?";

export const IZMJENA = "IZMJENA";
export const preventUnsaved = "Želite li nastaviti? Nespremljeni podaci će biti izgubljeni!";

export const KoristiLagerZaDodavanjeArtikala = "Koristi lager za dodavanje artikala";
export const FiweStatusFaktura = "FiweStatusFaktura";
export const FiWePoljeDostava = "FiWePoljeDostava";

export const FiweFaktureListaSakrijRabat = "FiweFaktureListaSakrijRabat";
export const FiweFaktureListaPrikaziNapomenu = "FiweFaktureListaPrikaziNapomenu";


export const PoreskiObveznik = "PoreskiObveznik";
export const KoristiPlanskuCijenu = "KoristiPlanskuCijenu";