import { Component, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal-three-opts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirmation-modal-three-opts.component.html',
  styleUrl: './confirmation-modal-three-opts.component.scss'
})
export class ConfirmationModalThreeOptsComponent {
  @Input() updateInputValue = new EventEmitter();
  text: string;
  confirmed = false;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void { }

  closeModal(value) {
    this.confirmed = value; //koristi se za change guard
    this.updateInputValue.emit(value);
    this.bsModalRef.hide();
  }

}
