import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent
    }
  ]
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {

  @Input() numberOfDecimals: string;
  @Input() tabIndex: any;
  @Input()
  set inputValue(value) {
    this.param = value?.toString().split('.').join(',');
  }
  @Input() readonly: boolean = false;
  @Input() allowMinusSign: boolean;
  @Output('keyup') keyUpEvent = new EventEmitter<any>();
  @ViewChild('myInput', {read:ElementRef}) myInput: ElementRef<HTMLInputElement>;
  param;

  onChange: (param: number) => void = () => {};
  onTouched: () => void = () => {};

  constructor() {}

  ngOnInit(): void {
  }

  writeValue(value: number): void {
    this.param = value?.toString().split('.').join(',');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  doInput() {
    const value = parseFloat(this.param.toString().split(',').join('.'));
    this.onChange(value);
  }

  doBlur() {
    this.onTouched();
  }

  doKeyUp(e) {
    this.keyUpEvent.emit(e);
  }

}
