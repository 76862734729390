import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { AccountService } from './account.service';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DmsKomentariService {
  constructor(private api: BaseApiService,
    private accountService: AccountService) { }

  getAll(params: any): Observable<any> {
    return this.api
      .post('dms/komentari/all', params)
      .pipe(catchError(this.handleError<[]>(`dms/komentari/all`)));
  }

  getAllUnseen(params: any): Observable<any> {
    return this.api
      .post('dms/komentari/all-unseen', params)
      .pipe(catchError(this.handleError<[]>(`dms/komentari/all-unseen`)));
  }

  setSeen(params: any): Observable<any> {
    return this.api
      .post('dms/komentari/set-seen', params)
      .pipe(catchError(this.handleError<[]>(`dms/komentari/set-seen`)));
  }

  create(params: any): Observable<any> {
    return this.api
      .post('dms/komentari/create', params)
      .pipe(catchError(this.handleError<[]>(`dms/komentari//create`)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
