<app-breadcrumb [title]="'Carousel'" [items]="['Bonus UI', 'Base']" [active_item]="'Carousel'"></app-breadcrumb>
<!-- Container-fluid Ends -->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Carousel</h5>
        </div>
        <div class="card-body">
          <ngb-carousel *ngIf="images">
            <ng-template ngbSlide>
              <img [src]="images[0]" alt="Random first slide">
              <div class="carousel-caption">
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img [src]="images[1]" alt="Random second slide">
              <div class="carousel-caption">
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img [src]="images[2]" alt="Random third slide">
              <div class="carousel-caption">
                <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Global configuration of carousels</h5>
        </div>
        <div class="card-body">
          <ngb-carousel *ngIf="images">
            <ng-template ngbSlide>
              <img [src]="images[0]" alt="Random first slide">
              <div class="carousel-caption">
                <h3>10 seconds between slides...</h3>
                <p>This carousel uses customized default values.</p>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img [src]="images[1]" alt="Random second slide">
              <div class="carousel-caption">
                <h3>No mouse events...</h3>
                <p>This carousel doesn't pause or resume on mouse events</p>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <img [src]="images[2]" alt="Random third slide">
              <div class="carousel-caption">
                <h3>No keyboard...</h3>
                <p>This carousel uses customized default values.</p>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xl-6 xl-100">
      <div class="card">
        <div class="card-header">
          <h5>Navigation arrows and indicators</h5>
        </div>
        <div class="card-body">
          <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows"
            [showNavigationIndicators]="showNavigationIndicators">
            <ng-template ngbSlide *ngFor="let image of images">
              <img [src]="image" alt="Random slide">
              <div class="carousel-caption">
                <h3>No mouse navigation</h3>
                <p>This carousel hides navigation arrows and indicators.</p>
              </div>
            </ng-template>
          </ngb-carousel>
          <hr>
          <div class="btn-showcase" role="group" aria-label="Carousel toggle controls">
            <button type="button" (click)="showNavigationArrows = !showNavigationArrows"
              class="btn btn-outline-primary btn-sm mr-1">Toggle navigation arrows</button>
            <button type="button" (click)="showNavigationIndicators = !showNavigationIndicators"
              class="btn btn-outline-primary btn-sm">Toggle navigation indicators</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>