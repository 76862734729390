<div class="card card-body mb-0">
    <div class="mb-4 h6">{{'PROPERTIES.Odaberite tip forme' | translate}}</div>

    <div class="col-12 mt-4 pl-0 d-lg-flex justify-content-between">
        <div class="input-group p-0 rounded col-12">
            <input class="form-control" type="search" [placeholder]="'DASHBOARD.Pretraga' | translate"
                aria-label="Search" aria-describedby="search-addon" [(ngModel)]="search" (keyup)="filterItems()" />
        </div>
    </div>

    <div class="my-custom-scrollbar mt-2">
        @for (item of filteredTipoviFormi; track $index) {
        <a class="list-group-item list-group-item-action" href="javascript:void(0)" (click)="listSelected(item.sifra)">
            {{ item.naziv }}</a>
        }

    </div>
    <!--  <div class="input-group custom-group">
        <ng-select class="col p-0" [items]="tipoviFormi" [(ngModel)]="tipForme" loadingText="Ucitavanje..."
            bindLabel="naziv" bindValue="sifra" [clearable]="false" [virtualScroll]="true" [selectOnTab]="true">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div>{{ item.naziv }}</div>
            </ng-template>
        </ng-select>

    </div> -->

    <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-light mr-3" (click)="closeModal()">
            {{"PROPERTIES.Odustani" | translate}}
        </button>
        <!--   <button class="btn btn-primary" (click)="odaberi()">
            {{"PROPERTIES.Odaberi" | translate}}
        </button> -->
    </div>
</div>