import { BaseModel } from "../base.model";

export class qALL_rpt_FF_OBILASCI_KOMITENTI_FORME_SUM extends BaseModel {
    komitent: number;
    nKomitent: string;
    grad: number;
    adresa: string;
    tagovi: string;
    nGrad: string;
    operater: number;
    nOperater: string;
    noteSum: number;
    forma1Sum: number;
    forma2Sum: number;
    forma3Sum: number;
    forma4Sum: number;
    forma5Sum: number;
    forma6Sum: number;
    forma7Sum: number;
    forma8Sum: number;
    forma9Sum: number;
    forma10Sum: number;
    forma11Sum: number;
    forma12Sum: number;
    forma13Sum: number;
    forma14Sum: number;
    forma15Sum: number;
    forma16Sum: number;
    forma17Sum: number;
    forma18Sum: number;
    forma19Sum: number;
    forma20Sum: number;
    forma21Sum: number;
    forma22Sum: number;
    forma23Sum: number;
    forma24Sum: number;
    forma25Sum: number;

    forma26Sum: number;
    forma27Sum: number;
    forma28Sum: number;
    forma29Sum: number;
    forma30Sum: number;
    forma31Sum: number;
    forma32Sum: number;
    forma33Sum: number;
    forma34Sum: number;
    forma35Sum: number;

    redSum: number;

    get tagoviArray() {
        return (this.tagovi == undefined) ? [] : this.tagovi.split(";");
    }
}