import { ToastrService } from 'ngx-toastr';
import { ClientService } from './../../../services/client.service';
import { CitiesModel } from '../../../models/cities.model';
import { UntypedFormGroup, UntypedFormControl, Validators, } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CitiesService } from 'src/app/shared/services/cities.service';
import { AddNewCityComponent } from '../add-new-city/add-new-city.component';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-new-client',
  templateUrl: './add-new-client.component.html',
  styleUrls: ['./add-new-client.component.scss'],
})
export class AddNewClientComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();
  cities: Array<CitiesModel>;

  constructor(
    private cityService: CitiesService,
    private clientService: ClientService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
  ) { }

  clientForm = new UntypedFormGroup({
    komitent: new UntypedFormControl('', Validators.required),
    vrsta: new UntypedFormControl(2),
    identifikacioniBroj: new UntypedFormControl('', [
      Validators.required,
      this.isExactLength,
    ]),
    pdvBroj: new UntypedFormControl(null, this.isVATNumberExactLength),
    adminJedinica: new UntypedFormControl(1),
    grad: new UntypedFormControl(null, Validators.required),
    adresa: new UntypedFormControl('', Validators.required),
    telefon: new UntypedFormControl(''),
    eMail: new UntypedFormControl('', [Validators.email]),
  });

  private isExactLength(control) {
    return !(control.value.length < 13 || control.value.length > 13)
      ? null
      : {
        isExactLength: {
          valid: false,
        },
      };
  }
  private isVATNumberExactLength(control) {
    return !(control.value?.length < 12 || control.value?.length > 12) ||
      !control.value
      ? null
      : {
        isVATNumberExactLength: {
          valid: false,
        },
      };
  }

  ngOnInit(): void {
    this.getCities();
  }

  onSubmit() {
    if (this.clientForm.valid) {
      this.createNewClient();
    } else {
      this.clientForm.markAllAsTouched();
    }
  }

  getCities() {
    this.cities = [];
    this.cityService.getAllCities().subscribe((res) => {
      this.cities = res;
    });
  }

  createNewClient() {
    this.clientService.addNewClient(this.clientForm.value).subscribe(
      (res) => {
        this.toastr.success(this.translate.instant("MESSAGE.Uspješno kreiran komitent"));
        this.updateInputValue.emit(res.body.result.item.sifra);
        this.closeModal();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  openAddNewCityModal() {
    const initialState: ModalOptions = {
      /* initialState: {
      }, */
      class: "modal-dialog-centered modal-lg"
    };
    this.bsModalRef = this.modalService.show(AddNewCityComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.getCities();
        this.clientForm.controls.grad.setValue(res);
      }
    });
  }
}
