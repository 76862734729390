<app-breadcrumb [title]="'Tabset'" [items]="['Bonus UI', 'Base']" [active_item]="'Tabset'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Basic Tabset</h5>
        </div>
        <div class="card-body">
          
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Pills</h5>
        </div>
        <div class="card-body">
        
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Select an active tab by id</h5>
        </div>
        <div class="card-body">
         

          
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6">
      <div class="card">
        <div class="card-header">
          <h5>Prevent tab change</h5>
        </div>
        <div class="card-body">
         
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6 box-col-12 xl-100">
      <div class="card">
        <div class="card-header">
          <h5>Nav justification</h5>
        </div>
        <div class="card-body">
          
          <div class="btn-group btn-group-toggle mt-2 tabset-group-btn-xs" ngbRadioGroup [(ngModel)]="currentJustify">
            <label ngbButtonLabel class="btn-outline-primary btn-sm btn-position">
              <input ngbButton type="radio" value="start">Start
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm btn-position">
              <input ngbButton type="radio" value="center">Center
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm btn-position">
              <input ngbButton type="radio" value="end">End
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm btn-position">
              <input ngbButton type="radio" value="fill">Fill
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm btn-position">
              <input ngbButton type="radio" value="justified">Justified
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-6 box-col-12 xl-100">
      <div class="card">
        <div class="card-header">
          <h5>Nav justification</h5>
        </div>
        <div class="card-body">
         
          <div class="btn-group btn-group-toggle mt-2" ngbRadioGroup [(ngModel)]="currentOrientation">
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="horizontal">Horizontal
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="vertical">Vertical
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>