import {
  ArticleModel,
  ArticleTypesModel,
  VATRatingsModel,
} from '../models/article.model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { BaseApiService } from './base-api.service';
import { PagedResponse } from '../models/paged-response.model';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  constructor(private api: BaseApiService) { }

  getAllArticles(params: any = {}): Observable<ArticleModel[]> {
    return this.api.get('artikli/all', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (article) => new ArticleModel(article)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<ArticleModel[]>(`getAllItems`))
    );
  }

  getAllArticlesSorted(params: any = {}): Observable<ArticleModel[]> {
    return this.api.post('artikli/all-sorted', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (article) => new ArticleModel(article)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<ArticleModel[]>(`getAllItems`))
    );
  }

  getAllArticlesTypes(params: any = {}): Observable<ArticleTypesModel[]> {
    return this.api.get('configurations/tipove-artikala', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (article) => new ArticleTypesModel(article)
          );
        }
        return undefined;
      }),
      catchError(
        this.handleError<ArticleTypesModel[]>(`configurations/tipove-artikala`)
      )
    );
  }

  getVatRatings(): Observable<VATRatingsModel[]> {
    return this.api.get('configurations/pdv-tarife').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (article) => new VATRatingsModel(article)
          );
        }
        return undefined;
      }),
      catchError(
        this.handleError<VATRatingsModel[]>(`configurations/pdv-tarife`)
      )
    );
  }

  addNewArticle(params: any): Observable<any> {
    return this.api
      .post('artikli/create', params)
      .pipe(catchError(this.handleError<[]>(`artikli/create`)));
  }

  getPagedArticles(params: any): Observable<PagedResponse<ArticleModel>> {
    return this.api.post('artikli/paged', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<ArticleModel>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new ArticleModel(i));
        return pagedModel;
      })
    );
  }

  getNextId(): Observable<any> {
    return this.api
      .get('artikli/next-sifra')
      .pipe(catchError(this.handleError<[]>(`artikli/next-sifra`)));
  }

  getArticleLookup(params: any = {}): Observable<any> {
    return this.api
      .post('artikli/artikal-lookup', params)
      .pipe(catchError(this.handleError<[]>(`artikli/artikal-lookup`)));
  }

  deleteArticle(params: any): Observable<any> {
    return this.api
      .post('artikli/delete', params)
      .pipe(catchError(this.handleError<[]>('artikli/delete')));
  }

  getArticlebyId(params: any): Observable<any> {
    return this.api
      .post('artikli/by-id', params)
      .pipe(catchError(this.handleError<[]>('artikli/by-id')));
  }

  updateArticle(params: any = {}): Observable<any> {
    return this.api
      .post('artikli/update', params)
      .pipe(catchError(this.handleError<[]>('artikli/update')));
  }

  getCreatePropertyList() {
    return this.api
      .get('artikli/create-property-list')
      .pipe(catchError(this.handleError<[]>(`artikli/create-property-list`)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
