
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() buttonText: string;
  @Input() icon: string;
  @Input() buttonIcon: string;
  @Input() buttonColor = 'primary';
  @Input() link: string;
  @Input() linkText: string;
  @Input() disabled: boolean;
  @Input() tooltipText: string;
  @Input() tooltipDisabled: boolean;
  @Input() textColor: string;
  @Output() buttonClicked = new EventEmitter();


  constructor(private translateService: TranslateService) {

  }
 
  ngOnInit() {
    this.title = this.translateService.instant(this.title);
  }

  emmitClick() {
    this.buttonClicked.emit(true);
  }
}
