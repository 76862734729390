import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { AccountGlobalService } from './account-global.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SignalrNotificationService {
  hubUrl = environment.hubUrl;
  private hubConnection?: HubConnection

  public startConnection = () => {
    var auth = this.account.accessToken.token;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl + 'notification',
        {
          //skipNegotiation: true,
          //transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => { return auth }
        })
      .withAutomaticReconnect()
      .build();


    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));

    this.hubConnection.on("notification", data => {
      this.toastr.info(data);
    })


  }
  stopHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(
        error => console.log(error)
      );
    }
  }

  constructor(
    private account: AccountGlobalService,
    private toastr: ToastrService) { }

}
