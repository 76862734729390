import { XmlHelper } from './../helpers/xml.helper';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  DOCUMENT_TYPES,
  RimVrsteDokumenata,
} from 'src/app/helper/RimVrsteDokumenata';
import { PrintModel } from '../models/print.model';
import { BUSINESS_UNIT } from '../../_constants/appMessages';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private editorExtensionId = 'noimlepebohpjjlhdpcbpimipjeajkfn';

  constructor(private api: BaseApiService) { }

  addFiscalNumberToDocument(params) {
    return this.api
      .post('rim/fakture/upisi-fiskalni-broj-na-fakturu', params)
      .pipe(
        catchError(
          this.handleError<[]>('fakture/upisi-fiskalni-broj-na-fakturu')
        )
      );
  }

  getReceipt(params: any): Observable<any> {
    return this.api
      .post('fiskalni-uredjaj/fiskalni-racun-request', params)
      .pipe(
        catchError(
          this.handleError<[]>(`fiskalni-uredjaj/fiskalni-racun-request`)
        )
      );
  }

  private get printInfo(): any {
    const businessUnitString = localStorage.getItem(BUSINESS_UNIT);
    if (!businessUnitString) return {};
    const businessUnit = JSON.parse(businessUnitString);
    return {
      deviceType: businessUnit.vrstaFiskalnogUredjaja,
      responseFolder: businessUnit.folderOdgovora,
      searchFolder: businessUnit.folderPretrazivanja,
      tringFiscalIp: businessUnit.tringFiscalIp,
      tringFiscalPort: businessUnit.tringFiscalPort,
    };
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;
      return throwError(error);
    };
  }

  printDocumentCopy(requestType, documentNumber, printCmd) {
    if (this.printInfo.deviceType === 'NSC') {
      const data = `109,1,______,_,__;${requestType.NSCValue};${documentNumber};${documentNumber};1;`;
      return this.sendPrintOrder(data, 'Duplikat');
    }
    if (this.printInfo.deviceType === 'Tring') {
      const data = `<?xml version="1.0" encoding="utf-8"?> <Zahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>43950</BrojZahtjeva><VrstaZahtjeva>${requestType.id}</VrstaZahtjeva><Parametri><Parametar><Naziv>BrojRacuna</Naziv><Vrijednost>${documentNumber}</Vrijednost></Parametar></Parametri></Zahtjev>`;
      return this.sendPrintOrder(data, printCmd);
    }
  }

  printMoneyImport(value, paymentMethod, printCommand) {
    if (this.printInfo.deviceType === 'NSC') {
      const data = `70,1,______,_,__;${value};`;
      return this.sendPrintOrder(data, 'Unos novca');
    }
    if (this.printInfo.deviceType === 'Tring') {
      const data = `<?xml version="1.0" encoding="utf-8"?> <RacunZahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>0</BrojZahtjeva><VrstaZahtjeva>7</VrstaZahtjeva><NoviObjekat><Oznaka>${paymentMethod}</Oznaka><Iznos>${value}</Iznos></NoviObjekat></RacunZahtjev>`;
      return this.sendPrintOrder(data, printCommand);
    }
  }

  printMoneyExport(value, paymentMethod, printCommand) {
    if (this.printInfo.deviceType === 'NSC') {
      const data = `70,1,______,_,__;${-value};`;
      return this.sendPrintOrder(data, 'Unos novca');
    }
    if (this.printInfo.deviceType === 'Tring') {
      const data = `<?xml version="1.0" encoding="utf-8"?> <RacunZahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>0</BrojZahtjeva><VrstaZahtjeva>7</VrstaZahtjeva><NoviObjekat><Oznaka>${paymentMethod}</Oznaka><Iznos>${value}</Iznos></NoviObjekat></RacunZahtjev>`;
      return this.sendPrintOrder(data, printCommand);
    }
  }

  printDailyReport() {
    if (this.printInfo.deviceType === 'NSC') {
      const data = `69,1,______,_,__;0;`;
      return this.sendPrintOrder(data, 'Dnevni izvjestaj');
    }
    if (this.printInfo.deviceType === 'Tring') {
      const data = `<?xml version="1.0" encoding="utf-8"?> <Zahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>330176</BrojZahtjeva><VrstaZahtjeva>4</VrstaZahtjeva><Parametri /></Zahtjev>`;
      return this.sendPrintOrder(data, 'stampatidnevniizvjestaj');
    }
  }

  printCancelInvoice(){
    if (this.printInfo.deviceType === 'NSC') {
      const data = `53,1,______,_,__;;;\n56,1,______,_,__;`;
      return this.sendPrintOrder(data, 'Prekini račun');
    }
  }

  printOverview() {
    if (this.printInfo.deviceType === 'NSC') {
      const data = `69,1,______,_,__;2;`;
      return this.sendPrintOrder(data, 'Presjek stanja');
    }
    if (this.printInfo.deviceType === 'Tring') {
      const data = `<?xml version="1.0" encoding="utf-8"?> <Zahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>149678</BrojZahtjeva><VrstaZahtjeva>3</VrstaZahtjeva><Parametri /></Zahtjev>`;
      return this.sendPrintOrder(data, 'stampatipresjekstanja');
    }
    const data = `<?xml version="1.0" encoding="utf-8"?> <Zahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>149678</BrojZahtjeva><VrstaZahtjeva>3</VrstaZahtjeva><Parametri /></Zahtjev>`;
    return this.sendPrintOrder(data, 'stampatipresjekstanja');
  }

  printPeriodicReport(fromDateTime, toDateTime) {
    if (this.printInfo.deviceType === 'NSC') {
      const data = `79,1,______,_,__;${moment(
        fromDateTime,
        'DD-MM-YYYY hh:ii:ss'
      ).format('DDMMYY')};${moment(toDateTime, 'DD-MM-YYYY hh:ii:ss').format(
        'DDMMYY'
      )};000000;235959;`;
      return this.sendPrintOrder(data, 'Periodični izvještaj');
    }
    if (this.printInfo.deviceType === 'Tring') {
      const data = `<?xml version="1.0" encoding="utf-8"?><Zahtjev xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><BrojZahtjeva>129345</BrojZahtjeva><VrstaZahtjeva>5</VrstaZahtjeva><Parametri><Parametar><Naziv>odDatuma</Naziv><Vrijednost>${fromDateTime}</Vrijednost></Parametar><Parametar><Naziv>doDatuma</Naziv><Vrijednost>${toDateTime}</Vrijednost></Parametar></Parametri></Zahtjev>`;
      return this.sendPrintOrder(data, 'stampatiperiodicniizvjestaj');
    }
  }

  printReceipt(params, documentType, invoiceAutoBr, reklamirani: boolean,
    VrstaPlacanja: any) {
    let printCommand = DOCUMENT_TYPES.find(
      (type) => type.value == documentType
    ).printCommand;

    let vrstaZahtjeva = reklamirani ? '2' : '0';

    let data;

    if (this.printInfo.deviceType === 'NSC') {
      data = params;
    }
    if (this.printInfo.deviceType === 'Tring') {
      // The ID of the extension we want to talk to.
      const printParams = new PrintModel(params);

      var xmlPrint = {
        RacunZahtjev: {
          BrojZahtjeva: params.brojRacuna,
          VrstaZahtjeva: vrstaZahtjeva,
          NoviObjekat: {
            Kupac: {
              IDbroj: printParams.kupac?.iDbroj,
              Naziv: printParams.kupac?.naziv,
              Adresa: printParams.kupac?.adresa,
              PostanskiBroj: printParams.kupac?.postanskiBroj,
              Grad: printParams.kupac?.grad,
            },
            StavkeRacuna: [
              ...printParams.stavkeRacuna.map((item) => {
                return {
                  RacunStavka: {
                    artikal: {
                      Sifra: item.artikal.sifra,
                      Naziv: item.artikal.naziv,
                      JM: item.artikal.jm,
                      Cijena: item.artikal.cijena,
                      Stopa: item.artikal.vatMark,
                      Grupa: item.artikal.grupa,
                      PLU: item.artikal.plu,
                    },
                    Kolicina: item.kolicina,
                    Rabat: item.rabat,
                  },
                };
              }),
            ],
            VrstePlacanja: {
              VrstaPlacanja
              /* VrstaPlacanja: {
                Oznaka: vrstaPlacanja,
                Iznos: params.vrstePlacanja[0].iznos,
              }, */
            },
            Napomena: params.napomena,
            BrojRacuna: params.brojRacuna,
          },
        },
      }

      if (printParams.kupac == null) {
        delete xmlPrint.RacunZahtjev.NoviObjekat.Kupac;
      } else if (printParams.kupac?.iDbroj.length == 0 && printParams.kupac?.naziv.length == 0) {
        delete xmlPrint.RacunZahtjev.NoviObjekat.Kupac;
      }

      data =
        '<?xml version="1.0" encoding="utf-8"?>' +
        XmlHelper.json2xml(xmlPrint, '');
    }

    return new Observable((sub: Subscriber<any>) => {
      //  Make a simple request:
      this.sendPrintOrder(data, printCommand).subscribe((response) => {
        if (this.printInfo.deviceType === 'Tring') {
          this.sendPrintResponse(
            invoiceAutoBr,
            response,
            reklamirani
          ).subscribe(() => {
            sub.next(response);
            sub.complete();
          }, error => {
            sub.next(); // ne vracati error, smatrat ce fiskalizaciju uspjesnom
            sub.complete();
          });
        }
        if (this.printInfo.deviceType === 'NSC') {
          response = JSON.parse(response);
          this.sendPrintResponse(
            invoiceAutoBr,
            response.data,
            reklamirani
          ).subscribe(() => {
            sub.next(response);
            sub.complete()
          });
        }
      });
    });
  }

  private sendPrintOrder(data: any, command: string) {
    return new Observable((sub: Subscriber<any>) => {
      //   @ts-ignore
      chrome.runtime.sendMessage(
        this.editorExtensionId,
        {
          data: data,
          command,
          ...this.printInfo,
        },
        (response) => {
          sub.next(response);
          sub.complete();
        }
      );
    });
  }

  sendPrintResponse(invoiceId, responseData, isReclamation) {
    return this.api.post('fiskalni-uredjaj/fiskalni-racun-response', {
      autoBr: invoiceId,
      reklamirani: isReclamation,
      kasaOdgovor: responseData,
    });
  }
}
