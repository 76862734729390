import { BaseModel } from "./base.model";

export class PaymentMethodModel extends BaseModel {
    vrsta: number;
    nacccinPlaccanja: string;
}

export class CurrencyModel extends BaseModel {
    sifra: number;
    valuta: string;
    stotinkaValute: string;
    skr: string;
    kurs: number;

    get valutaTranslated () {
        if(this.skr == 'BAM') {
            return 'KM';
        }
        else {
            return this.skr;
        }
    }
}