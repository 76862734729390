import { BaseModel } from "./base.model";
import { KomercijalistiDto } from "./komercijalisti.model";

export class ClientModel extends BaseModel {

    sifra: number;
    komitent: string;
    vrsta: number;
    pdvObveznik: boolean;
    identifikacioniBroj: string;
    sudskiRegBroj: string;
    pdvBroj: string;
    zziroRacccun: string;
    adminJedinica: number;
    grad: number;
    adresa: string;
    telefon: string;
    eMail: string;
    nGrad: string;
    nAdminJedinica: string;
    tagovi: string;
    kontoTrosska: string;
    komercijalisti: KomercijalistiDto;
    kontoDuguje: string;
    kontoPotrazzuje: string;
    search_label;

    get value() {
        return this.sifra;
    }

    get label() {
        return this.komitent;
    }

    get addressString() {
        return `${this.adresa}, ${this.nGrad}`
    }

    get lookupString() {
        return `${this.komitent}, ${this.identifikacioniBroj}`
    }

    get tagoviArray() {
        return (this.tagovi == undefined) ? [] : this.tagovi.split(";");
    }
}