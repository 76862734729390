import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AccountService } from './account.service';
import { environment } from '../../../environments/environment';
import { PagedResponse } from '../models/paged-response.model';
import { firmeDto } from '../models/firmeDto';
import { getAcceptLanguage } from '../helpers/getAcceptLanguage';
import { UserWithIdDto } from 'src/app/models/users/userWithIdDto';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';

@Injectable({
  providedIn: 'root',
})
export class FirmsService {
  constructor(
    private api: BaseApiService,
    private accountService: AccountService
  ) { }

  getFirmsForUser(userId: any = {}): Observable<any> {
    return this.api
      .post('firme-useri/firme-for-user', userId)
      .pipe(catchError(this.handleError<[]>(`firme-useri/firme-for-user`)));
  }

  getAllFirmsLookup(): Observable<any> {
    return this.api
      .get('firme/all-lookup')
      .pipe(catchError(this.handleError<[]>(`firme/all-lookup`)));
  }

  getAllBusinessUnits(context): Observable<any> {
    var lang = getAcceptLanguage.getLocalStorageLanguage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      firmaid: context?.organizationId?.toString() || '',
      godina: context?.year?.toString() || '',
    });
    const auth = this.accountService.accessToken;
    if (auth && auth.token) {
      headers = headers.append('Authorization', 'Bearer ' + auth.token);
    }
    return this.api
      .newRequest(
        'get',
        `${environment.apiUrl}poslovne-jedinice/all`,
        {},
        {},
        { headers, observe: 'response' }
      )
      .pipe(
        catchError(
          this.handleError<[]>(`${environment.apiUrl}poslovne-jedinice/all`)
        )
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }

  getPagedFirms(params: any): Observable<PagedResponse<firmeDto>> {
    return this.api.post('firme/paged', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<firmeDto>(response.body.result);
        pagedModel.items = pagedModel.items?.map((i) => new firmeDto(i));
        return pagedModel;
      })
    );
  }

  updateEmail(params: any = {}): Observable<any> {
    return this.api
      .post('firme/update-email', params)
      .pipe(catchError(this.handleError<[]>('firme/update-email')));
  }

  createFirm(params: any): Observable<any> {
    return this.api
      .post('firme/create-db', params)
      .pipe(catchError(this.handleError<[]>(`firme/create-db`)));
  }

  getUsersForCurrentFirm(): Observable<UserWithIdDto[]> {
    return this.api.get('firme/current-firma-users',).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new UserWithIdDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<UserWithIdDto[]>(`firme/current-firma-users`))
    );
  }

}
