import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { PagedResponse } from '../models/paged-response.model';
import { FinNaloziPodaciDto } from '../models/fin-nalozi-podaci.model';
import { qFIN_rpt_GRESKA_IZNOSI_DOKUMENATA_NEUKNJIZENJE_STAVKE } from '../models/financije/RESKA_IZNOSI_DOKUMENATA_NEUKNJIZENJE_STAVKE';
import { qFIN_AUTO_KNJIZENJE_GET_NEUKNJIZENO } from '../models/financije/qFIN_AUTO_KNJIZENJE_GET_NEUKNJIZENO';

@Injectable({
  providedIn: 'root'
})
export class FinNaloziPodaciService {
  constructor(private api: BaseApiService) { }

  getCreatePropertyList() {
    return this.api
      .get('financije/unos-naloga-podaci/create-property-list')
      .pipe(catchError(this.handleError<[]>(`financije/unos-naloga-podaci/create-property-list`)));
  }

  getAll(params: any = {}): Observable<FinNaloziPodaciDto[]> {
    return this.api.post('financije/unos-naloga-podaci/all', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FinNaloziPodaciDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FinNaloziPodaciDto[]>(`getAllFinNalozi`))
    );
  }

  getUknjizeno(params: any): Observable<PagedResponse<FinNaloziPodaciDto>> {
    return this.api.post('financije/unos-naloga-podaci/uknjizeno', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FinNaloziPodaciDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FinNaloziPodaciDto(i));
        return pagedModel;
      })
    );
  }

  getNeknjizeno(params: any): Observable<PagedResponse<FinNaloziPodaciDto>> {
    return this.api.post('financije/unos-naloga-podaci/neuknjizeno', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FinNaloziPodaciDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FinNaloziPodaciDto(i));
        return pagedModel;
      })
    );
  }

  getNeuknjizenoAll(params: any = {}): Observable<FinNaloziPodaciDto[]> {
    return this.api.post('financije/unos-naloga-podaci/neuknjizeno-all', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FinNaloziPodaciDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FinNaloziPodaciDto[]>(`neuknjizeno-all`))
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/by-id', params)
      .pipe(catchError(this.handleError<[]>(`financije/unos-naloga-podaci/by-id`)));
  }

  getByRobno(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/by-robno', params)
      .pipe(catchError(this.handleError<[]>(`financije/unos-naloga-podaci/by-robno`)));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/update', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/update')));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/create', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/create')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/delete', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/delete')));
  }

  ObrisiKompletanNalog(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/obrisi-kompletan-nalog', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/obrisi-kompletan-nalog')));
  }


  ispravkaSelektiranogDokumenta(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/ispravka-selektiranog-dokumenta', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/ispravka-selektiranog-dokumenta')));
  }

  ispravkaKompletnogNaloga(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/ispravka-kompletnog-naloga', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/ispravka-kompletnog-naloga')));
  }

  uknjiziNalog(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/uknjizi-nalog', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/uknjizi-nalog')));
  }

  pregledajGreskeKnjizenja(params: any): Observable<any> {
    return this.api.post('financije/unos-naloga-podaci/greske-knjizenja', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new qFIN_rpt_GRESKA_IZNOSI_DOKUMENATA_NEUKNJIZENJE_STAVKE(client));
        }
        return undefined;
      }),
      catchError(this.handleError<qFIN_rpt_GRESKA_IZNOSI_DOKUMENATA_NEUKNJIZENJE_STAVKE[]>(`getAllFinNalozi`))
    );

    /*   return this.api
        .post('financije/unos-naloga-podaci/greske-knjizenja', params)
        .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/greske-knjizenja'))); */
  }

  getSaldoNaloga(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/saldo-naloga', params)
      .pipe(catchError(this.handleError<[]>(`financije/unos-naloga-podaci/saldo-naloga`)));
  }

  getNeuknjizeniRimDokumenti(params: any): Observable<PagedResponse<qFIN_AUTO_KNJIZENJE_GET_NEUKNJIZENO>> {
    return this.api.post('financije/unos-naloga-podaci/neuknjizeni-rim', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<qFIN_AUTO_KNJIZENJE_GET_NEUKNJIZENO>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new qFIN_AUTO_KNJIZENJE_GET_NEUKNJIZENO(i));
        return pagedModel;
      })
    );
  }

  getNeuknjizenoIznosi(params: any = {}): Observable<FinNaloziPodaciDto[]> {
    return this.api.post('financije/unos-naloga-podaci/neuknjizeni-iznosi', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FinNaloziPodaciDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FinNaloziPodaciDto[]>(`NeuknjizenoIznosi`))
    );
  }

  autoKnjizenjeIzRIM(params: any): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/auto-knjizenje', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/auto-knjizenje')));
  }

  updateDMS(params: any = {}): Observable<any> {
    return this.api
      .post('financije/unos-naloga-podaci/update-dms', params)
      .pipe(catchError(this.handleError<[]>('financije/unos-naloga-podaci/update-dms')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
