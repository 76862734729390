<form focusInputAfterSubmit (ngSubmit)="saveItem()" [formGroup]="itemsFormGroup" class="p-3" #formElement
    #form="ngForm">
    <div (keyup.enter)="form.ngSubmit.emit({ target: formElement }); $event.stopPropagation()" class="py-4">
        <div class="row">
            <div class="col-sm-12 col-12 col-md-12 col-lg-6 col-xl-6 col-6 form-group">
                <label><span class="font-weight-bold">{{ "PROPERTIES.Artikal" | translate }} {{ articleIndex
                        }}</span></label>
                <div class="input-group custom-group">
                    <ng-select #artikalElement class="col p-0 select-append"
                        (keyup.enter)="$event.stopPropagation(); moveToNext($event)"
                        (keydown.enter)="$event.stopPropagation()" [items]="articles" bindLabel="lookupString"
                        bindValue="sifra" formControlName="artikal" loadingText="Ucitavanje..." [virtualScroll]="true"
                        [selectOnTab]="true" [searchFn]="customSearchFn">
                        <ng-template ng-header-tmp let-item="item">
                            <div class="w-100 d-flex">
                                <span class="w-25">{{ "PROPERTIES.Sifra" | translate }}</span>
                                <span class="w-75">{{ "PROPERTIES.Naziv" | translate }}</span>
                                <span *ngIf="(koristiLagerZaDodavanjeArtikala ) === 1" class="w-75">{{
                                    "PROPERTIES.Cijena" | translate }}</span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="w-100 d-flex">
                                <span class="w-25 text-wrap">{{ item.sifra }}</span>
                                <span class="w-75 text-wrap">{{ item.naziv }}</span>
                                <span *ngIf="koristiLagerZaDodavanjeArtikala === 1" class="w-75 text-wrap">{{
                                    item.cijena }} KM</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div class="input-group-append">
                        <span class="input-group-text p-0">
                            <button tabindex="-1" type="button" (click)="openAddNewArticleModal()"
                                class="btn btn-link btn-sm">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.ArtikalJeObvezan' ,
            control: itemsFormGroup.controls.artikal
          }">
                </ng-container>
            </div>

            <div class="col-sm-12 col-12 col-md-12 col-lg-6 col-xl-6 form-group">
                <label><span class="font-weight-bold">{{ "PROPERTIES.Kolicina" | translate }}</span></label>
                <app-number-input #kolicccina formControlName="kolicccina" [numberOfDecimals]="'2'"
                    [allowMinusSign]="'true'"></app-number-input>
                <div class="d-flex flex-column">
                </div>

                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                    control: itemsFormGroup.controls.kolicccina
                  }">
                </ng-container>
            </div>
        </div>
    </div>

    <div class="col-12 d-flex justify-content-end">
        <button *ngIf="updatingItem === -1" (click)="clearItemForm()" type="button" class="btn btn-light mr-2">
            {{ "PROPERTIES.Otkazi" | translate }}
        </button>
        <button *ngIf="updatingItem === -1" type="submit" class="btn btn-primary">
            {{ "PROPERTIES.DodajArtikal" | translate }}
        </button>
    </div>
</form>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control?.hasError(validation) && control?.touched">
        <small class="text-danger">{{ message | translate }}</small>
    </ng-container>
</ng-template>

<!-- <pre class="mb-0">{{form.value | json}}</pre> -->