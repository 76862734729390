<div class="card-body">
    <h5 class="mb-3 border-bottom">{{"DASHBOARD.Brzi unos zadatka" | translate}}</h5>
    <form #formElement [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">

            <div class="col-sm-12 col-12 col-lg-6 col-xl-6 form-group">
                <label class="font-weight-bold">
                    {{ "PROPERTIES.Komitent" | translate }}
                    <app-tooltip key="invoice.komitent"></app-tooltip>
                </label>
                <div class="input-group custom-group">
                    <ng-select class="col p-0" [items]="komitenti" formControlName="komitent"
                        loadingText="Ucitavanje..." bindLabel="komitent" bindValue="sifra" [virtualScroll]="true"
                        [selectOnTab]="true" [searchFn]="customSearchFn" [clearable]="false">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div> {{ item.komitent }}</div>
                            <div class="text-muted small">
                                {{ item.addressString }}
                            </div>
                            <!--<div class="text-muted small">JIB: {{item.identifikacioniBroj}}</div>-->
                        </ng-template>
                    </ng-select>
                    <div class="input-group-append">
                        <span class="input-group-text p-0">
                            <button tabindex="-1" (click)="openAddNewClientModal()" type="button"
                                class="btn btn-link btn-sm">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.KomitentJeObavezan',
            control: this.form.controls.komitent
          }">
                </ng-container>
            </div>

            <div class="col-sm-12 col-12 col-lg-6 col-xl-6 form-group">
                <label class="font-weight-bold">
                    {{ "PROPERTIES.Za korisnika" | translate }}
                </label>
                <div class="input-group custom-group">
                    <ng-select class="col p-0" [items]="users" formControlName="zaOperatera" loadingText="Ucitavanje..."
                        bindLabel="search_label" bindValue="id" [virtualScroll]="true" [selectOnTab]="true"
                        [searchFn]="customSearchFn" [clearable]="false">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div> {{ item.firstName }} {{ item.lastName }}</div>
                        </ng-template>
                    </ng-select>
                </div>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.form.controls.zaOperatera
          }">
                </ng-container>
            </div>

            <div class="col-12 form-group">
                <label class="font-weight-bold">
                    <span class=""> {{ "PROPERTIES.Zadatak" | translate }}</span>
                </label>
                <input formControlName="opis" class="form-control" type="text" />
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.form.controls.opis
          }">
                </ng-container>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
                <label class="font-weight-bold">
                    <span class=""> {{"PROPERTIES.Od datuma" | translate}}</span>
                </label>
                <input class="form-control" type="datetime-local" formControlName="od_datuma"
                    (ngModelChange)="odDatumaChange($event)" />
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
                <label class="font-weight-bold">
                    <span class=""> {{"PROPERTIES.Do datuma" | translate}}</span>
                </label>
                <input class="form-control" type="datetime-local" formControlName="do_datuma"
                    (ngModelChange)="doDatumaChange($event)" />
            </div>


        </div>

        <div class="col12 mt-4 text-right">
            <button class="btn btn-light mr-3" type="button" (click)="closeModal()">
                {{ "PROPERTIES.Otkazi" | translate }}
            </button>
            <button class="btn btn-primary">
                {{ "PROPERTIES.SpremiIzmjene" | translate }}
            </button>
        </div>
    </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <small class="text-danger">{{ message | translate }}</small>
    </ng-container>
</ng-template>