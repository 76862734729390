import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from "./shared/routes/routes";

@NgModule({
  imports: [
    [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
