import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-take-picture-modal',
  templateUrl: './take-picture-modal.component.html',
  styleUrls: ['./take-picture-modal.component.scss']
})
export class TakePictureModalComponent implements OnInit {
  width: number = 1280;
  height: number = 1000;
  @Input() updateInputValue = new EventEmitter();
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    facingMode: 'environment',
    width: { min: this.width, ideal: this.width, max: this.width },
    height: { min: this.height, ideal: this.height, max: this.height }
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  public innerWidth = window.innerWidth;

  constructor(public bsModalRef: BsModalRef) { }

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;

        setTimeout(() => {
          this.showNextWebcam(true);
        }, 1500);
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    //  console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.updateInputValue.emit(this.webcamImage);
    this.closeModal();
  }

  public cameraWasSwitched(deviceId: string): void {
    //  console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

}
