import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule, DatePipe, formatDate } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FfTasksService } from 'src/app/shared/services/fiskusfield/ff-tasks.service';
import { FirmsService } from 'src/app/shared/services/firms.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/shared/services/client.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddNewClientComponent } from '../add-new-client/add-new-client.component';
import { NavService } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-add-new-ff-task',
  standalone: false,
  templateUrl: './add-new-ff-task.component.html',
  styleUrl: './add-new-ff-task.component.scss'
})
export class AddNewFfTaskComponent implements OnInit {
  static TASK_ADDED = new EventEmitter();
  @Input() updateInputValue = new EventEmitter();
  komitenti;
  users;
  odDatuma;
  doDatuma

  form = new UntypedFormGroup({
    autoBr: new UntypedFormControl(null),
    komitent: new UntypedFormControl(null),
    zaOperatera: new UntypedFormControl(null, Validators.required),
    opis: new UntypedFormControl('', Validators.required),
    od_datuma: new UntypedFormControl(formatDate(new Date(Date.now()), 'yyyy-MM-dd HH:mm', 'en')),
    do_datuma: new UntypedFormControl(formatDate(new Date(Date.now()), 'yyyy-MM-dd HH:mm', 'en')),
  })

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private ffTasksService: FfTasksService,
    private firmsService: FirmsService,
    private translate: TranslateService,
    private clientService: ClientService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    public bsModalRef: BsModalRef,
    private navServices: NavService
  ) { }


  ngOnInit(): void {
    if (this.odDatuma != null) {
      this.form.controls.od_datuma.setValue(formatDate(new Date(this.odDatuma), 'yyyy-MM-dd HH:mm', 'en'))
    }

    if (this.doDatuma != null) {
      this.form.controls.do_datuma.setValue(formatDate(new Date(this.doDatuma), 'yyyy-MM-dd HH:mm', 'en'))
    }

    this.getClients();
    this.getUsers();
  }

  getClients() {
    this.clientService.getAllClients().subscribe((clients) => {
      this.komitenti = clients;

      this.komitenti = this.komitenti.map((o) => {
        o.search_label = ` ${o.sifra!} ${o.komitent!} `; //${o.identifikacioniBroj!} ${o.pdvBroj!} 
        return o;
      });
    });
  }

  getUsers() {
    this.firmsService.getUsersForCurrentFirm().subscribe(resp => {
      this.users = resp;

      this.users = this.users.map((o) => {
        o.search_label = ` ${o.firstName!} ${o.lastName!}  `;
        return o;
      });
    })
  }

  onSubmit() {
    if (this.form.valid) {
      this.createTask();
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  createTask() {
    this.ffTasksService.create(this.form.value).subscribe((res) => {
      this.toastr.success(this.translate.instant("MESSAGE.Fiskus field zadatak uspješno keiran"));
      AddNewFfTaskComponent.TASK_ADDED.emit(true);
      this.updateInputValue.emit(res);
      this.closeModal();
    },
      (err) => {
        console.log(err);
      }
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      let search = item['search_label'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) != -1);
    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }

  odDatumaChange($event) {
    var odDatuma = new Date($event);
    var doDatuma = new Date(this.form.controls.do_datuma.value);

    if (odDatuma > doDatuma) {
      var setDoDatuma = formatDate(new Date(odDatuma), 'yyyy-MM-dd HH:mm', 'en')
      this.form.controls.do_datuma.setValue(setDoDatuma, { emitEvent: false, });
    }
  }

  doDatumaChange($event) {
    var odDatuma = new Date(this.form.controls.od_datuma.value);
    var doDatuma = new Date($event);

    if (doDatuma < odDatuma) {
      var setOdDatuma = formatDate(new Date(doDatuma), 'yyyy-MM-dd HH:mm', 'en')
      this.form.controls.od_datuma.setValue(setOdDatuma, { emitEvent: false, });
    }
  }

  openAddNewClientModal() {
    const initialState: ModalOptions = {
      /* initialState: {
      }, */
      class: "modal-dialog-centered modal-lg"
    };
    this.bsModalRef = this.modalService.show(AddNewClientComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.getClients();
        this.form.controls.komitent.setValue(res);
      }
    });
  }
}
