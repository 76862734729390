import { AfterViewInit, Component, EventEmitter, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { formatDate, registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FFObilasciService } from 'src/app/shared/services/fiskusfield/ff-obilasci.service';
import { FfTasksService } from 'src/app/shared/services/fiskusfield/ff-tasks.service';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { addDays, isSameDay, isSameMonth } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ObilazakPodaciModalComponent } from '../../obilazak-podaci-modal/obilazak-podaci-modal.component';
import { FfAddNewTaskObilazakComponent } from '../ff-add-new-task-obilazak/ff-add-new-task-obilazak.component';
import { AddNewFfTaskComponent } from 'src/app/shared/components/modals/add-new-ff-task/add-new-ff-task.component';
import { FfTasksPreviewComponent } from '../../ff-tasks/ff-tasks-preview/ff-tasks-preview.component';
import { AccountService } from 'src/app/shared/services/account.service';
import { FfSelectTipFormeModalComponent } from '../../obilasci/ff-select-tip-forme-modal/ff-select-tip-forme-modal.component';
import { FfAddNewObilazakComponent } from '../../obilasci/ff-add-new-obilazak/ff-add-new-obilazak.component';
import { CanvasWhiteboardComponent } from 'ng2-canvas-whiteboard';
import { NotificationComponent } from 'src/app/shared/components/header/elements/notification/notification.component';

const colors: any = {
  primary: {
    primary: '#6052FF',
    secondary: '#6052FF'
  },

  secondary: {
    primary: '#f73164',
    secondary: '#f73164',
  },

  red: {
    primary: '#4466f2',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-ff-calendar',
  encapsulation: ViewEncapsulation.None, // hack to get the styles to apply locally
  templateUrl: './ff-calendar.component.html',
  styleUrl: './ff-calendar.component.scss',
  viewProviders: [CanvasWhiteboardComponent],
})
export class FfCalendarComponent implements OnInit, AfterViewInit {
  static OBILAZAK_ADDED = new EventEmitter();
  events: CalendarEvent[] = [];
  eventsObilasci: CalendarEvent[] = [];
  eventsTasks: CalendarEvent[] = [];
  obilasci = [];
  tasks = [];
  currStartDate = new Date();
  currEndDate = new Date();
  operater = 0;
  activeDayIsOpen: boolean = true;
  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Week;
  calendarView = CalendarView;
  viewDate: Date = this.firstDayOfWeek(new Date());
  refresh = new Subject<void>(); //  declare this property for data bind in html
  dayStartHour = "7"
  locale;
  bsModalRef: BsModalRef;
  public isMobile: boolean = window.innerWidth < 991 && window.outerWidth < 991;
  roleClaims = this.accountService.roleClaims;
  userSettings;
  operateri;

  private messageSource = new BehaviorSubject("");
  currentMessage = this.messageSource.asObservable();

  constructor(
    private translate: TranslateService,
    private ffTasksService: FfTasksService,
    private ffObilasciService: FFObilasciService,
    private modalService: BsModalService,
    private accountService: AccountService,
  ) {

    FfTasksPreviewComponent.TASK_CHANGED.subscribe(() => {
      this.getTasksRange();
    })

    NotificationComponent.OBILAZAK_ADDED.subscribe(() => {
      this.getObilasciRange();
    })
  }

  ngOnInit(): void {
    registerLocaleData(this.translate.currentLang);
    this.getUserSettings();

    if (this.isMobile) {
      this.view = CalendarView.Day;
      this.viewDate = new Date();
    }
    else {
      this.currStartDate = this.firstDayOfWeek(this.currStartDate);
      this.currEndDate.setDate(this.currStartDate.getDate() + 7)
    }

    this.getObilasciRange();
    this.getTasksRange();
    this.getOperatere();
  }

  onEventClick($event) {
    console.log($event)
  }

  ngAfterViewInit(): void {
  }

  getUserSettings() {
    this.userSettings = this.accountService.getLocalStorageUserSettings();
    if (this.userSettings = {}) {
      this.accountService.getUserSettings().subscribe(resp => {
        this.userSettings = resp.body.result.item;
        this.accountService.setLocalStorageUserSettings(this.userSettings);
      })
    }
  }

  pushEvents() {
    this.events = [];
    this.events = [... this.eventsObilasci, ... this.eventsTasks];
  }

  getOperatere() {
    this.ffObilasciService.getOperatereZaFirmu().subscribe(resp => {
      this.operateri = resp;
    })
  }

  getTasksRange() {
    var payload = {
      odDatuma: formatDate(this.currStartDate, 'yyyy-MM-ddT00:00', 'en'),
      doDatuma: formatDate(this.currEndDate, 'yyyy-MM-ddT23:59', 'en'),
      operater: this.operater
    };
    this.tasks = [];
    this.eventsTasks = [];

    if (this.isAuthorized('ffTasks.admin')) {
      this.ffTasksService.getDateRangeAllUsers(payload).subscribe(resp => {
        this.tasks = resp;
        this.tasksRangePush();
      })
    }
    else {
      this.ffTasksService.getDateRangeCurrentUser(payload).subscribe(resp => {
        this.tasks = resp;
        this.tasksRangePush();
      })
    }

  }

  tasksRangePush() {
    this.tasks.forEach(element => {
      var dttOd = new Date(element.od_datuma.replace("Z", ""))
      var dttDo = new Date(element.do_datuma.replace("Z", ""))

      let days = Math.floor((dttDo.getTime() - dttOd.getTime()) / 1000 / 60 / 60 / 24);

      var event: CalendarEvent = {
        id: element.autoBr,
        title: element.opis + " - " + element.komitenti.komitent,
        start: new Date(dttOd),
        end: new Date(dttDo),
        resizable: {
          beforeStart: false,
          afterEnd: false
        },
        draggable: false,
        meta: "task",
        cssClass: (element.zavrseno) ? 'secondary-class-done' : 'secondary-class',
        color: colors.secondary,
        allDay: (days > 1) ? true : false
        //  actions: this.actions,
      };
      this.eventsTasks.push(event);
    });
    this.pushEvents();
  }

  getObilasciRange() {
    var payload = {
      odDatuma: formatDate(this.currStartDate, 'yyyy-MM-ddT00:00', 'en'),
      doDatuma: formatDate(this.currEndDate, 'yyyy-MM-ddT23:59', 'en'),
      operater: this.operater
    };
    this.obilasci = [];
    this.eventsObilasci = [];

    if (this.isAuthorized('fiskusfield.admin')) {
      this.ffObilasciService.GetForDateRangeAllUsers(payload).subscribe(resp => {
        this.obilasci = resp.body.result;
        this.obilasciRangePush();
      });
    }
    else {
      this.ffObilasciService.GetForDateRangeCurrentUser(payload).subscribe(resp => {
        this.obilasci = resp.body.result;
        this.obilasciRangePush();
      });
    }
  }

  obilasciRangePush() {
    this.obilasci.forEach(element => {
      var dtt = new Date(element.datumIVrijeme) //.replace("Z", "") // ovo vrijedi samo za android field
      var event: CalendarEvent = {
        id: element.autoBr,
        title: element.komitenti.komitent + " - " + element.user + " - " + element.ffTipoviFormi.naziv,
        start: new Date(dtt),
        end: new Date(dtt),
        resizable: {
          beforeStart: false,
          afterEnd: false
        },
        draggable: false,
        meta: "obilazak",
        cssClass: 'primary-class',
        color: colors.primary
      };
      this.eventsObilasci.push(event);
    });
    this.pushEvents();
  }

  //day in month clicked
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    //this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  //pregled eventa - dohvati obilazak info
  eventClicked($event) {
    if ($event.meta == 'obilazak') {
      this.obilazakPreview($event.id);
    }
    else if ($event.meta == 'task') {
      var item = this.tasks.find(x => x.autoBr == $event.id);
      this.taskPreview(item);
    }
  }

  viewDateChange($event: Date) {
    if (this.view == CalendarView.Day) {
      this.currStartDate = $event;
      this.currEndDate = $event;
    }
    else if (this.view == CalendarView.Week) {
      var toDate = new Date();
      toDate.setDate($event.getDate() + 6);
      this.currStartDate = $event;
      this.currEndDate = toDate;
    }
    else if (this.view == CalendarView.Month) {
      var from: Date = $event;
      from.setHours(0, 0, 0)
      var to = addDays(from, 31);

      this.currStartDate = from;
      this.currEndDate = to;
    }

    this.activeDayIsOpen = false;
    this.getTasksRange();
    this.getObilasciRange();
  }

  firstDayOfWeek(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  monthButtonClicked() {
    this.view = CalendarView.Month;
    var dt = this.viewDate;
    dt.setDate(1);
    var from = dt;
    from.setHours(0, 0, 0)
    var to = addDays(dt, 31);

    this.currStartDate = from;
    this.currEndDate = to;
    this.getObilasciRange();
    this.getTasksRange();
  }

  dayButtonClicked() {
    this.view = CalendarView.Day;
    var dt = this.viewDate;

    this.currStartDate = dt;
    this.currEndDate = dt;
    this.getObilasciRange();
    this.getTasksRange();
  }

  weekButtonClicked() {
    this.view = CalendarView.Week;
    var dt = this.viewDate;
    var from = this.firstDayOfWeek(dt);
    var to = new Date();
    to.setDate(from.getDate() + 6);

    this.viewDate = from;
    this.currStartDate = from;
    this.currEndDate = to;
    this.getObilasciRange();
    this.getTasksRange();
  }

  todayClicked() {
    var today = new Date();

    this.currStartDate = today;
    this.currEndDate = today;
    this.getObilasciRange();
    this.getTasksRange();
  }

  taskPreview(task) {
    var initialState: ModalOptions = {
      initialState: {
        task: task
      },
      class: 'modal-dialog-centered modal-lg'
    };
    this.bsModalRef = this.modalService.show(FfTasksPreviewComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((resTaskPreview: any) => {
      if (resTaskPreview) {
        if (resTaskPreview == true) {
          // this.getTasksRange();
          this.bsModalRef.hide();
        }
        else {
          this.bsModalRef = this.modalService.show(FfSelectTipFormeModalComponent, initialState);
          this.bsModalRef.content.updateInputValue.subscribe((resTipForme: any) => {
            if (resTipForme) {
              //unesi obilazak
              initialState = {
                initialState: {
                  tipForme: resTipForme,
                  taskId: resTaskPreview.taskId,
                  komitentId: resTaskPreview.komitentId
                },
                class: "modal-dialog-centered modal-xl",
                backdrop: 'static',
              };
              this.bsModalRef = this.modalService.show(FfAddNewObilazakComponent, initialState);
              this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
                if (res) {
                  this.bsModalRef.hide();
                  //obilazak successfull
                  this.getObilasciRange();
                  this.getTasksRange();
                  FfCalendarComponent.OBILAZAK_ADDED.emit(res);
                  this.bsModalRef.hide();
                }
                else {
                  this.bsModalRef.hide();
                }
              });
            }
          });
        }
      } else {
        this.bsModalRef.hide();
      }
    });
  }

  obilazakPreview(autoBr) {
    const initialState: ModalOptions = {
      initialState: {
        autoBr: autoBr
      },
      class: 'modal-dialog-centered modal-lg'
    };
    this.bsModalRef = this.modalService.show(ObilazakPodaciModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.bsModalRef.hide();
      }
      else this.bsModalRef.hide();
    });
  }

  weekOnEmptyClick($event) {
    this.addNew($event);
  }

  dayOnEmptyClick($event) {
    this.addNew($event);
  }


  addNew($event?) {
    if (this.isCurrentYear() == false) {
      let currentYear = new Date().getFullYear();
      var htmlError =
        `${this.translate.instant("MESSAGE.Nije odabrana")} <b>${currentYear}</b>. ${this.translate.instant("MESSAGE.poslovna godina")}! ${this.translate.instant("MESSAGE.Molim odaberite poslovnu godinu u padajućem meniju ili otvorite poslovnu godinu")}<br/> <br/>`;
      Swal.fire({
        title: this.translate.instant("PROPERTIES.Poslovna godina"),
        html: htmlError,
      });
      return;
    }
    const initialState: ModalOptions = {
      initialState: {
        //text: this.translate.instant("MESSAGE.Želite li obrisati stavku?")
      },
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(FfAddNewTaskObilazakComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        if (res == "task") {
          this.openAddNewTask($event);
        }
        else if (res == "obilazak") {
          this.openAddNewTipForme();
        }
      }
    });
  }

  openAddNewTipForme() {
    var initialState: ModalOptions = {
      /* initialState: {}, */
      class: "modal-dialog-centered modal-lg",
      backdrop: 'static',
    };
    this.bsModalRef = this.modalService.show(FfSelectTipFormeModalComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {

        //unesi obilazak
        initialState = {
          initialState: {
            tipForme: res,
          },
          class: "modal-dialog-centered modal-xl",
          backdrop: 'static',
        };
        this.bsModalRef = this.modalService.show(FfAddNewObilazakComponent, initialState);
        this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
          if (res) {
            //obilazak successfull
            this.bsModalRef.hide();
            this.getObilasciRange();
          }
          else {
            //force close 
            this.bsModalRef.hide();
          }
        });
      }
    });
  }

  isCurrentYear(): boolean {
    let context = JSON.parse(localStorage.getItem('userContext'));
    let currentYear = new Date().getFullYear();
    if (context?.year == currentYear) {
      return true;
    } else {
      return false;
    }
  }

  openAddNewTask($event) {
    const initialState: ModalOptions = {
      initialState: {
        odDatuma: $event?.date,
        doDatuma: $event?.date
      },
      class: "modal-dialog-centered modal-lg",
      backdrop: 'static',
    };
    this.bsModalRef = this.modalService.show(AddNewFfTaskComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.getTasksRange();
        this.bsModalRef.hide();
      }
      else {
        this.bsModalRef.hide();
      }
    });
  }

  isAuthorized(claim) {
    if (this.roleClaims.includes(`permission.${claim}`)) {
      return true;
    } else {
      return false;
    }
  }

  setViewDataSwipe(message: string) {
    if (this.view == CalendarView.Day) {
      var dt: Date = this.viewDate;
      dt = addDays(dt, (message == 'previous') ? -1 : 1);
      this.viewDate = dt;
      this.viewDateChange(dt);
    }
    else if (this.view == CalendarView.Week) {
      var dt: Date = this.viewDate;
      dt = addDays(dt, (message == 'previous') ? -7 : 7);
      this.viewDate = dt;
      this.viewDateChange(dt);
    }
  }

  setDirection(message: string) {
    this.messageSource.next(message);
    this.setViewDataSwipe(message);
  }
  public swipeNext() {
    this.setDirection("next");

  }
  public swipePrevious() {
    this.setDirection("previous");
  }
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    }
    else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        if (direction[0] < 0) {
          //next
          this.swipeNext();
        } else {
          //previous
          this.swipePrevious();
        }
      }
    }
  }

  operaterChanged($event) {
    if ($event == undefined) {
      this.operater == 0;
    }
    this.getObilasciRange();
    this.getTasksRange();
  }
}
