import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FfCalendarComponent } from 'src/app/pages/fiskus-field/pages/ff-calendar/ff-calendar/ff-calendar.component';
import { FfTasksPreviewComponent } from 'src/app/pages/fiskus-field/pages/ff-tasks/ff-tasks-preview/ff-tasks-preview.component';
import { FfAddNewObilazakComponent } from 'src/app/pages/fiskus-field/pages/obilasci/ff-add-new-obilazak/ff-add-new-obilazak.component';
import { FfSelectTipFormeModalComponent } from 'src/app/pages/fiskus-field/pages/obilasci/ff-select-tip-forme-modal/ff-select-tip-forme-modal.component';
import { FfTasksService } from 'src/app/shared/services/fiskusfield/ff-tasks.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { AddNewFfTaskComponent } from '../../../modals/add-new-ff-task/add-new-ff-task.component';
import { DmsKomentariService } from 'src/app/shared/services/dms-komentari.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { auto } from '@popperjs/core';
import { AccountService } from 'src/app/shared/services/account.service';
import { LicenceService } from 'src/app/shared/services/licence.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  static OBILAZAK_ADDED = new EventEmitter();
  public openNotification: boolean = false;
  notifications;
  tasks;
  unseenComments;
  bsModalRef: BsModalRef;
  roleClaims = this.accountService.roleClaims;

  pushTasks = [];
  pushUnseenComments = [];
  pushLicence = [];

  constructor(
    private ffTasksService: FfTasksService,
    private modalService: BsModalService,
    private navServices: NavService,
    private dmsKomentariService: DmsKomentariService,
    private translate: TranslateService,
    private router: Router,
    private accountService: AccountService,
    private licence: LicenceService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getTasks();
      this.getUnseenDMSComments();
      this.getLicencePredIstek();
    }, 1000);

    FfCalendarComponent.OBILAZAK_ADDED.subscribe(resp => {
      if (resp) {
        this.getTasks();
      }
    });

    AddNewFfTaskComponent.TASK_ADDED.subscribe(resp => {
      if (resp) {
        this.getTasks();
      }
    });

    FfTasksPreviewComponent.TASK_CHANGED.subscribe(resp => {
      if (resp) {
        this.getTasks();
      }
    });
  }

  getTasks() {
    this.ffTasksService.getUndodeCurentUser().subscribe(resp => {
      this.tasks = resp;

      this.pushTasks = this.tasks.map(item => {
        var dttDo = new Date(item.do_datuma?.replace("Z", ""))
        var unfinished = {
          type: 1, //task
          id: item.autoBr,
          desc: item.komitenti.komitent + ' - ' + item.opis,
          date: new Date(dttDo),
        }
        return unfinished;
      });
      this.pushNotifications();
    })
  }

  getUnseenDMSComments() {
    if (this.isAuthorized('dms-komentari.preview')) {
      this.dmsKomentariService.getAllUnseen({}).subscribe(resp => {
        this.unseenComments = resp.body.result;

        this.pushUnseenComments = this.unseenComments.map(item => {
          var dtt = new Date(item.datum?.replace("Z", ""))
          var desc = 'DMS #' + item.fiweDms.autoBr + ' ' + item.komentar;
          var unfinished = {
            type: 2, //task,
            id: item.autoBr_,
            dms: item.fiweDms.autoBr,
            desc: desc,
            date: new Date(dtt),
          }
          return unfinished;
        })
        this.pushNotifications();
      })
    }
  }

  getLicencePredIstek() {
    if (this.isAuthorized('licence.licence-pred-istek')) {
      var payload = {
        page: 1,
        pageSize: 30
      };
      this.licence.licencePredIstek(payload).subscribe(resp => {
        var licence = resp.body.result;

        if (licence.length > 0) {
          var desc = 'Broj licenci: ' + licence.length;
          var item = {
            type: 3, //licence,
            id: 0,
            dms: 0,
            desc: desc,
            date: new Date(),
          }
          this.pushLicence.push(item);
          this.pushNotifications();
        }
      })
    }
  }

  pushNotifications() {
    this.notifications = [];
    this.notifications = [...this.pushTasks, ... this.pushUnseenComments, ... this.pushLicence];
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  getNotification(id: number, type: string) {
    if (type == "task") {
      var task = this.tasks.find(x => x.autoBr == id);
      this.taskPreview(task);
    }
  }

  notificationClickedDMS(autobr: number, dms: number) {
    var payload = {
      autoBr_: autobr
    };
    this.dmsKomentariService.setSeen(payload).subscribe(resp => {
      this.getUnseenDMSComments();
    });
    this.router.navigateByUrl("/dms/dms-dokumenti/" + dms)
  }

  taskPreview(task) {
    var initialState: ModalOptions = {
      initialState: {
        task: task
      },
      class: 'modal-dialog-centered modal-lg'
    };
    this.bsModalRef = this.modalService.show(FfTasksPreviewComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((resTaskPreview: any) => {
      if (resTaskPreview) {
        if (resTaskPreview == true) {
          this.getTasks();
          this.bsModalRef.hide();
        }
        else {
          this.bsModalRef = this.modalService.show(FfSelectTipFormeModalComponent, initialState);
          this.bsModalRef.content.updateInputValue.subscribe((resTipForme: any) => {
            if (resTipForme) {
              //unesi obilazak
              initialState = {
                initialState: {
                  tipForme: resTipForme,
                  taskId: resTaskPreview.taskId,
                  komitentId: resTaskPreview.komitentId
                },
                class: "modal-dialog-centered modal-xl",
                backdrop: 'static',
              };
              this.bsModalRef = this.modalService.show(FfAddNewObilazakComponent, initialState);
              this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
                if (res) {
                  this.bsModalRef.hide();
                  //obilazak successfull
                  NotificationComponent.OBILAZAK_ADDED.emit(true);
                  this.getTasks();
                  this.bsModalRef.hide();
                }
                else {
                  this.bsModalRef.hide();
                }
              });
            }
          });
        }
      }
      else {
        this.bsModalRef.hide();
      }
    });
  }

  isAuthorized(claim) {
    if (this.roleClaims.includes(`permission.${claim}`)) {
      return true;
    } else {
      return false;
    }
  }

}
