import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService } from 'src/app/shared/services/account.service';
import { FirmsService } from '../../services/firms.service';
import { LayoutService } from '../../services/layout.service';
import { NavService } from '../../services/nav.service';
import { ALL_POSTAVKE, BUSINESS_UNIT } from '../../../_constants/appMessages';
import { PostavkeAllService } from '../../services/postavke-all.service';
import { Location } from '@angular/common'
import { DmsUnregisteredModalComponent } from 'src/app/pages/dms/dms-list/dms-list/dms-unregistered-modal/dms-unregistered-modal.component';

@Component({
  selector: 'app-context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.scss'],
})
export class ContextComponent implements OnInit {
  static CONTEXT_CHANGED = new EventEmitter();

  public organizationsForUser = [];
  public allOrganizations = [];
  public organizationsToDisplay: any = [];
  public years = [];
  public yearsToDisplay = [];
  public businessUnits = [];
  isDesktop: boolean = window.innerWidth > 990; // && window.outerWidth > 990;

  context: any = {};

  isContextDisabled: boolean = false;
  isContextYearDisabled: boolean = false;

  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    public firmsService: FirmsService,
    public accountService: AccountService,
    private route: Router,
    private postavkeService: PostavkeAllService,
    private location: Location,
    @Inject(DOCUMENT) private document: any
  ) {

    DmsUnregisteredModalComponent.CONTEXT_CHANGED.subscribe((resp) => {
      this.context.organizationId = resp.id;
      this.organizationChange();
      this.setLocation('/dms/dms-dokumenti');
    });
  }

  ngOnInit(): void {
    this.context = this.accountService.context;
    this.getFirmsForUser();
    this.disableContext();
    this.disableContextYear();
    this.getUserSettings();

    this.route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.disableContext();
        this.disableContextYear();
      }
    });
  }

  disableContextYear() {
    this.isContextYearDisabled = this.route.url.includes('/invoice/create');
  }

  disableContext() {
    this.isContextDisabled =
      this.route.url.includes('/invoice/update') ||
      this.route.url.includes('/invoice/preview');
  }

  getUserSettings() {
    var setting = this.accountService.getLocalStorageUserSettings();
    if (setting = {}) {
      this.accountService.getUserSettings().subscribe(resp => {
        var userSettings = resp.body.result.item;
        this.accountService.setLocalStorageUserSettings(userSettings);
      })
    }
  }

  getFirmsForUser() {
    this.firmsService.getFirmsForUser().subscribe((res) => {
      this.organizationsForUser = res.body.result;
      this.getFirmsToDisplay();
    });
  }

  getFirmsToDisplay() {
    this.organizationsToDisplay = this.organizationsForUser;
    const currentOrganization = this.organizationsToDisplay.find(
      (u) => u.id == this.context.organizationId
    );
    if (!this.context.organizationId || !currentOrganization) {
      this.context.organizationId = this.organizationsToDisplay[0].id;
    }
    this.getYearsForFirm();
  }

  getYearsForFirm() {
    this.yearsToDisplay = [];
    for (let i = 0; i < this.organizationsForUser.length; i++) {
      for (let k = 0; k < this.organizationsForUser[i].godine.length; k++) {
        if (this.organizationsForUser[i].id == this.context.organizationId) {
          let yearsToDisplay = this.organizationsForUser[i].godine[k];
          this.yearsToDisplay.push(yearsToDisplay);
        }
      }
    }
    const currentYear = this.yearsToDisplay.find(
      (u) => u.godina == this.context.year
    );
    if (!this.context.year || !currentYear) {
      this.context.year = this.yearsToDisplay[0].godina;
    }
    this.getBusinessUnits();
  }

  getAllPostavke() {
    this.postavkeService.getAllPostavke().subscribe((resp) => {
      localStorage.setItem(ALL_POSTAVKE, JSON.stringify(resp));
    });
  }

  setContext() {
    this.accountService.context = {
      organizationId: this.context.organizationId,
      year: this.context.year,
      businessUnit: this.context.businessUnit,
    };
    this.accountService.contextFirmData = this.organizationsToDisplay.find(
      (o) => o.id == this.context.organizationId
    );
  }

  getBusinessUnits() {
    this.firmsService.getAllBusinessUnits(this.context).subscribe((res) => {
      this.businessUnits = [];
      this.businessUnits = res.body.result;
      const currentUnit = this.businessUnits.find(
        (u) => u.sifra == this.context.businessUnit
      );
      localStorage.setItem(BUSINESS_UNIT, JSON.stringify(currentUnit));
      if (!this.context.businessUnit || !currentUnit) {
        this.context.businessUnit = this.businessUnits[0].sifra;
        localStorage.setItem(
          BUSINESS_UNIT,
          JSON.stringify(this.businessUnits[0])
        );
      }
      this.setContext();
      this.getAllPostavke();
    });
  }

  changeContext() {
    this.setContext();
    ContextComponent.CONTEXT_CHANGED.emit(this.context);
  }

  organizationChange() {
    this.getYearsForFirm();
    this.changeContext();
    this.setLocation('/');
  }

  setLocation(loc: string) {
    location.href = loc;
  }

  yearChange() {
    this.getBusinessUnits();
    this.changeContext();
  }

  businessUnitChange() {
    const unit = this.businessUnits.find(
      (u) => u.sifra == this.context.businessUnit
    );
    if (!unit) return;
    localStorage.setItem(BUSINESS_UNIT, JSON.stringify(unit));
    this.changeContext();
  }

  isSearchable() {
    if (this.isDesktop) return true;
    return false;
  }


}
