import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule, DatePipe, formatDate } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FfTasksService } from 'src/app/shared/services/fiskusfield/ff-tasks.service';
import { FirmsService } from 'src/app/shared/services/firms.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/shared/services/client.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FfSelectTipFormeModalComponent } from '../../obilasci/ff-select-tip-forme-modal/ff-select-tip-forme-modal.component';
import { FfAddNewObilazakComponent } from '../../obilasci/ff-add-new-obilazak/ff-add-new-obilazak.component';
import { NavService } from 'src/app/shared/services/nav.service';
import { CallService } from 'src/app/shared/services/call/call.service';

@Component({
  selector: 'app-ff-tasks-preview',
  standalone: false,
  templateUrl: './ff-tasks-preview.component.html',
  styleUrl: './ff-tasks-preview.component.scss'
})
export class FfTasksPreviewComponent implements OnInit {
  task;
  @Input() updateInputValue = new EventEmitter();
  static TASK_CHANGED = new EventEmitter();
 
  komitenti;
  users;
  odDatuma;
  doDatuma;
  saving = false;

  form = new UntypedFormGroup({
    autoBr: new UntypedFormControl(null),
    komitent: new UntypedFormControl(null),
    zaOperatera: new UntypedFormControl(null),
    opis: new UntypedFormControl(''),
    od_datuma: new UntypedFormControl(formatDate(new Date(Date.now()), 'yyyy-MM-dd HH:mm', 'en')),
    do_datuma: new UntypedFormControl(formatDate(new Date(Date.now()), 'yyyy-MM-dd HH:mm', 'en')),
  })

  constructor(
    private toastr: ToastrService,
    private ffTasksService: FfTasksService,
    private translate: TranslateService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private navServices: NavService,
    private callService: CallService
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  kreirajObilazak() {
    this.openAddNewTipForme();
  }

  forceCloseModal() {
    this.updateInputValue.emit(false);
    this.bsModalRef.hide();
  }

  zavrsiZadatak() {
    this.saving = true;
    var payload = {
      autoBr: this.task.autoBr
    }
    this.ffTasksService.endTask(payload).subscribe(resp => {
      this.toastr.success(this.translate.instant("MESSAGE.Zadatak uspješno završen"))
      this.saving = false;
      this.updateInputValue.emit(true);
      FfTasksPreviewComponent.TASK_CHANGED.emit(true);
      this.closeModal();
    })
  }

  openAddNewTipForme() {
    var payload = {
      taskId: this.task.autoBr,
      komitentId: this.task.komitent
    }
    this.updateInputValue.emit(payload);
    this.closeModal();
  }

  callViber() {
    this.callService.callViber(this.task.komitenti.telefon);
  }

  callPhone() {
    this.callService.callPhone(this.task.komitenti.telefon);
  }

  callWhatsapp() {
    this.callService.callWhatsapp(this.task.komitenti.telefon);
  }

  isCurrentYear(): boolean {
    let context = JSON.parse(localStorage.getItem('userContext'));
    let currentYear = new Date().getFullYear();
    if (context?.year == currentYear) {
      return true;
    } else {
      return false;
    }
  }

  openObilasciKomitent() {
    window.open("/fiskus-field/obilasci/0/0/" + this.task.komitent + "/0/0")
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    }
    else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        if (direction[0] < 0) {
          //next
          this.forceCloseModal();
        } else {
          //previous
          this.forceCloseModal();
        }
      }
    }
  }
}
