import { Injectable } from '@angular/core';
import { FFObilasciDto } from '../../models/fiskus-field/ffObilasciDto';
import { PagedResponse } from '../../models/paged-response.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { AccountService } from '../account.service';
import { getAcceptLanguage } from '../../helpers/getAcceptLanguage';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { OperatoriZaFirmuDto } from '../../models/fiskus-field/OperatoriZaFirmuDto';
import { UserWithIdDto } from 'src/app/models/users/userWithIdDto';

@Injectable({
  providedIn: 'root'
})
export class FFObilasciService {
  lang = getAcceptLanguage.getLocalStorageLanguage();

  constructor(private api: BaseApiService, private accountService: AccountService) { }

  getPaged(params: any): Observable<PagedResponse<FFObilasciDto>> {
    return this.api.post('fiskus-field/obilasci', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FFObilasciDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FFObilasciDto(i));
        return pagedModel;
      })
    );
  }

  getPagedCurrentUser(params: any): Observable<PagedResponse<FFObilasciDto>> {
    return this.api.post('fiskus-field/obilasci/paged-current-user', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FFObilasciDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FFObilasciDto(i));
        return pagedModel;
      })
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/obilasci/by-id', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/obilasci/by-id`)));
  }

  getObilasciExcel(payload) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': this.lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}fiskus-field/obilasci/export-obilasci-excel`,
      {
        odDatuma: payload.odDatuma,
        doDatuma: payload.doDatuma,
        odKomitenta: payload.odKomitenta,
        doKomitenta: payload.doKomitenta,
        operater: payload.operater,
        tipForme: payload.tipForme,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getOperatereZaFirmu() {
    return this.api.get('fiskus-field/obilasci/get-operateri-field').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.item.map(
            (obilasci) => new UserWithIdDto(obilasci)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<UserWithIdDto[]>(`fiskus-field/obilasci/get-operateri-field`))
    );
  }

  GetForDateRangeAllUsers(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/obilasci/date-range-all-users', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/obilasci/date-range-all-users`)));
  }

  GetForDateRangeCurrentUser(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/obilasci/date-range-current-user', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/obilasci/date-range-current-user`)));
  }

  create(params: any, files): Observable<any> {
    return this.api
      .postFormDataMultipleFiles('fiskus-field/obilasci/create', params, files)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/obilasci/create`)));
  }

  getFile(fileName) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': this.lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}fiskus-field/obilasci/get-file`,
      {
        fileName: fileName,
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  sendObilazakPDF(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/obilasci/send-obilazak-pdf', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/obilasci/send-obilazak-pdf`)));
  }

  fakturaIzObilaska(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/obilasci/faktura-iz-obilaska', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/obilasci/faktura-iz-obilaska`)));
  }

  getPDFObilazak(autoBr) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': this.lang,
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}fiskus-field/obilasci/obilazak-pdf`,
      {
        autoBr: autoBr,
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
