import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { ALL_POSTAVKE } from 'src/app/_constants/appMessages';
import { PagedResponse } from '../models/paged-response.model';
import { PostavkeDto } from '../models/postavke.model';
import { AccountService } from './account.service';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PostavkeAllService {
  constructor(private api: BaseApiService,
    private accountService: AccountService) { }

  getAllPostavke() {
    return this.api.get('all-postavke/all',).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (adminJedinice) => new PostavkeDto(adminJedinice)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<PostavkeDto[]>(`all-postavke/all`))
    );

  }

  getPaged(params: any): Observable<PagedResponse<PostavkeDto>> {
    return this.api.post('all-postavke', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<PostavkeDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new PostavkeDto(i));
        return pagedModel;
      })
    );
  }

  getPostavkaById(params: any): Observable<any> {
    return this.api
      .post('all-postavke/by-id', params)
      .pipe(catchError(this.handleError<[]>(`all-postavke/by-id`)));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('all-postavke/update', params)
      .pipe(catchError(this.handleError<[]>('all-postavke/update')));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('all-postavke/create', params)
      .pipe(catchError(this.handleError<[]>('all-postavke/create')));
  }


  deletePodgrupa(params: any): Observable<any> {
    return this.api
      .post('all-postavke/delete', params)
      .pipe(catchError(this.handleError<[]>('all-postavke/delete')));
  }

  findPostavku(parametar: string) {
    let allPostavke = JSON.parse(localStorage.getItem(ALL_POSTAVKE));
    let postavkaItem = allPostavke?.find(
      (postavka) => postavka.parametar == parametar
    )?.vrijednost;
    if (postavkaItem) {
      return postavkaItem;
    }
    else {
      return 0;
    }
  }

  updateCustomFirma(params: any = {}, firmaid): Observable<any> {
    return this.api
      .postCustom('all-postavke/update', params, firmaid)
      .pipe(catchError(this.handleError<[]>('all-postavke/update')));
  }

  createCustomFirma(params: any = {}, firmaid): Observable<any> {
    return this.api
      .postCustom('all-postavke/create', params, firmaid)
      .pipe(catchError(this.handleError<[]>('all-postavke/create')));
  }


  getAllPostavkeCustomFirma(firmaid) {
    return this.api
      .getCustom('all-postavke/all', firmaid
      )
      .pipe(
        map((response: HttpResponse<apiResponseFactory>) => {
          if (response.status == 200 && response?.body?.result) {
            return response.body.result.map(
              (postakve) => new PostavkeDto(postakve)
            );
          }
          return undefined;
        }),
        catchError(this.handleError<PostavkeDto[]>(`all-postavke/all`))
      );

  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }


  mozeUMinus(params: any): Observable<any> {
    return this.api
      .post('all-postavke/moze-u-minus', params)
      .pipe(catchError(this.handleError<[]>(`all-postavke/moze-u-minus`)));
  }

}
