<div class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  @if (notifications?.length > 0) {
  <span class="badge badge-pill badge-secondary">{{ notifications?.length}}</span>
  }
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">{{ "PROPERTIES.Obavijesti" | translate}}</h6>
  </li>
  @for (item of notifications; track $index) {
  @if (item.type == 1) {
  <li (click)="getNotification(item.id, 'task')">
    <p class="text-left">
      <i class="fa fa-circle-o mr-3 font-danger"></i> {{item.desc}}
      <span class="pull-right"> {{item?.date | date: 'dd.MM.yy HH:mm' }}</span>
    </p>
  </li>
  }
  @else if (item.type == 2) {
  <li (click)="notificationClickedDMS(item.id, item.dms)">
    <p class="text-left">
      <i class="fa fa-circle-o mr-3 font-success"></i> {{item.desc}}
      <span class="pull-right"> {{item.date | date: 'dd.MM.yy HH:mm' }}</span>
    </p>
  </li>
  }

  @else if (item.type == 3) {
  <li routerLink="/licence/pred-istek">
    <p class="text-left">
      <i class="fa fa-circle-o mr-3 font-warning"></i> {{item.desc}}
      <span class="pull-right"> {{item.date | date: 'dd.MM.yy HH:mm' }}</span>
    </p>
  </li>
  }
  }
  <!--  <li>
    <a class="btn btn-primary" href="#">Check all notification</a>
  </li> -->
</ul>