import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from 'src/app/shared/services/search.service';
import { NavService, Menu } from '../../../../services/nav.service';
import { FiscalizationModalComponent } from '../../../modals/Fiscalization/fiscalization-modal/fiscalization-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from '../../header.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() isXHidden: boolean;

  public menuItems: Menu[];
  public items: Menu[];

  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;
  bsModalRef: BsModalRef;

  constructor(
    public navServices: NavService,
    private searchService: SearchService,
    private modalService: BsModalService,
    private translate: TranslateService
  ) {
    this.navServices.items.subscribe((menuItems) => {
      (this.items = menuItems)
    });

    HeaderComponent.SIDEBAR_OPENED.subscribe(resp => {
      this.removeFix();
    })
  }

  ngOnInit() {
  }

  searchToggle() {
    this.navServices.search = false;
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return (this.menuItems = []);
    this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.translateTitle();
    this.items.filter((menuItems) => {
      if (!menuItems?.title) return false;
      if (
        menuItems.title.toLowerCase().includes(term) &&
        menuItems.type === 'link'
      ) {
        items.push(menuItems);
      }
      if (!menuItems.children) return false;
      menuItems.children.filter((subItems) => {
        if (
          subItems.title.toLowerCase().includes(term) &&
          subItems.type === 'link'
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (subItems.title == "Fiskalizacija" && subItems.title.toLowerCase().includes(term)) {
          subItems.icon = 'printer';
          items.push(subItems);
        }

        if (!subItems.children) return false;
        subItems.children.filter((suSubItems) => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });

      this.menuItems = items;
      setTimeout(() => {
        this.checkSearchResultEmpty(items);
      }, 200);
    });
    this.searchBackend(term);
  }

  translateTitle() {
    for (var item of this.items) {
      item.title = this.translate.instant(item.title);
    }
  }

  searchBackend(term: any) {
    if (term.length < 3) return;
    let payload = {
      search: term,
      sortRim: "broj",
      sortFin: "",
    }

    this.searchService.search(payload)
      .subscribe(resp => {
        if (resp.body.result?.length > 0) {
          let items = resp.body.result.slice(0, 8);

          this.menuItems = this.menuItems.filter(function (obj) {
            return obj.type !== 'backend'
          });

          items.forEach(element => {
            this.menuItems.push(element);
          });
          this.checkSearchResultEmpty(items);
        }
      })
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true;
    else this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
  }

  removeFix() {
    this.searchResult = false;
    this.text = '';
  }

  openFiscalizationModal() {
    this.bsModalRef = this.modalService.show(FiscalizationModalComponent, { class: "modal-lg" });
  }
}
