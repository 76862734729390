import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting-modal',
  templateUrl: './waiting-modal.component.html',
  styleUrls: ['./waiting-modal.component.scss']
})
export class WaitingModalComponent implements OnInit {
  loading: boolean = true;
  modal;

  constructor() { }

  ngOnInit(): void {
  }

}
