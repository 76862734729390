import { Component, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FfTipoviFormiService } from 'src/app/shared/services/fiskusfield/ff-tipovi-formi.service';

@Component({
  selector: 'app-ff-select-tip-forme-modal',
  standalone: false,
  templateUrl: './ff-select-tip-forme-modal.component.html',
  styleUrl: './ff-select-tip-forme-modal.component.scss'
})
export class FfSelectTipFormeModalComponent {
  tipoviFormi;
  filteredTipoviFormi;
  tipForme = 1;
  search;
  @Input() updateInputValue = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private ffTipoviFormiService: FfTipoviFormiService) { }

  ngOnInit(): void {
    this.getTipoviFormi();
  }

  getTipoviFormi() {
    this.ffTipoviFormiService.getAllNeaktivneHeader().subscribe(resp => {
      this.tipoviFormi = resp;
      this.filteredTipoviFormi = resp;
    })
  }

  listSelected(tipForme) {
    this.updateInputValue.emit(tipForme);
    this.closeModal();
  }

  odaberi() {
    this.updateInputValue.emit(this.tipForme);
    this.closeModal();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  filterItems() {
    this.filteredTipoviFormi = this.tipoviFormi.filter((param) => {
      return (
        param.naziv.toString().toUpperCase().includes(this.search.toUpperCase())
      );
    });
  }

}
