 <!-- error-404 start-->
 <div class="error-wrapper">
    <div class="container"><img class="img-100" src="assets/images/other-images/sad.png" alt="">
      <div class="error-heading">
        <h2 class="headline font-danger">404</h2>
      </div>
      <div class="col-md-8 offset-md-2">
        <p class="sub-content">Stranica koju tražite nije dostupna</p>
      </div>
      <div><a class="btn btn-danger-gradien btn-lg text-uppercase" href="dashboard/default">Nazad na Početnu</a></div>
    </div>
  </div>
  <!-- error-404 end      -->
