<div class="card card-body mb-0">
  <div class="row border-bottom pb-3 mb-3">
    <div class="col-6">
      <form ngbAutofocus [formGroup]="importMoneyform" (ngSubmit)="onSubmitimportMoneyform()">
        <h5>{{ "PROPERTIES.UnosNovca" | translate }}</h5>
        <label class="font-weight-bold">{{ "PROPERTIES.Iznos" | translate }}</label>
        <div class="form-group">
          <div>
            <input formControlName="value" class="form-control text-right" type="text" inputmode="numeric" />
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                control: importMoneyform.controls.value
              }">
            </ng-container>
          </div>
        </div>

        <label class="font-weight-bold">{{ "PROPERTIES.NacinPlacanja" | translate }}</label>
        <div class="input-group custom-group">
          <ng-select class="col p-0" [items]="paymentMethods" bindLabel="nacccinPlaccanja" bindValue="nacccinPlaccanja"
            [selectOnTab]="true" formControlName="paymentMethod">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>{{ item.nacccinPlaccanja }}</div>
            </ng-template>
          </ng-select>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: importMoneyform.controls.paymentMethod
          }">
        </ng-container>

        <div class="d-flex align-items-center justify-content-end mt-4">
          <button type="submit" class="btn btn-primary d-flex align-items-center justify-content-center">
            {{ "PROPERTIES.Unesi" | translate }}
            <span class="spinner-border spinner-border-sm text-light ml-1" role="status" *ngIf="loading['moneyImport']">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </form>
    </div>

    <div class="col-6">
      <form [formGroup]="exportMoneyform" (ngSubmit)="onSubmitExportMoneyform()">
        <h5>{{ "PROPERTIES.PovratNovca" | translate }}</h5>
        <label class="font-weight-bold">Iznos</label>
        <div class="form-group">
          <div>
            <input formControlName="value" class="form-control text-right" type="text" inputmode="numeric" />
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                control: exportMoneyform.controls.value
              }">
            </ng-container>
          </div>
        </div>

        <label class="font-weight-bold">{{ "PROPERTIES.NacinPlacanja" | translate }}</label>
        <div class="input-group custom-group">
          <ng-select class="col p-0" [items]="paymentMethods" bindLabel="nacccinPlaccanja" bindValue="nacccinPlaccanja"
            [selectOnTab]="true" formControlName="paymentMethod">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>{{ item.nacccinPlaccanja }}</div>
            </ng-template>
          </ng-select>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: exportMoneyform.controls.paymentMethod
          }">
        </ng-container>

        <div class="d-flex align-items-center justify-content-end mt-4">
          <button type="submit" class="btn btn-primary d-flex align-items-center justify-content-center">
            {{ "PROPERTIES.IznesiNovac" | translate }}
            <span class="spinner-border spinner-border-sm text-light ml-1" role="status" *ngIf="loading['moneyExport']">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mb-3 mb-md-0 pb-3 pb-md-0">
      <form (ngSubmit)="onSubmitPeriodicReport()" [formGroup]="periodicReportForm">
        <h5> {{ "PROPERTIES.StampajPeriodicniIzvjestaj" | translate }}</h5>
        <div class="form-group">
          <label class="font-weight-bold"> {{ "PROPERTIES.Od" | translate }} </label>
          <div class="form-group">
            <div>
              <input formControlName="from" class="form-control digits" id="example-datetime-local-input"
                type="datetime-local" value="2019-01-19T18:45:00" />
            </div>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                control: periodicReportForm.controls.from
              }">
            </ng-container>
          </div>
        </div>
        <div class="form-group">
          <label class="font-weight-bold"> {{ "PROPERTIES.Do" | translate }} </label>
          <div class="form-group">
            <div>
              <input formControlName="to" class="form-control digits" id="example-datetime-local-input"
                type="datetime-local" />
            </div>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                control: periodicReportForm.controls.to
              }">
            </ng-container>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <button type="submit" class="btn btn-primary d-flex align-items-center justify-content-center">
            {{ "PROPERTIES.Stampaj" | translate }}
            <span class="spinner-border spinner-border-sm text-light ml-1" role="status" *ngIf="loading['periodic']">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </form>
    </div>

    <div class="col-12 col-md-6 mb-4">
      <h5>{{ "PROPERTIES.StampaDuplikata" | translate }}</h5>

      <form [formGroup]="documentCopyform" (ngSubmit)="onDocumentCopySubmit()">
        <label class="font-weight-bold">{{ "PROPERTIES.TipDokumenta" | translate }}</label>
        <div class="input-group custom-group">
          <ng-select class="col p-0" [selectOnTab]="true" [items]="requestTypes" bindLabel="label"
            formControlName="documentType">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: documentCopyform.controls.documentType
          }">
        </ng-container>

        <div class="form-group">
          <label class="font-weight-bold mt-4">{{ "PROPERTIES.BrojDokumenta" | translate }}</label>
          <div>
            <input formControlName="documentNumber" class="form-control text-right" type="text" inputmode="numeric" />
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                control: documentCopyform.controls.documentNumber
              }">
            </ng-container>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end mt-4">
          <button type="submit" class="btn btn-primary d-flex align-items-center justify-content-center">
            {{ "PROPERTIES.Stampaj" | translate }}
            <span class="spinner-border spinner-border-sm text-light ml-1" role="status" *ngIf="loading['copy']">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-12 d-lg-flex px-0">
    <div class="col-12 col-lg-6 mb-3 mb-lg-0 px-0 pr-lg-3 pl-lg-0">
      <span [disableTooltip]="isAuthorized('fiskalniuredjaj.dnevniizvjestaj')"
        [ngbTooltip]="'FORME.NemateDozvoluZaStampanjeDnevnogIzvjestaja' | translate" placement="top">
        <button [disabled]="!isAuthorized('fiskalniuredjaj.dnevniizvjestaj')" type="button" (click)="printDailyReport()"
          class="btn btn-primary btn-lg w-100 p-4 d-flex align-items-center justify-content-center">
          {{ "PROPERTIES.StampajDnevniIzvjestaj" | translate }}
          <span class="spinner-border spinner-border-sm text-light ml-1" role="status" *ngIf="loading['dailyReport']">
            <span class="sr-only">Loading...</span>
          </span>
        </button>
      </span>
    </div>
    <div class="col-12 col-lg-6 px-0 pl-lg-3 pr-lg-0">
      <span [disableTooltip]="isAuthorized('fiskalniuredjaj.presjekstanja')"
        [ngbTooltip]="'FORME.NemateDozvoluZaStampanjePresjekaStanja' | translate" placement="top">
        <button [disabled]="!isAuthorized('fiskalniuredjaj.presjekstanja')" type="button" (click)="printOverview()"
          class="btn btn-primary btn-lg w-100 p-4 d-flex align-items-center justify-content-center">
          {{ "PROPERTIES.StampajPresjekStanja" | translate }}
          <span class="spinner-border spinner-border-sm text-light ml-1" role="status" *ngIf="loading['overview']">
            <span class="sr-only">Loading...</span>
          </span>
        </button>
      </span>
    </div>
  </div>

  <div class="col-12 mt-4 d-flex justify-content-end px-0">
    <button type="button" class="btn btn-primary" (click)="prekiniRacun()">
      {{ "PROPERTIES.PrekiniRacunNSC" | translate }}
    </button>
  </div>

  <div class="col-12 mt-4 d-flex justify-content-end px-0">
    <button type="button" class="btn btn-light" (click)="closeModal()">
      {{ "PROPERTIES.Otkazi" | translate }}
    </button>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && control.touched">
    <small class="text-danger">{{ message | translate}}</small>
  </ng-container>
</ng-template>