import { KomitentiLookupDto } from "src/app/models/sifrarnici/KomitentiLookupDto";
import { BaseModel } from "../base.model";
import { FFObilascPodaciDto } from "./ffObilasciPodaciDto";
import { FFTipoviFormiDto } from "./FFTipoviFormiDto";

export class FFObilasciDto extends BaseModel {
    autoBr: number;
    tipForme: number | null;
    komitent: number;
    datumIVrijeme: string;
    longitude: string;
    latitude: string;
    checkIn: boolean | null;
    user: string;
    vrijednost: string;

    komitenti: KomitentiLookupDto;
    fFTipoviFormi: FFTipoviFormiDto;
    fFObilasciPodaci: FFObilascPodaciDto[];
}