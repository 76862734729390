<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">Copyright {{ today | date:'y'}} © Fiskus Bosna i Hercegovina
        - <a routerLink="/privacy-policy"> {{'DASHBOARD.Pravila o privatnosti' | translate}} </a>

        - <a routerLink="/faq"> {{'DASHBOARD.Česta pitanja' | translate}} </a>
      </p>
    </div>
  </div>
</div>