import { Injectable } from "@angular/core";
import { BaseApiService } from "./base-api.service";
import { Observable, catchError, throwError } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class LicenceService {
    constructor(
        private api: BaseApiService,
    ) { }

    licencePredIstek(params: any): Observable<any> {
        return this.api
            .post('licence/pred-istek', params)
            .pipe(catchError(this.handleError<[]>(`licence/pred-istek`)));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const errorMsg = `${operation} failed: ${error.message}`;
            return throwError(error);
        };
    }
}