<div class="card-header">
    <h4 class="mt-2">{{ "PROPERTIES.Fotografiraj" | translate }}</h4>
</div>

<div class="card card-body mb-0">
    <div class="row" id="report-export">
        <div class="col-12 mb-2 text-center"> <!-- [height]="500" [width]="500"  -->
            {{innerWidth}}
            <div class="form-group">
                <webcam [height]="420" [width]="innerWidth - 40" [trigger]="triggerObservable"
                    (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
                    [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="1"
                    (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                </webcam>
            </div>
        </div>

        <div class="col-12 text-right mt-2 mb-0 mr-2 ml-2">
            <button class="btn p-1 btn-primary" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">
                {{"PROPERTIES.Izmijeni kameru" | translate}}
            </button>

            <button class="btn p-1 btn-primary mr-2 ml-2" (click)="triggerSnapshot()" type="button">
                {{"PROPERTIES.Fotografiraj" | translate}}
            </button>

            <button class="btn p-1 btn-light mr-2 ml-2" (click)="closeModal()" type="button">
                {{"PROPERTIES.Zatvori" | translate}}
            </button>
        </div>

        <!--   <div class="snapshot mt-5" *ngIf="webcamImage">
            <h2>Vaša fotografija:</h2>
            <img [src]="webcamImage.imageAsDataUrl" />
        </div> -->

    </div>
</div>