import { AccountInfo } from '../../models/login/AuthenticateResultDto';
import { AccountService } from '../services/account.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuperAdmin } from '../../_constants/ApplicationRoles';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root',
})
export class RoleGuard  {
  constructor(
    private accountService: AccountService,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
     return this.checkPermission(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPermission(childRoute, state);
  }


  // checkRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const user = this.accountService.currentUser;

  //   const roles = user.userRoles;
  //   const isSuperAdmin = roles.find(r => r.roleName === SuperAdmin);
  //   if (isSuperAdmin || !route.data.roles) {
  //     return true;
  //   }
  //   if (roles.some((x) => route.data.roles.includes(x.roleName))) {
  //     return true;
  //   }
  //   this.router.navigate(['/']).then();
  //   this.toastr.error('Nemate dozvolu za pristup ovoj stranici');
  //   return false;
  // }

  checkPermission(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permissions = this.accountService.roleClaims;
    const user = this.accountService.currentUser;
    const roles = user.userRoles;
    const isSuperAdmin = roles.find(r => r.roleName === SuperAdmin);
    if (isSuperAdmin || !route.data.permissions) {
      return true;
    }

    if(route.data.permissions?.some((x: any) => permissions.includes(x))) {
      return true;
    }
    this.router.navigate(['/dashboard/default']).then();
    this.toastr.error(this.translate.instant("MESSAGE.Nemate dozvolu za pristup ovoj stranici"));
    return false;
  }
}
