import { BaseModel } from './base.model';
import { ArticleModel } from './article.model';
import { formatDate } from '@angular/common';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { KomitentiLookupDto } from 'src/app/models/sifrarnici/KomitentiLookupDto';
import { PrometArtikalaPodaciDto } from 'src/app/models/documents/rim/PrometArtikalaDto';
import {
  DOCUMENT_TYPES,
  RimVrsteDokumenata,
} from '../../helper/RimVrsteDokumenata';
import { FiWePlacanjaDto } from './FiWePlacanjaDto';

export class Invoice extends BaseModel {
  autoBr: number;
  komitent: string | number;
  podkupac: number | null;
  datum: string;
  datumIsporuke: string;
  rokPlaccanja: string;
  nacccinPlaccanja: number;
  valutaPlaccanja: number;
  brojDatumOtpremnice: string;
  radniNalog: string;
  rabat: number;
  valuta: number;
  kursValute: number;
  napomena: string;
  prometArtikalaPodaci: ArticleModel[];
  fakturniIznos: number;
  zavisniTrosskovi: number;
  brojSobe: number;
  poslovnaJedinica: number;
  dokument: number;
  broj: number;
  komitenti: KomitentiLookupDto;
  podkupci: KomitentiLookupDto;
  nKomitent: string | null;
  length: number;
  ukupanIznosKolicina: number | null;
  ukupanIznosIzlaz: number | null;
  ukupanIznosUlaz: number | null;
  nNacinPlacanja: string | null;
  nValuta: string | null;
  nPoslovnaJedinica: string | null;
  context?: any;
  ruc: number;
  ruc__: number;
  brojPoslanihEmailova: number;
  iznos_virman: number;
  iznos_gotovina: number;
  iznos_cccek: number;
  iznos_kartica: number;
  dostava: string;
  dms: number | null;
  projekt: number | null;
  fiskalni_broj_rs: string | null;

  statusPlaceno: StatusPlaceno;
  fiwePlacanja: FiWePlacanjaDto[];

  get initialValues() {
    return { prometArtikalaPodaci: [], ruc: 0, ruc__: 0 };
  }

  get childModels() {
    return {
      items: ArticleModel,
      prometArtikalaPodaci: ArticleModel,
    };
  }

  get unsafeApiAttributes(): string[] {
    return [...super.unsafeApiAttributes, 'context'];
  }

  getForm(disable = true): UntypedFormGroup {
    return this.fb.group({
      autoBr: this.autoBr ? this.autoBr : 0,
      komitent: this.komitent ? this.komitent : 0, disabled: disable,

      datum: this.datum
        ? this.datum
        : formatDate(new Date(Date.now()), 'yyyy-MM-dd', 'en'),
      datumIsporuke: this.datumIsporuke
        ? this.datumIsporuke
        : formatDate(new Date(Date.now()), 'yyyy-MM-dd', 'en'),
      rokPlaccanja: this.rokPlaccanja
        ? this.rokPlaccanja
        : formatDate(new Date(Date.now()), 'yyyy-MM-dd', 'en'),
      nacccinPlaccanja: [
        {
          value: this.nacccinPlaccanja ? this.nacccinPlaccanja : 0,
          disabled: disable,
        },
      ],
      valutaPlaccanja: this.valutaPlaccanja ? this.valutaPlaccanja : 0,
      brojDatumOtpremnice: this.brojDatumOtpremnice
        ? this.brojDatumOtpremnice
        : '',
      brojSobe: this.brojSobe ? this.brojSobe : 0,
      radniNalog: this.radniNalog ? this.radniNalog : '',
      rabat: this.rabat ? this.rabat : 0,
      valuta: [{ value: this.valuta ? this.valuta : 1, disabled: disable }],
      kursValute: this.kursValute ? this.kursValute : 1,
      napomena: this.napomena ? this.napomena : '',
      fakturniIznos: this.fakturniIznos ? this.fakturniIznos : 0.0,
      poslovnaJedinica: this.poslovnaJedinica ? this.poslovnaJedinica : 1,
      dokument: this.dokument ? this.dokument : 0,
      broj: this.broj ? this.broj : 2,
      pj: this.poslovnaJedinica ? this.poslovnaJedinica : 1,
      ruc: this.isOutputVisible ? 0 : null,
      ruc__: this.isOutputVisible ? 0 : null,
      podkupac: this.podkupac ? this.podkupac : 0,
      iznos_virman: this.iznos_virman ? this.iznos_virman : 0,
      iznos_gotovina: this.iznos_gotovina ? this.iznos_gotovina : 0,
      iznos_cccek: this.iznos_cccek ? this.iznos_cccek : 0,
      iznos_kartica: this.iznos_kartica ? this.iznos_kartica : 0,
      dostava: this.dostava ? this.dostava : '',
      dms: this.dms ? this.dms : null,
      projekt: this.projekt ? this.projekt : null,
      fiskalni_broj_rs: this.fiskalni_broj_rs ? this.fiskalni_broj_rs : null,
    });
  }

  createApiObject(isCreate?: boolean, model?: this) {
    const object = JSON.parse(JSON.stringify(model || this));
    if (isCreate) {
      delete object.autoBr;
    }
    delete object.fb;
    return this.toApi(object);
  }

  get quantityMask(): string {
    if (this.dokument === RimVrsteDokumenata.STORNO_FAKTURA_AVANS) {
      return '-0*.00000';
    }
    return '0*.00000';
  }

  get outputMask(): string {
    if (this.dokument == RimVrsteDokumenata.POVRAT_ROBE_OD_KUPACA) {
      return '-0*.00000';
    }
    return '0*.00000';
  }

  get inputMask(): string {
    if (this.dokument == RimVrsteDokumenata.POVRAT_ROBE_DOBAVLJACU) {
      return '-0*.00000';
    }
    return '0*.00000';
  }

  get quantityLabel(): string {
    if (this.isQuantityVisible) {
      return 'Količina';
    }
    if (this.isInputVisible) {
      return 'Ulaz';
    }
    return 'Izlaz';
  }

  get totalControlName(): string {
    if (this.isQuantityVisible) {
      return 'ukupanIznosKolicina';
    }
    if (this.isInputVisible) {
      return 'fakturniIznos';
    }
    if (this.isOutputVisible) {
      return 'ukupanIznosIzlaz';
    }
    return 'fakturniIznos';
  }

  get isQuantityVisible(): boolean {
    return (
      this.dokument === RimVrsteDokumenata.PROFAKTURA ||
      this.dokument === RimVrsteDokumenata.UGOVOR ||
      this.dokument === RimVrsteDokumenata.FAKTURA_AVANS ||
      this.dokument === RimVrsteDokumenata.STORNO_FAKTURA_AVANS
    );
  }

  get isInputVisible(): boolean {
    return (
      this.dokument === RimVrsteDokumenata.POVRAT_ROBE_DOBAVLJACU ||
      this.dokument == RimVrsteDokumenata.ULAZNA_KALKULACIJA
    );
  }

  get isOutputVisible(): boolean {
    return !this.isQuantityVisible && !this.isInputVisible;
  }

  get quantityControlName(): string {
    if (this.isQuantityVisible) {
      return 'kolicccina';
    }
    if (this.isInputVisible) {
      return 'ulaz';
    }
    return 'izlaz';
  }

  get documentTypeName(): string {
    let documentType = this.dokument;
    if (!documentType) {
      return DOCUMENT_TYPES.find(
        (type) => type.value == RimVrsteDokumenata.FAKTURA
      )?.name;
    } else {
      return DOCUMENT_TYPES.find((type) => type.value == documentType)?.name;
    }
  }

  get noVatTotal(): number {
    let sum = 0;
    this.prometArtikalaPodaci.forEach((item) => {
      sum += item.cijenaBezPdv * item[this.quantityControlName];
    });
    return sum;
  }

  get priceWithDiscount(): number {
    let sum = 0;
    this.prometArtikalaPodaci.forEach((item) => {
      sum += item.cijenaSaRabatom * item[this.quantityControlName];
    });
    return sum;
  }
}

export class PrometArtikalaDto extends BaseModel {
  autoBr: number;
  poslovnaJedinica: number;
  dokument: number;
  broj: number;
  komitent: number;
  datum: string | null;
  valutaPlaccanja: number | null;
  rokPlaccanja: string | null;
  datumIsporuke: string | null;
  nacccinPlaccanja: number | null;
  veza: number | null;
  cjenovnik: number | null;
  brojDatumOtpremnice: string;
  radniNalog: number | null;
  rabat: number | null;
  valuta: number | null;
  kursValute: number | 1;
  devizniFakturniIznos: number | null;
  fakturniIznos: number | null;
  zavisniTrosskovi: number | null;
  pdvSeMozzeOdbiti: number | null;
  vrstaKnjige: number | null;
  nabavniIznos: number | null;
  prodajniIznos: number | null;
  komercijalist: number | null;
  napomena: string;
  datumIzmjene: string | null;
  vrijeme: string | null;
  operator: number | null;
  status: number;
  created: string | null;
  createdBy: number | null;
  modified: string | null;
  modifiedBy: number | null;
  fiskalniBroj: number | null;
  reklamiraniFiskalniBroj: number | null;
  fiskalniDatum: string | null;
  fiskalni_broj_rs: string | null;
  fiskalnoVrijeme: string | null;
  rezervacijaNaloga: number | null;
  rezervacijaUgovora: number | null;
  jci: string;
  pdvZavisnihTroskova: number | null;
  projekt: number | null;
  profitniCentar: number | null;
  izvrssilac: number | null;
  podkupac: number | null;
  uplacceniIznos: number | null;
  ostatakDuga: number | null;
  komitentPoslovnica: number | null;
  datumPrimitka: string | null;
  neaktivan: boolean | null;
  datumDeaktivacije: string | null;
  brojRata: number | null;
  lobist: number | null;
  jciDatum: string | null;
  komisionaProvizija: number | null;
  ibfmUreddaja: string;
  nPoslovnaJedinica: string;
  nKomitent: string;
  nNacinPlacanja: string;
  nValuta: string;
  ukupanIznosIzlaz: number;
  ukupanIznosKolicina: number;
  ukupanIznosUlaz: number;
  prometArtikalaPodaci: PrometArtikalaPodaciDto[];
  komitenti: KomitentiLookupDto;
  podkupci: KomitentiLookupDto;

  iznos_virman: 0;
  iznos_gotovina: 0;
  iznos_cccek: 0;
  iznos_kartica: 0;
  dostava: string | null;
  dms: number | null;
  //brojPoslanihEmailova: number;

  statusPlaceno: StatusPlaceno;
  fiwePlacanja: FiWePlacanjaDto[];

  get dateFormatted() {
    return new Date(this.datum).toLocaleDateString();
  }

  get totalValue(): number {
    if (this.kursValute == null) this.kursValute = 0;

    if (this.isQuantityVisible) {
      return this.ukupanIznosKolicina;
    }
    if (this.isInputVisible) {
      return this.fakturniIznos;
    }
    if (this.isOutputVisible) {
      if (this.kursValute != 0) {
        return this.ukupanIznosIzlaz / this.kursValute;
      }
      return this.ukupanIznosIzlaz;
    }
    return this.fakturniIznos;
  }

  get totalControlName(): string {
    if (this.isQuantityVisible) {
      return 'ukupanIznosKolicina';
    }
    if (this.isInputVisible) {
      return 'fakturniIznos';
    }
    if (this.isOutputVisible) {
      return 'ukupanIznosIzlaz';
    }
    return 'fakturniIznos';
  }

  get isQuantityVisible(): boolean {
    return (
      this.dokument === RimVrsteDokumenata.PROFAKTURA ||
      this.dokument === RimVrsteDokumenata.UGOVOR ||
      this.dokument === RimVrsteDokumenata.FAKTURA_AVANS ||
      this.dokument === RimVrsteDokumenata.STORNO_FAKTURA_AVANS
    );
  }

  get isInputVisible(): boolean {
    return (
      this.dokument === RimVrsteDokumenata.POVRAT_ROBE_DOBAVLJACU ||
      this.dokument == RimVrsteDokumenata.ULAZNA_KALKULACIJA
    );
  }

  get canExecuteReclamation() {
    if (
      this.dokument == RimVrsteDokumenata.POVRAT_ROBE_DOBAVLJACU ||
      this.dokument == RimVrsteDokumenata.PROFAKTURA ||
      this.dokument == RimVrsteDokumenata.POVRAT_ROBE_OD_KUPACA ||
      this.dokument == RimVrsteDokumenata.OTPREMNICA ||
      this.dokument == RimVrsteDokumenata.FAKTURA_AVANS ||
      this.dokument == RimVrsteDokumenata.STORNO_FAKTURA_AVANS ||
      this.dokument == RimVrsteDokumenata.ULAZNA_KALKULACIJA
    ) {
      return false;
    } else {
      return true;
    }
  }

  get isOutputVisible(): boolean {
    return !this.isQuantityVisible && !this.isInputVisible;
  }

  get komitentKupac(): string {
    var komitentKupac = this.nKomitent;

    if (this.podkupci != null) {
      komitentKupac =
        komitentKupac + ' (Kupac: ' + this.podkupci?.komitent + ' )';
    }
    return komitentKupac;
  }
}

export enum StatusPlaceno {
  Nezavrseno = 0,
  Djelomicno = 1,
  Zavrseno = 2,
  Preplaceno = 3,
}

export enum FiWePlacanjeVrsta {
  Uplata = 1,
  Isplata = 2,
}
