export function replaceAllBH(str: string) {
  var tt: string = '';
  tt = str.replace('__', ' %');
  tt = tt.replaceAll('_', '');
  tt = tt.replaceAll('ccc', 'č');
  tt = tt.replaceAll('cc', 'ć');
  tt = tt.replaceAll('zz', 'ž');
  tt = tt.replaceAll('dd', 'đ');
  tt = tt.replaceAll('ss', 'š');
  tt = tt.replaceAll('CCC', 'Č');
  tt = tt.replaceAll('CC', 'Ć');
  tt = tt.replaceAll('ZZ', 'Ž');
  tt = tt.replaceAll('DD', 'Ð');
  tt = tt.replaceAll('SS', 'Š');

  var result = tt.replace(/([A-Z])/g, ' $1');
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  tt = finalResult;

  tt = tt.trimEnd().trimStart();

  return tt;
}

export function PrepareBH(str: string) {
  var tt: string = '';
  tt = str.replaceAll(' ', '');
  tt = tt.replaceAll('č', 'ccc');
  tt = tt.replaceAll('ć', 'cc');
  tt = tt.replaceAll('ž', 'zz');
  tt = tt.replaceAll('đ', 'dd');
  tt = tt.replaceAll('š', 'ss');
  tt = tt.replaceAll('Č', 'C');
  tt = tt.replaceAll('Ć', 'C');
  tt = tt.replaceAll('Ž', 'Z');
  tt = tt.replaceAll('Ð', 'D');
  tt = tt.replaceAll('Š', 'S');

  tt = tt.replace('%', '__'); // npr zamijeni "PDV %" sa "PDV__"

  return tt;
}
