import { PaymentMethodModel } from '../models/paymentInfo.model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { BaseApiService } from './base-api.service';
import { CurrencyModel } from '../models/paymentInfo.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentInfoService {
  constructor(private api: BaseApiService) {}

  getAllCurrencies(params: any = {}): Observable<CurrencyModel[]> {
    return this.api.get('configurations/valute-placanja', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (currency) => new CurrencyModel(currency)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<CurrencyModel[]>(`getAllCurrencies`))
    );
  }

  getAllPaymentMethods(params: any = {}): Observable<PaymentMethodModel[]> {
    return this.api.get('configurations/nacine-placanja', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (paymentMethod) => new PaymentMethodModel(paymentMethod)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<PaymentMethodModel[]>(`getAllCurrencies`))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
