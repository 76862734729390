import { KomitentiLookupDto } from "src/app/models/sifrarnici/KomitentiLookupDto";
import { BaseModel } from "../base.model";

export class FFTasksDto extends BaseModel {
    autoBr: number;
    komitent: number;
    zaOperatera: number;
    datumKreiranja: string | null;
    opis: string;
    zavrseno: boolean | null;
    zavrsenoDatum: string | null;
    procccitano: boolean | null;
    user: string;
    komitenti: KomitentiLookupDto;

}