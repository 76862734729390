import { BaseModel } from './base.model';

export class SubGroupModel extends BaseModel {
    podgrupa: number | null;
    grupa: number | null;
    opis: string | null;
    status: number | null;
    created: string | null;
    createdBy: number | null;
    modified: string | null;
    modifiedBy: number | null;
    nGrupa: string |null;
}

