<div class="card card-body mb-0">
    <div class="mb-4 h6">{{text}}</div>
    <div class="d-flex justify-content-end">
        <button class="btn btn-light mr-3" (click)="closeModal(1)">
            Ne
        </button>
        <button class="btn btn-primary mr-3" (click)="closeModal(2)">
            U glavnu knjigu
        </button>
        <button class="btn btn-primary" (click)="closeModal(3)">
            Da
        </button>
    </div>
</div>