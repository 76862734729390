export class RimVrsteDokumenata {
  public static POCETNO_STANJE: number = 1;
  public static ULAZNA_KALKULACIJA: number = 2;
  public static NIVELACIJE_CIJENA: number = 3;
  public static POVRAT_ROBE_DOBAVLJACU: number = 4;
  public static PREDISPOZICIJA_ULAZ: number = 5;
  public static ULAZ_PROIZVODA: number = 6;
  public static VISAK: number = 8;
  public static FAKTURA: number = 11;
  public static OTPREMNICA: number = 12;
  public static PREDISPOZICIJA_IZLAZ: number = 13;
  public static POVRAT_ROBE_OD_KUPACA: number = 14;
  public static IZDATNICA_MATERIJALA: number = 15;
  public static MANJAK: number = 16;
  public static FAKTURA_AVANS: number = 21;
  public static INTERNA_FAKTURA: number = 22;
  public static IZVOZNA_FAKTURA: number = 23;
  public static BLAGAJNICKI_RACUN: number = 24;
  public static FAKTURA_BEZ_RAZDUZENJA: number = 27;
  public static STORNO_FAKTURA_AVANS: number = 28;
  public static UVOZNA_KALKULACIJA: number = 32;
  public static PROFAKTURA: number = 101;
  public static UGOVOR: number = 107;
  public static AMORTIZACIJE: number = 109;
  public static IZLAZ_OTPIS_SS: number = 19;
}

export const DOCUMENT_TYPES = [
  {
    name: 'Faktura',
    value: RimVrsteDokumenata.FAKTURA,
    printCommand: 'stampatifiskalniracun',
    link: '/invoice/'
  },
  {
    name: 'Profaktura',
    value: RimVrsteDokumenata.PROFAKTURA,
    link: '/invoice/'
  },
  {
    name: 'Otpremnica',
    value: RimVrsteDokumenata.OTPREMNICA,
    link: '/invoice/'
  },
  /*  {
        name: 'Ugovor',
        value: RimVrsteDokumenata.UGOVOR
    }, */
  {
    name: 'Povrat robe dobavljaču',
    value: RimVrsteDokumenata.POVRAT_ROBE_DOBAVLJACU,
    link: '/invoice/calculation-list/'
  },
  {
    name: 'Povrat robe od kupaca',
    printCommand: 'stampatireklamiraniracun',
    value: RimVrsteDokumenata.POVRAT_ROBE_OD_KUPACA,
    link: '/invoice/'
  },
  {
    name: 'Faktura avans',
    value: RimVrsteDokumenata.FAKTURA_AVANS,
    link: '/invoice/'
  },
  {
    name: 'Storno faktura avans',
    value: RimVrsteDokumenata.STORNO_FAKTURA_AVANS,
    link: '/invoice/'
  },
  {
    name: 'Blagajnički račun',
    value: RimVrsteDokumenata.BLAGAJNICKI_RACUN,
    printCommand: 'stampatifiskalniracun',
    link: '/invoice/'
  },

  {
    name: 'Izvozne fakture',
    value: RimVrsteDokumenata.IZVOZNA_FAKTURA,
    printCommand: 'stampatifiskalniracun',
    link: '/invoice/'
  },
 
];
