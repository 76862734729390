import { BaseModel } from "./base.model";

export class firmeDto extends BaseModel {
    id: number;
    naziv: string;
    identifikacioni_broj: string;
    bazeGodine: string;
    firmeInformacije: firmeInformacijeDto;
    firmePodaci: FirmaPodaciDto[];
    firmeUsers: FirmeUseriDto[];
}

export class firmeInformacijeDto extends BaseModel {
    firmeId: number;
    adresa: string;
    telefon: string;
    pDVBroj: string;
    porezniBroj: string;
    sudskiBroj: string;
    ziroRacun: string;
    operator: number;
    sifraDjelatnosti: string;
    nazivDjelatnosti: string;
    prezimeImePoduzetnika: string;
    jMBPoduzetnika: string;
    adresaPoduzetnika: string;
    opcinaPoduzetnika: string;
    email: string;
}

export class FirmaPodaciDto {
    godina: number;
    baza: string;
}

export class FirmeUseriDto {
    firmaId: number;
    userId: number;
    firma: string;
    user: string;
    identifikacioni_broj: string;
    allowed: boolean;
}
 