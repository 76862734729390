import { UntypedFormGroup, Validators } from "@angular/forms";
import { BaseModel } from "../base.model";
import { FFPoljaLookupDto } from "./ffPoljaLookupDto";
import { TranslateService } from "@ngx-translate/core";

export class FFTipoviFormiPodaciModel extends BaseModel {
    autoBr_: number;
    autoBr: number;
    redniBroj: number | null;
    polje: number;
    nazivPolja: string;
    obaveznoPolje: boolean | null;
    possaljiNaMail: boolean | null;
    preview: boolean | null;
    polja: FFPoljaLookupDto;

    constructor(attributes?: any,) {
        super(attributes);
    }

    static setFormValue(form: UntypedFormGroup, item: FFTipoviFormiPodaciModel): void {
        for (let control in form.controls) {
            form.controls[control].setValue(item[control]);
        }
    }

    getForm(): UntypedFormGroup {
        return this.fb.group({
            autoBr_: [this.autoBr_ ? this.autoBr_ : null],
            autoBr: [this.autoBr ? this.autoBr : null],
            redniBroj: [this.redniBroj ? this.redniBroj : 1, Validators.required],
            polje: [this.polje ? this.polje : null, Validators.required],
            nazivPolja: [this.nazivPolja ? this.nazivPolja : '', Validators.required],
            obaveznoPolje: [this.obaveznoPolje ? this.obaveznoPolje : null,],
            possaljiNaMail: [this.possaljiNaMail ? this.possaljiNaMail : null,],
            preview: [this.preview ? this.preview : null,],
        });
    }
 
}