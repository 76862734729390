<form class="form-inline search-full m-0 search-bar" [class.open]="navServices.search">
  <div class="form-group w-100 m-1 m-lg-0">
    <input class="form-control-plaintext text-dark search-input" [(ngModel)]="text"
      [ngModelOptions]="{standalone: true}" (keyup)="searchTerm(text)" type="text" autocomplete="off"
      placeholder='{{"DASHBOARD.Pretraga" | translate}}'>
    <app-feather-icons *ngIf="!isXHidden" [icon]="'x'" class="close-search" (click)="searchToggle()">
    </app-feather-icons>
    <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
      <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:10">
        <ng-container *ngIf="menuItem?.path">
          <div class="ProfileCard-avatar">
            <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
          </div>
          <div class="ProfileCard-details">
            <div class="ProfileCard-realName">
              <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title |
                translate}}</a>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="menuItem?.title === 'Fiskalizacija'">
          <div class="ProfileCard-avatar">
            <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
          </div>
          <div class="ProfileCard-details">
            <div class="ProfileCard-realName">
              <a [routerLink]="menuItem?.path" class="realname"
                (click)="removeFix(); openFiscalizationModal()">{{menuItem?.title}}</a>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
   <!--  <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">
          {{"MESSAGE.Nije pronađen rezultat" | translate}}
        </div>
      </div>
    </div> -->
  </div>
</form>