import { FirmeDto } from "src/app/models/firms/firmeDto";
import { UserDto } from "src/app/models/users/userDto";
import { BaseModel } from "./base.model";

export class ClipboardDto extends BaseModel {
    id: number;
    date: string;
    clip: string;
    tip: number;
    firme: FirmeDto;
    users: UserDto;
}