import { CitiesModel } from '../models/cities.model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { BaseApiService } from './base-api.service';
import { PagedResponse } from '../models/paged-response.model';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(private api: BaseApiService) {}

  getAllCities(params: any = {}): Observable<CitiesModel[]> {
    return this.api.get('gradovi/all', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((city) => new CitiesModel(city));
        }
        return undefined;
      }),
      catchError(this.handleError<CitiesModel[]>(`gradovi/all`))
    );
  }

  getPaged(params: any): Observable<PagedResponse<CitiesModel>> {
    return this.api.post('gradovi', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<CitiesModel>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new CitiesModel(i));
        return pagedModel;
      })
    );
  }

  getGrupaById(params: any): Observable<any> {
    return this.api
      .post('gradovi/by-id', params)
      .pipe(catchError(this.handleError<[]>(`gradovi/by-id`)));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('gradovi/update', params)
      .pipe(catchError(this.handleError<[]>('gradovi/update')));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('gradovi/create', params)
      .pipe(catchError(this.handleError<[]>('gradovi/create')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('gradovi/delete', params)
      .pipe(catchError(this.handleError<[]>('gradovi/delete')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
