import { BaseModel } from "../base.model";

export class qFIN_AUTO_KNJIZENJE_GET_NEUKNJIZENO extends BaseModel {
    autoBr: number;
    poslovna_jedinica: number;
    dokument: number;
    broj: number;

    nPoslovnaJedinica: string;
    nDokument: string;
}