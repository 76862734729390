import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService, Menu } from '../../../../services/nav.service';
import { Location } from '@angular/common'
import { getAcceptLanguage } from 'src/app/shared/helpers/getAcceptLanguage';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [
    {
      language: 'BiH',
      code: 'bs',
      type: 'BS',
      icon: 'ba'
    },
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'German',
      code: 'de',
      type: 'DE',
      icon: 'de'
    },
    {
      language: 'Español',
      code: 'es',
      type: 'ES',
      icon: 'es'
    },

    {
      language: 'Italy',
      code: 'it',
      type: 'IT',
      icon: 'it'
    },

    {
      language: 'Turkish',
      code: 'tr',
      type: 'TR',
      icon: 'tr'
    },

    {
      language: 'Arabic',
      code: 'ar',
      type: 'Ar',
      icon: 'ae'
    },
    /* {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    },
    {
      language: 'Português',
      code: 'pt',
      type: 'BR',
      icon: 'pt'
    } */]

  public selectedLanguage: any = {
    language: 'BiH',
    code: 'bs',
    type: 'ba',
    icon: 'ba'
  }

  constructor(private translate: TranslateService,
    public navServices: NavService,
  ) { }

  ngOnInit() {
    this.translate.addLangs(['bs', 'en', 'hr', 'de', 'es', 'it']);

    var lang = getAcceptLanguage.getLocalStorageLanguage();
    this.selectedLanguage = this.languages.find(x => x.code == lang);
  }

  changeLanguage(lang) {
    this.translate.use(lang.code)
    this.translate.setDefaultLang(lang.code);
    this.selectedLanguage = lang;
    localStorage.setItem('language-code', JSON.stringify(lang.code));
    location.reload();
  }

}
