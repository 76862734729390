import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ClipboardTypesArray } from 'src/app/_constants/clipboardTypes';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { YesnoModalComponent } from '../yesno-modal/yesno-modal.component';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-clipboard-list-modal',
  templateUrl: './clipboard-list-modal.component.html',
  styleUrl: './clipboard-list-modal.component.scss'
})
export class ClipboardListModalComponent {
  loading: boolean = true;
  clips;
  params = {
    page: 1,
    pageSize: 30,
    pageCount: 1,
    search: '',
    sort: '-date',
  };
  clipboardTypes = new ClipboardTypesArray().array;
  isPasting: boolean = false;

  getTip(value) {
    return this.clipboardTypes.find(x => x.value == value).label;
  }

  getValueFromClip(clip) {
    //var val = JSON.parse(clip);
    clip = clip.replace("{", "");
    clip = clip.replace("}", "");
    clip = clip.replace("[", "");
    clip = clip.replace("]", "");
    /* if (val.length > 0) {
      return JSON.stringify(val[0]);
    } */
    if (clip.length > 100) return clip.substring(0, 100);
    return clip;
  }

  constructor(
    public bsModalRef: BsModalRef,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
    this.getClips();
  }

  getClips() {
    this.clipboardService.getPaged(this.params).subscribe(resp => {
      this.loading = false;
      this.clips = resp.items;
    })
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  delete(item) {
    var payload = {
      id: item.id
    };

    var htmlDanger = "";
    Swal.fire({
      title: this.translate.instant("PROPERTIES.Međuspremnik"),
      footer: htmlDanger,
      html: this.translate.instant("MESSAGE.Želite li obrisati stavku iz međuspremnika") + ' ' + '?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant("DA"),
      cancelButtonText: this.translate.instant("NE"),
    }).then(result => {
      if (result.isConfirmed) {
        this.clipboardService.delete(payload).subscribe(resp => {
          this.toastr.success(this.translate.instant("MESSAGE.Stavka uspješno obrisana"));
          this.getClips();
        })
      }
    });
  }

  pasteClipboard(item) {
    var payload = {
      tip: item.tip,
      poslovnaJedinica: this.accountService.context.businessUnit
    }

    this.isPasting = true;
    var htmlDanger = "";
    Swal.fire({
      title: this.translate.instant("PROPERTIES.Međuspremnik"),
      footer: htmlDanger,
      html: this.translate.instant("MESSAGE.Želite li kopirati stavku iz međuspremnika") + ' ' + '?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant("DA"),
      cancelButtonText: this.translate.instant("NE"),
    }).then(result => {
      if (result.isConfirmed) {
        this.clipboardService.paste(payload).subscribe(resp => {
          this.isPasting = false;
          this.toastr.success(this.translate.instant("MESSAGE.Uspješno zalijepljeno"));
          this.getClips();
        })
      }
      else {
        this.isPasting = false;
      }
    });













  }

}
