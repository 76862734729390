import { Injectable, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IStepOption, TourService } from 'ngx-ui-tour-ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AnchorStepsConstants } from 'src/app/_constants/anchorStepsConstants';
import { Menu, NavService } from './nav.service';
import { tourResponseType } from 'src/app/helper/tourResponseType';

const ONBOARD_ARTIKLI_KEY = 'onBoardArtikli';

@Injectable({
  providedIn: 'root'
})
export class NgxTourService implements OnInit {
  private readonly tourService = inject(TourService);
  public menuItems: Menu[];

  constructor(
    private translate: TranslateService,
    private stepsAnchor: AnchorStepsConstants,
    public navServices: NavService,
  ) {

    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
    });
  }

  ngOnInit(): void {
  }

  //Start onboard Artikli
  public onBoardArtikli: boolean = false;
  onBoardArtikliSubject = new BehaviorSubject<boolean>(this.onBoardArtikli);

  //Start onboard Faktura
  public onBoardFaktura: tourResponseType = false;
  onBoardFakturaSubject = new BehaviorSubject<tourResponseType>(this.onBoardFaktura);

  //Start onboard Kalkulacija
  public onBoardKalkulacija: tourResponseType = false;
  onBoardKalkulacijaSubject = new BehaviorSubject<tourResponseType>(this.onBoardKalkulacija);

  public onBoardPripremni: tourResponseType = false;
  onBoardPripremniSubject = new BehaviorSubject<tourResponseType>(this.onBoardPripremni);


  public setOnBoardArtikli(onBoardArtikli: boolean): void {
    this.onBoardArtikli = onBoardArtikli;
    this.onBoardArtikliSubject.next(onBoardArtikli);
    localStorage.setItem(ONBOARD_ARTIKLI_KEY, String(onBoardArtikli));
  }

  public setOnBoardFaktura(onBoardFaktura: tourResponseType): void {
    this.onBoardFaktura = onBoardFaktura;
    this.onBoardFakturaSubject.next(onBoardFaktura);
  }

  public setOnBoardKalkulacija(onBoardKalkulacija: tourResponseType): void {
    this.onBoardKalkulacija = onBoardKalkulacija;
    this.onBoardKalkulacijaSubject.next(onBoardKalkulacija);
  }

  public setOnBoardPripremni(onBoardPripremni: tourResponseType): void {
    this.onBoardPripremni = onBoardPripremni;
    this.onBoardPripremniSubject.next(onBoardPripremni);
  }

  initTourServie(steps) {
    this.tourService.initialize(steps, {
      enableBackdrop: true,
      backdropConfig: {
        offset: 15,
      },
      prevBtnTitle: this.translate.instant("PROPERTIES.Nazad"),
      nextBtnTitle: this.translate.instant("PROPERTIES.Naprijed"),
      endBtnTitle: this.translate.instant("PROPERTIES.Završi"),
      placement: 'bottom',
      disablePageScrolling: true,
      delayBeforeStepShow: 100,
    });
  }

  startTourServiceArtikli(stepsArtikli) {
    this.closeAllMenus();
    this.navServices.collapseSidebar = false;
    this.openMenuItem("sifrarnici");
    this.initTourServie(stepsArtikli);
    setTimeout(() => {
      this.start();
    }, 100);
  }

  startTourServiceFakture(stepsFaktura) {
    this.closeAllMenus();
    this.navServices.collapseSidebar = false;
    this.openMenuItem("fakture");
    this.initTourServie(stepsFaktura);
    setTimeout(() => {
      this.start();
    }, 100);
  }

  startTourServiceKalkulacija(stepsKalkulacija) {
    this.closeAllMenus();
    this.navServices.collapseSidebar = false;
    this.openMenuItem("robnomaterijalno");
    this.initTourServie(stepsKalkulacija);
    setTimeout(() => {
      this.start();
    }, 100);
  }

  startTourServiceKUF(steps) {
    this.closeAllMenus();
    this.navServices.collapseSidebar = false;
    this.openMenuItem("pdv");
    this.initTourServie(steps);
    setTimeout(() => {
      this.start();
    }, 100);
  }

  startTourServiceUnosNaloga(steps) {
    this.closeAllMenus();
    this.navServices.collapseSidebar = false;
    this.openMenuItem("financije");
    this.initTourServie(steps);
    setTimeout(() => {
      this.start();
    }, 100);
  }

  startTourService(steps) {
    this.initTourServie(steps);
    this.start()
  }

  start() {
    this.tourService.start();

    this.tourService.end$.subscribe(resp => {
      // console.log("end tour")
    })
    this.tourService.stepShow$.subscribe(step => {
      if (step) {
        if (step.step.anchorId == "polje-artikal-faktura") {
          this.setOnBoardFaktura(tourResponseType.POPUNI_ARTIKAL);
        }
        else if (step.step.anchorId == "dodaj-artikal-faktura") {
          this.setOnBoardFaktura(tourResponseType.SAVE_ITEM);
        }
        else if (step.step.anchorId == "polje-artikal-kalkulacija") {
          this.setOnBoardKalkulacija(tourResponseType.POPUNI_ARTIKAL);
        }
        else if (step.step.anchorId == "dodaj-artikal-kalkulacija") {
          this.setOnBoardKalkulacija(tourResponseType.SAVE_ITEM);
        }
        else if (step.step.anchorId == "dodaj-pripremni") {
          this.setOnBoardPripremni(tourResponseType.SAVE_ITEM);
        }
      }
    })
  }

  openMenuItem(badgeType) {
    for (var item of this.menuItems) {
      if (item.badgeType == badgeType) {
        item.active = true;
      }
    }
  }

  closeAllMenus() {
    for (var item of this.menuItems) {
      item.active = false;
    }
  }

}
