export enum ffTipoviPoljaEnum {
    Text = 1,
    Broj = 2,
    Boolean = 3,
    DropDown = 4,
    Datum = 5,
    Potpis = 6,
    Fotografija = 19, //ovo je samo greska na test firmi - koristi se samo ovdje
    Tag = 8,
    Fotografija2 = 7,
    Artikli = 9,
}