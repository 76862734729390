<ul *ngIf="context" class="d-flex flex-column flex-lg-row">
  <li *ngIf="!isDesktop">
    <app-feather-icons [icon]="'archive'"></app-feather-icons>
    <h6 class="f-18 mb-0">{{ "PROPERTIES.Kontekst" | translate}}</h6>
  </li>
  <li class="context-dropdown mr-1 ml-1" *ngIf="organizationsToDisplay.length">
    <div>
      <ng-select [disabled]="isContextDisabled || (organizationsToDisplay.length < 2)" (change)="organizationChange()"
        [clearable]="false" placeholder="Firma" [(ngModel)]="context.organizationId" [searchable]="isSearchable()">
        <ng-option *ngFor="let firm of organizationsToDisplay" [value]="firm.id">
          <ng-container *ngIf="isDesktop"> {{ firm.nFirma }}</ng-container>
          <ng-container *ngIf="!isDesktop"> {{ (firm.nFirma.length > 30)? (firm.nFirma | slice:0:30)+'...':(firm.nFirma)
            }}</ng-container>
        </ng-option>
      </ng-select>
    </div>
  </li>

  <li class="context-dropdown mr-1 ml-1 business-unit" *ngIf="businessUnits.length">
    <div>
      <ng-select [disabled]="isContextDisabled || (businessUnits.length < 2)" (change)="businessUnitChange()"
        [clearable]="false" placeholder="Poslovna jedinica" [(ngModel)]="context.businessUnit"
        [searchable]="isSearchable()">
        <ng-option *ngFor="let unit of businessUnits" [value]="unit.sifra">
          <ng-container *ngIf="isDesktop"> {{ unit.naziv }}</ng-container>
          <ng-container *ngIf="!isDesktop"> {{ (unit?.naziv?.length > 20)? (unit?.naziv | slice:0:20)+'...':
            (unit?.naziv)
            }}</ng-container>
        </ng-option>
      </ng-select>
    </div>
  </li>

  <li class="context-dropdown mr-1 ml-1 business-years" *ngIf="yearsToDisplay.length">
    <div>
      <ng-select [disabled]="isContextDisabled || isContextYearDisabled || (yearsToDisplay.length < 2)"
        (change)="yearChange()" [clearable]="false" placeholder="Godina" [(ngModel)]="context.year"
        [searchable]="isSearchable()">
        <ng-option *ngFor="let year of yearsToDisplay" [value]="year.godina">
          {{ year.godina }}
        </ng-option>
      </ng-select>
    </div>
  </li>


</ul>