import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IStepOption } from 'ngx-ui-tour-ngx-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class AnchorStepsConstants {
    constructor(private translate: TranslateService) { }

    public stepsUnosNaloga() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-unos-naloga',
                title: this.translate.instant('MESSAGE.Dobrodošli'),
                content: this.translate.instant('MESSAGE.Za početak unosa naloga potrebno je otvoriti meni „Unos naloga“'),
                delayBeforeStepShow: 200,
            },
            {
                anchorId: 'nalog-pripremni',
                title: this.translate.instant('PROPERTIES.Nalog'),
                route: '/financije/pripremni-nalozi/2',
                content: this.translate.instant('MESSAGE.Odaberite Vrstu naloga koji želite popunjavati'),
                delayBeforeStepShow: 400,
            },
            {
                anchorId: 'broj-pripremni',
                title: this.translate.instant('PROPERTIES.Broj'),
                content: this.translate.instant('MESSAGE.Zatim unesite broj dokumenta koji knjižite'),
            },
            {
                anchorId: 'opis-pripremni',
                title: this.translate.instant('PROPERTIES.Opis'),
                content: this.translate.instant('MESSAGE.Zatim opis prve stavke na nalogu koju knjižimo'),
            },
            {
                anchorId: 'konto-pripremni',
                title: this.translate.instant('PROPERTIES.Konto'),
                content: this.translate.instant('PROPERTIES.Konto'),
            },
            {
                anchorId: 'komitent-pripremni',
                title: this.translate.instant('PROPERTIES.Komitent'),
                content: this.translate.instant('MESSAGE.Komitent-ako ga ima'),
            },
            {
                anchorId: 'duguje-pripremni',
                title: this.translate.instant('PROPERTIES.Duguje'),
                content: this.translate.instant('MESSAGE.Duguje-ako je prazno, onda preskočiti'),
            },
            {
                anchorId: 'potrazuje-pripremni',
                title: this.translate.instant('PROPERTIES.Potražuje'),
                content: this.translate.instant('MESSAGE.Potražuje-ako je prazno, onda preskočiti'),
            },
            {
                anchorId: 'dodaj-pripremni',
                title: this.translate.instant('PROPERTIES.Dodaj stavku'),
                content: this.translate.instant('MESSAGE.Dodaj Stavku Da bi ste stavku spremili na listu'),
            },
            {
                anchorId: 'stavka-spremljena-pripremni',
                // title: this.translate.instant('PROPERTIES.Dodaj stavku'),
                content: this.translate.instant('MESSAGE.Stavka spremljena i može se uređivati ili brisati'),
            },
            {
                anchorId: 'ponovno-unos-pripremni',
                title: this.translate.instant('PROPERTIES.Stavka'),
                content: this.translate.instant('MESSAGE.Stavka -Ukoliko želite dodati jos stavki u nalog ponovite proceduru za unos (opis, komitent, duguje, potražuje)'),
            },
            {
                anchorId: 'zatvori-nalog-pripremni-0',
                title: this.translate.instant('PROPERTIES.Zatvori nalog'),
                content: this.translate.instant('MESSAGE.Ako niste ručno zatvorili nalog (napravili ravnotežu dugovnog i potražnog salda)'),
                placement: 'left'
            },
            {
                anchorId: 'uknjizi-nalog-pripremni-0',
                title: this.translate.instant('PROPERTIES.Uknjiži nalog'),
                content: this.translate.instant('PROPERTIES.Uknjiži nalog'),
                placement: 'left'
            },
        ]
        return step;
    }

    public stepsKUF() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-kuf',
                title: this.translate.instant('MESSAGE.Dobrodošli'),
                content: this.translate.instant('MESSAGE.Za početak unosa naloga potrebno je otvoriti meni „Ulazne Fakture“'),
            },
            {
                anchorId: 'dodaj-kuf',
                route: '/pdv/kuf-podaci',
                title: this.translate.instant('DASHBOARD.Knjige izlaznih faktura'),
                content: this.translate.instant('MESSAGE.Zatim odabrati dodaj fakturu'),
                delayBeforeStepShow: 400,
            },
            {
                anchorId: 'vrsta-knjige-kuf',
                route: '/pdv/kuf-podaci/create',
                title: this.translate.instant('PROPERTIES.Vrsta'),
                content: this.translate.instant('MESSAGE.Unesite vrstu knjige gdje želite da vam se vodi ova stavka a ako nemate preferenciju, odaberite bilo koju'),
            },
            {
                anchorId: 'broj-fakture-kuf',
                title: this.translate.instant('PROPERTIES.Broj fakture'),
                content: this.translate.instant('MESSAGE.Unesite Broj ulazne fakture'),
            },
            {
                anchorId: 'datum-primitka-kuf',
                title: this.translate.instant('PROPERTIES.Datum primitka'),
                content: this.translate.instant('MESSAGE.Unesite datum primitka ulazne fakture.Dokument će biti prijavljen u PDV prijavu za za upisani mjesec'),
            },
            {
                anchorId: 'datum-izdavanja-kuf',
                title: this.translate.instant('PROPERTIES.Datum izdavanja'),
                content: this.translate.instant('MESSAGE.Unesite datum izdavanja dokumenta'),
            },
            {
                anchorId: 'valuta-placanja-kuf',
                title: this.translate.instant('PROPERTIES.Valuta plaćanja'),
                content: this.translate.instant('MESSAGE.Unesite broj dana odgode plaćanja'),
            },
            {
                anchorId: 'kuf-komitent',
                title: this.translate.instant('PROPERTIES.Komitent'),
                content: this.translate.instant('MESSAGE.Unesite komitenta koji je izdao fakturu'),
            },
            {
                anchorId: 'konto-troska-kuf',
                title: this.translate.instant('PROPERTIES.Konto'),
                content: this.translate.instant('MESSAGE.Unesite konto troška za ovu fakturu a ako vam nije važan onda preskočite'),
            },
            {
                anchorId: 'iznos-bez-pdv-kuf',
                title: this.translate.instant('PROPERTIES.Iznos bez PDV-a'),
                content: this.translate.instant('MESSAGE.Unesite iznos fakture bez PDVa a ako niste u PDV sistemu, unesite kompletan iznos'),
            },
            {
                anchorId: 'pdv-kuf',
                title: this.translate.instant('PROPERTIES.PDV'),
                content: this.translate.instant('MESSAGE.Unesite pripadajaći PDV a ako niste u PDV sistemu, preskočite'),
            },
            {
                anchorId: 'iznos-sa-pdv-kuf',
                title: this.translate.instant('PROPERTIES.Iznos sa PDVom'),
                content: this.translate.instant('MESSAGE.Unesite konačan iznos fakture'),
            },
            {
                anchorId: 'mozze-se-odbiti-kuf',
                title: this.translate.instant('PROPERTIES.Može se odbiti'),
                content: this.translate.instant('MESSAGE.Unesite iznos PDVa koji se može odbiti, ako ste u sistemu PDVa a ako niste u PDV sistemu, preskočite'),
            },
            {
                anchorId: 'uknjizi-nalog-kuf',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Uknjiži nalog'),
            },

        ];
        return step;
    }

    public stepsPecat() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-postavke',
                title: this.translate.instant('PROPERTIES.Pečat na fakturi'),
                content: this.translate.instant('MESSAGE.Početak unosa pečata'),
            },
            {
                anchorId: 'odaberi-pečat',
                route: '/settings',
                title: this.translate.instant('PROPERTIES.Odaberi datoteku'),
                content: this.translate.instant('MESSAGE.Odaberite fajl za pečat'),
            },
            {
                anchorId: 'spremi-pečat',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Klikom na spremi izmjene pečat je spremljen u bazu podataka i spreman za korištenje'),
            },
        ]
        return step;
    }

    public stepsPotpis() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-postavke',
                title: this.translate.instant('PROPERTIES.Potpis na fakturi'),
                content: this.translate.instant('MESSAGE.Početak unosa potpisa'),
            },
            {
                anchorId: 'odaberi-potpis',
                route: '/settings',
                title: this.translate.instant('PROPERTIES.Odaberi datoteku'),
                content: this.translate.instant('MESSAGE.Odaberite fajl za potpis'),
            },
            {
                anchorId: 'spremi-potpis',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Klikom na spremi izmjene potpis je spremljen u bazu podataka i spreman za korištenje'),
            },
        ]
        return step;
    }

    public stepsMemorandum() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-postavke',
                title: this.translate.instant('PROPERTIES.Memorandum'),
                content: this.translate.instant('MESSAGE.Početak unosa memoranduma'),
            },
            {
                anchorId: 'odaberi-memorandum',
                route: '/settings',
                title: this.translate.instant('PROPERTIES.Odaberi datoteku'),
                content: this.translate.instant('MESSAGE.Odaberite fajl za memorandum'),
            },
            {
                anchorId: 'spremi-memorandum',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Klikom na spremi izmjene memorandum je spremljen u bazu podataka i spreman za korištenje'),
            },
        ]
        return step;
    }

    public stepsKalkulacija() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-kalkulacija',
                title: this.translate.instant('MESSAGE.Dobrodošli'),
                content: this.translate.instant('MESSAGE.Početak unosa kalkulacije'),
                delayBeforeStepShow: 100,
            },
            {
                anchorId: 'dodaj-kalkulacija',
                route: '/invoice/calculation-list/2',
                title: this.translate.instant('DASHBOARD.UlaznaKalkulacija'),
                content: this.translate.instant('MESSAGE.Zatim odabrati dodaj kako bismo otvorili prozor izrade kalkulacije'),
                delayBeforeStepShow: 200,
            },
            {
                anchorId: 'dodatna-polja',
                route: '/invoice/create/importCalculation/2',
                title: this.translate.instant('PROPERTIES.DodatnaPolja'),
                content: this.translate.instant('MESSAGE.Unos dodatnih polja kalkulacije'),
            },
            {
                anchorId: 'polje-komitent',
                title: this.translate.instant('PROPERTIES.Komitent'),
                content: this.translate.instant('MESSAGE.Komitent koji je izdao fakturu'),
            },
            {
                anchorId: 'polje-artikal-kalkulacija',
                title: this.translate.instant('PROPERTIES.Artikal'),
                content: this.translate.instant('MESSAGE.Odaberite željeni artikal'),
            },
            {
                anchorId: 'polje-količina-kalkulacija',
                title: this.translate.instant('PROPERTIES.Kolicina'),
                content: this.translate.instant('MESSAGE.Unesite količinu'),
            },
            {
                anchorId: 'polje-nabavna-cijena',
                title: this.translate.instant('PROPERTIES.NabavnaCijena'),
                content: this.translate.instant('MESSAGE.Unesite prodajnu cijenu sa fakture'),
            },
            {
                anchorId: 'polje-cijena-kalkulacija',
                title: this.translate.instant('PROPERTIES.Cijena'),
                content: this.translate.instant('MESSAGE.Unesite prodajnu cijenu artikla'),
            },
            {
                anchorId: 'dodaj-artikal-kalkulacija',
                title: this.translate.instant('PROPERTIES.DodajArtikal'),
                content: this.translate.instant('MESSAGE.Da bi ste artikal spremili na listu pritisnite Dodaj artikal'),
            }, {
                anchorId: 'artikal-spremljen-kalkulacija',
                title: this.translate.instant('PROPERTIES.Artikal spremljen'),
                content: this.translate.instant('MESSAGE.Artikal spremljen i može se uređivati'),
            },
            {
                anchorId: 'polje-artikal-kalkulacija',
                title: this.translate.instant('PROPERTIES.Artikal'),
                content: this.translate.instant('MESSAGE.Ukoliko želite dodati jos artikala ponovite proceduru za unos artikla, količine, cijene'),
            },
            {
                anchorId: 'spremi-izmjene',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Nakon unosa artikala potrebno je spremiti kalkulaciju i time ste završili proces unosa kalkulacije'),
            }
        ]
        return step;
    }

    public stepsFaktura() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-faktura',
                title: this.translate.instant('MESSAGE.Dobrodošli'),
                content: this.translate.instant('MESSAGE.Početak unosa fakture'),
                delayBeforeStepShow: 100,
            },
            {
                anchorId: 'dodaj-faktura',
                route: '/invoice/11',
                title: this.translate.instant('DASHBOARD.Faktura'),
                content: this.translate.instant('MESSAGE.Zatim odabrati dodaj fakturu'),
                delayBeforeStepShow: 400,
            },
            {
                anchorId: 'dodatna-polja',
                route: '/invoice/create/11',
                title: this.translate.instant('PROPERTIES.DodatnaPolja'),
                content: this.translate.instant('MESSAGE.Unos dodatnih polja fakture'),
            },
            {
                anchorId: 'polje-komitent',
                title: this.translate.instant('PROPERTIES.Komitent'),
                content: this.translate.instant('MESSAGE.Komitent kome se fakturiše'),
            },
            {
                anchorId: 'polje-artikal-faktura',
                title: this.translate.instant('PROPERTIES.Artikal'),
                content: this.translate.instant('MESSAGE.Odaberite željeni artikal'),
            },
            {
                anchorId: 'polje-količina-faktura',
                title: this.translate.instant('PROPERTIES.Kolicina'),
                content: this.translate.instant('MESSAGE.Unesite količinu'),
            },
            {
                anchorId: 'polje-cijena-faktura',
                title: this.translate.instant('PROPERTIES.Cijena'),
                content: this.translate.instant('MESSAGE.Unesite prodajnu cijenu artikla'),
            },
            {
                anchorId: 'dodaj-artikal-faktura',
                title: this.translate.instant('PROPERTIES.DodajArtikal'),
                content: this.translate.instant('MESSAGE.Da bi ste artikal spremili na listu pritisnite Dodaj artikal'),
                delayBeforeStepShow: 200,
            },
            {
                anchorId: 'artikal-spremljen-faktura',
                title: this.translate.instant('PROPERTIES.Artikal spremljen'),
                content: this.translate.instant('MESSAGE.Artikal spremljen i može se uređivati'),
            },
            {
                anchorId: 'polje-artikal-faktura',
                title: this.translate.instant('PROPERTIES.Artikal'),
                content: this.translate.instant('MESSAGE.Ukoliko želite dodati jos artikala ponovite proceduru za unos artikla, količine, cijene'),
            },
            {
                anchorId: 'spremi-izmjene',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Nakon unosa artikala potrebno je spremiti fakturu i time ste završili proces unosa fakture'),
            }

        ]
        return step;
    }

    public stepsArtikli() {
        var step: IStepOption[] = [
            {
                anchorId: 'start-artikli',
                title: this.translate.instant('MESSAGE.Dobrodošli'),
                content: this.translate.instant('MESSAGE.Početak unosa artikla'),
                delayBeforeStepShow: 100,
            },
            {
                anchorId: 'dodaj-artikli',
                route: '/articles',
                delayBeforeStepShow: 200,
                title: this.translate.instant('DASHBOARD.Artikli'),
                content: this.translate.instant('MESSAGE.Zatim odabrati dodaj'),
            },
            {
                anchorId: 'dodatna-polja',
                route: '/articles/create',
                title: this.translate.instant('PROPERTIES.DodatnaPolja'),
                content: this.translate.instant('MESSAGE.Unos dodatnih polja'),
            },
            {
                anchorId: 'polje-naziv',
                title: this.translate.instant('PROPERTIES.Naziv'),
                content: this.translate.instant('MESSAGE.Unijeti obavezno polje Naziv artikla'),
            },

            {
                anchorId: 'spremi-izmjene',
                title: this.translate.instant('PROPERTIES.SpremiIzmjene'),
                content: this.translate.instant('MESSAGE.Nakon unosa naziv artikala, stavi potrebno je spremiti i time ste završili proces unosa artikla'),
            }
        ]
        return step;
    }

}
