<app-breadcrumb [title]="'Form Validation'" [items]="['Forms', 'Form Control']" [active_item]="'Form Validation'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Custom styles</h5>
          </div>
          <div class="card-body">
            <form class="needs-validation" novalidate [ngClass]="{'was-validated': validate}">
              <div class="form-row">
                <div class="col-md-4 mb-3">
                  <label for="validationCustom01">First name</label>
                  <input class="form-control" id="validationCustom01" type="text" placeholder="First name" required="" onlyAlphabets>
                  <div class="valid-feedback">Looks good!</div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationCustom02">Last name</label>
                  <input class="form-control" id="validationCustom02" type="text" placeholder="Last name" required="" onlyAlphabets>
                  <div class="valid-feedback">Looks good!</div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationCustomUsername">Username</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend">&#64;</span></div>
                    <input class="form-control" id="validationCustomUsername" type="text" placeholder="Username" aria-describedby="inputGroupPrepend" required="">

                    <div class="invalid-feedback">Please choose a username.</div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 mb-3">
                  <label for="validationCustom03">City</label>
                  <input class="form-control" id="validationCustom03" type="text" placeholder="City" required="">
                  <div class="invalid-feedback">Please provide a valid city.</div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationCustom04">State</label>
                  <input class="form-control" id="validationCustom04" type="text" placeholder="State" required="">
                  <div class="invalid-feedback">Please provide a valid state.</div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationCustom05">Zip</label>
                  <input class="form-control" id="validationCustom05" type="text" placeholder="Zip" required="">
                  <div class="invalid-feedback">Please provide a valid zip.</div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-check">
                  <div class="checkbox p-0">
                    <input class="form-check-input" id="invalidCheck" type="checkbox" required="">
                    <label class="form-check-label" for="invalidCheck">Agree to terms and conditions</label>
                  </div>
                  <div class="invalid-feedback">You must agree before submitting.</div>
                </div>
              </div>
              <button class="btn btn-primary" type="button" (click)=submit()>Submit form</button>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5>Browser defaults</h5><span>Not interested in custom validation feedback messages or writing JavaScript to change form behaviors? All good, you can use the browser defaults. Try submitting the form below. Depending on your browser and OS, you’ll see a slightly different style of feedback.</span><span>While these feedback styles cannot be styled with CSS.</span><span> If you want to use them it can be done by adding "ngNativeValidate" to your Form tag.</span>
          </div>
          <div class="card-body">
            <form ngNativeValidate>
              <div class="form-row">
                <div class="col-md-4 mb-3">
                  <label for="validationDefault01">First name</label>
                  <input class="form-control" id="validationDefault01" type="text" placeholder="First name" required onlyAlphabets>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationDefault02">Last name</label>
                  <input class="form-control" id="validationDefault02" type="text" placeholder="Last name" required onlyAlphabets>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationDefaultUsername">Username</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend2">&#64;</span></div>
                    <input class="form-control" id="validationDefaultUsername" type="text" placeholder="Username" aria-describedby="inputGroupPrepend2" required>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 mb-3">
                  <label for="validationDefault03">City</label>
                  <input class="form-control" id="validationDefault03" type="text" placeholder="City" required="">
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationDefault04">State</label>
                  <input class="form-control" id="validationDefault04" type="text" placeholder="State" required="">
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationDefault05">Zip</label>
                  <input class="form-control" id="validationDefault05" type="text" placeholder="Zip" required="">
                </div>
              </div>
              <div class="form-group">
                <div class="form-check">
                  <div class="checkbox p-0">
                    <input class="form-check-input" id="invalidCheck2" type="checkbox" required="">
                    <label class="form-check-label" for="invalidCheck2">Agree to terms and conditions</label>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Submit form</button>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5>Supported elements</h5><span>Not interested in custom validation feedback messages or writing JavaScript to change form behaviors? All good, you can use the browser defaults. Try submitting the form below. Depending on your browser and OS, you’ll see a slightly different style of feedback.</span><span>While these feedback styles cannot be styled with CSS, you can still customize the feedback text through JavaScript.</span>
          </div>
          <div class="card-body">
            <form class="was-validated">
              <div class="custom-control custom-checkbox mb-3">
                <input class="custom-control-input" id="customControlValidation1" type="checkbox" required="">
                <label class="custom-control-label" for="customControlValidation1">Check this custom checkbox</label>
                <div class="invalid-feedback">Example invalid feedback text</div>
              </div>
              <div class="custom-control custom-radio">
                <input class="custom-control-input" id="customControlValidation2" type="radio" name="radio-stacked" required="">
                <label class="custom-control-label" for="customControlValidation2">Toggle this custom radio</label>
              </div>
              <div class="custom-control custom-radio mb-3">
                <input class="custom-control-input" id="customControlValidation3" type="radio" name="radio-stacked" required="">
                <label class="custom-control-label" for="customControlValidation3">Or toggle this other custom radio</label>
                <div class="invalid-feedback">More example invalid feedback text</div>
              </div>
              <div class="form-group">
                <select class="custom-select" required="">
                  <option value="">Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <div class="invalid-feedback">Example invalid custom select feedback</div>
              </div>
              <div class="custom-file">
                <input class="custom-file-input" id="validatedCustomFile" type="file" required="">
                <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
                <div class="invalid-feedback">Example invalid custom file feedback</div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="card">
          <div class="card-header">
            <h5>Tooltips</h5>
          </div>
          <div class="card-body">
            <form class="needs-validation" novalidate [ngClass]="{'was-validated': tooltipValidation}">
              <div class="form-row">
                <div class="col-md-4 mb-3">
                  <label for="validationTooltip01">First name</label>
                  <input class="form-control" id="validationTooltip01" type="text" placeholder="First name" required="" onlyAlphabets>
                  <div class="valid-tooltip">Looks good!</div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationTooltip02">Last name</label>
                  <input class="form-control" id="validationTooltip02" type="text" placeholder="Last name" required="" onlyAlphabets>
                  <div class="valid-tooltip">Looks good!</div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationTooltipUsername">Username</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" id="validationTooltipUsernamePrepend">@</span></div>
                    <input class="form-control" id="validationTooltipUsername" type="text" placeholder="Username" aria-describedby="validationTooltipUsernamePrepend" required="">
                    <div class="invalid-tooltip">Please choose a unique and valid username.</div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6 mb-3">
                  <label for="validationTooltip03">City</label>
                  <input class="form-control" id="validationTooltip03" type="text" placeholder="City" required="">
                  <div class="invalid-tooltip">Please provide a valid city.</div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationTooltip04">State</label>
                  <input class="form-control" id="validationTooltip04" type="text" placeholder="State" required="">
                  <div class="invalid-tooltip">Please provide a valid state.</div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationTooltip05">Zip</label>
                  <input class="form-control" id="validationTooltip05" type="text" placeholder="Zip" required="">
                  <div class="invalid-tooltip">Please provide a valid zip.</div>
                </div>
              </div>
              <button class="btn btn-primary" type="button" (click)='tooltipSubmit()'>Submit form</button>
            </form>
          </div>
        </div> -->
      </div>
    </div>
  </div>