
import { RegisterModel } from './../models/register.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { loginDto } from '../../models/login/loginDto';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { catchError, map } from 'rxjs/operators';
import {
  AccessToken,
  AccountInfo,
  RefreshToken,
} from '../../models/login/AuthenticateResultDto';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import * as appconstatnts from 'src/app/_constants/appMessages';
import { Tester } from '../../_constants/ApplicationRoles';
import { BUSINESS_UNIT } from '../../_constants/appMessages';
import { PresenceService } from './presence.service';
import { BaseApiService } from './base-api.service';

const CONTEXT_KEY = 'userContext';
const CONTEXT_FIRM_DATA = 'contextFirmData';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  static LOGGED_IN = new EventEmitter();
  baseUrl: string = environment.apiUrl;
  private currentUserSource = new ReplaySubject<AccountInfo>(1); //sprema samo jednu vrijednost (ne treba prethodne spremati) -> size of buffer
  currentUser$ = this.currentUserSource.asObservable();

  private currentAccessTokenSource = new ReplaySubject<AccessToken>(1);
  currentAccessToken$ = this.currentAccessTokenSource.asObservable();

  constructor(private httpClient: HttpClient,
    private presenceService: PresenceService,
    private api: BaseApiService,
  ) { }

  registerUser(user: any = {}): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + 'authentication/register', user)
      .pipe(
        catchError(this.handleError<RegisterModel>(`authentication/register`))
      );
  }

  registerUserConfirmation(body): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + 'authentication/register/verify', body, {
        observe: 'response',
      })
      .pipe(
        catchError(
          this.handleError<RegisterModel>(
            `authentication/password-reset-request`
          )
        )
      );
  }

  sendPasswordResetRequest(body): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + 'authentication/password-reset-request', body, {
        observe: 'response',
      })
      .pipe(
        catchError(
          this.handleError<RegisterModel>(
            `authentication/password-reset-request`
          )
        )
      );
  }

  sendPasswordReset(body): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + 'authentication/password-reset-confirm', body, {
        observe: 'response',
      })
      .pipe(
        catchError(
          this.handleError<RegisterModel>(
            `authentication/password-reset-confirm`
          )
        )
      );
  }

  login(login: loginDto) {
    return this.httpClient
      .post<apiResponseFactory>(this.baseUrl + 'authentication/sign-in', login,)
      .pipe(
        map((response: apiResponseFactory) => {
          const result = response.result.item;
          if (result) {
            this.setCurrentUser(result.accountInfo);
            this.setAccessToken(result.accessToken);
            this.setRefreshToken(result.refreshToken);
            this.setRoleClaims(result.claims)

            //this.presenceService.createHubConnection(result);
          }
        })
      );
  }

  logout() {
    localStorage.removeItem(appconstatnts.accessToken);
    localStorage.removeItem(appconstatnts.refreshToken);
    localStorage.removeItem(appconstatnts.accountInfo);
    localStorage.removeItem(appconstatnts.roleClaims);
    this.currentUserSource.next(null);
    this.currentAccessTokenSource.next(null);
    //this.presenceService.stopHubConnection();
    window.location.reload();
  }

  setUserConfiguration(body, customContext?): Observable<any> {
    var send = JSON.stringify(body);
    var params = {
      userConfiguration: send
    }

    return this.api
      .post(
        'account/update-user-configuration',
        params,
        {},
        { headers: this.api.getHeaders(customContext) }
      )
      .pipe(
        catchError(this.handleError<RegisterModel>(`rim/fakture/update-faktura`))
      );
  }

  setCurrentUser(val: AccountInfo) {
    localStorage.setItem(appconstatnts.accountInfo, JSON.stringify(val));
    this.currentUserSource.next(val);
  }

  setAccessToken(val: AccessToken) {
    localStorage.setItem(appconstatnts.accessToken, JSON.stringify(val));
    this.currentAccessTokenSource.next(val);
  }

  setLocalStorageUserSettings(settings) {
    localStorage.setItem(appconstatnts.userSettings, JSON.stringify(settings));
  }

  getLocalStorageUserSettings() {
    const storage = localStorage.getItem(appconstatnts.userSettings);
    if (storage) {
      return JSON.parse(storage)
    }
    return {}
  }

  setRefreshToken(val: RefreshToken) {
    localStorage.setItem(appconstatnts.refreshToken, JSON.stringify(val));
  }
  setRoleClaims(claims) {
    localStorage.setItem(appconstatnts.roleClaims, JSON.stringify(claims));
  }
  get roleClaims() {
    const storage = localStorage.getItem(appconstatnts.roleClaims);
    if (storage) {
      return JSON.parse(storage)
    }
    return {}
  }

  get currentUser(): AccountInfo {
    const storage = localStorage.getItem(appconstatnts.accountInfo);
    if (storage) {
      return JSON.parse(storage) as AccountInfo;
    }
    return {} as AccountInfo;
  }

  get isCurrentUserTester(): boolean {
    return !!this.currentUser.userRoles.find(r => r.roleName === Tester);
  }

  get isCurrentUserZeljkoOrIgor(): boolean {
    if (this.currentUser.email == "igor.matic@fiskus.ba" || this.currentUser.email == "web@fiskus.ba") {
      return true;
    }
    return false;
  }

  get context() {
    const contextString = localStorage.getItem(CONTEXT_KEY);
    if (contextString) {
      return JSON.parse(contextString);
    }
    return {};
  }

  set context(value) {
    localStorage.setItem(CONTEXT_KEY, JSON.stringify(value));
  }

  set contextFirmData(value) {
    localStorage.setItem(CONTEXT_FIRM_DATA, JSON.stringify(value));
  }

  get contextFirmData() {
    const firmDataString = localStorage.getItem(CONTEXT_FIRM_DATA);
    if (firmDataString) {
      return JSON.parse(firmDataString);
    }
    return {};
  }

  get accessToken(): AccessToken {
    const storage = localStorage.getItem(appconstatnts.accessToken);
    if (storage) {
      return JSON.parse(storage) as AccessToken;
    }
    return {} as AccessToken;
  }

  getRefreshToken(): RefreshToken {
    const storage = localStorage.getItem(appconstatnts.refreshToken);
    if (storage) {
      return JSON.parse(storage) as RefreshToken;
    }
    return {} as RefreshToken;
  }

  refreshToken(token: string) {
    return this.httpClient
      .post(this.baseUrl + 'authentication/refresh-token', { tokenId: token })
      .pipe(
        map((response: apiResponseFactory) => {
          const result = response.result;
          this.setCurrentUser(result.accountInfo);
          this.setAccessToken(result.accessToken);
          this.setRefreshToken(result.refreshToken);
          //this.presenceService.createHubConnection(result);
          return response;
        })
      );
  }

  getCurrentBusinessUnitType() {
    let businessUnit = JSON.parse(localStorage.getItem(BUSINESS_UNIT));
    return businessUnit.vrsta;
  }

  getCurrentBusinessUnitName() {
    let businessUnit = JSON.parse(localStorage.getItem(BUSINESS_UNIT));
    return businessUnit.naziv;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }


  getOnlineUsers() {//: Observable<string[]> {
    /*  return this.api.get('presence-cntrl').pipe(
       map((response: HttpResponse<apiResponseFactory>) => {
         if (response.status == 200 && response?.body?.result) {
           return response.body.result;
         }
         return undefined;
       }),
       catchError(this.handleError<string[]>(`getAllItems`))
     ); */

    /*   return this.api
        .get('presence-cntrl',)
        .pipe(catchError(this.handleError<[]>(`presence-cntrl`)));
   */

  }

  getUserSettings() {
    return this.api
      .get('account/user-settings')
      .pipe(catchError(this.handleError<[]>('account/user-settings')));
  }

  setUserSettings(params: any = {}): Observable<any> {
    return this.api
      .post('account/update-user-settings', params)
      .pipe(catchError(this.handleError<[]>('account/update-user-settings')));
  }

  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(ipad|iP(hone|od))/i.test(ua)) {
      return 'ios';
    }
    if (
      /Mobile|Android|BlackBerry|tablet|playbook|silk|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|(android(?!.*mobi))|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'android';
    }
    return 'desktop';
  }

}
