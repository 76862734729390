import { Component, PLATFORM_ID, Inject, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ContextComponent } from './shared/components/context/context.component';
import { getAcceptLanguage } from './shared/helpers/getAcceptLanguage';
import { SignalrNotificationService } from './shared/services/signalr-notification.service';
import { NgxTourService } from './shared/services/ngx-tour.service';
import { AccountService } from './shared/services/account.service';
import { NgxFreshChatService } from 'src/lib/ngx-freshchat-lib.service';
import { FCUser } from 'src/lib/models';


declare var LiveAgent: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  refreshingTheView = false;

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private translate: TranslateService,
    public signalRService: SignalrNotificationService,
    private ngxTourService: NgxTourService,
    public accountService: AccountService,
    private elementRef: ElementRef,
    private chat: NgxFreshChatService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      var lang = getAcceptLanguage.getLocalStorageLanguage();
      this.useLanguage(lang)

    }
    ContextComponent.CONTEXT_CHANGED.subscribe(() => {
      this.refreshingTheView = true;
      setTimeout(() => {
        this.refreshingTheView = false;
      });
    });
    // registerLocaleData(bs)
  }

  ngOnInit() {
    //this.setFreshDeskUser();
  }

  useLanguage(language: string): void {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
  }

  setFreshDeskUser() {
    var user = new FCUser();
    user.firstName = "Fiskus";
    user.lastName = "doo";

    this.chat.setUserProperties(user);
    this.chat.init({
      token: 'eyJraWQiOiJjdXN0b20tb2F1dGgta2V5aWQiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJmcmVzaGNoYXQiLCJzdWIiOiIwZjM0YzRiNS1hYTcwLTRkYzYtYjc1YS1iYjYxYzk4MjZjZGMiLCJjbGllbnRJZCI6ImZjLTRiZGYxYTVmLWRjNzItNDQwMy05MThmLTI4NGFlNzQ2NGNhMCIsInNjb3BlIjoiYWdlbnQ6cmVhZCBhZ2VudDpjcmVhdGUgYWdlbnQ6dXBkYXRlIGFnZW50OmRlbGV0ZSBjb252ZXJzYXRpb246Y3JlYXRlIGNvbnZlcnNhdGlvbjpyZWFkIGNvbnZlcnNhdGlvbjp1cGRhdGUgbWVzc2FnZTpjcmVhdGUgbWVzc2FnZTpnZXQgYmlsbGluZzp1cGRhdGUgcmVwb3J0czpmZXRjaCByZXBvcnRzOmV4dHJhY3QgcmVwb3J0czpyZWFkIHJlcG9ydHM6ZXh0cmFjdDpyZWFkIGFjY291bnQ6cmVhZCBkYXNoYm9hcmQ6cmVhZCB1c2VyOnJlYWQgdXNlcjpjcmVhdGUgdXNlcjp1cGRhdGUgdXNlcjpkZWxldGUgb3V0Ym91bmRtZXNzYWdlOnNlbmQgb3V0Ym91bmRtZXNzYWdlOmdldCBtZXNzYWdpbmctY2hhbm5lbHM6bWVzc2FnZTpzZW5kIG1lc3NhZ2luZy1jaGFubmVsczptZXNzYWdlOmdldCBtZXNzYWdpbmctY2hhbm5lbHM6dGVtcGxhdGU6Y3JlYXRlIG1lc3NhZ2luZy1jaGFubmVsczp0ZW1wbGF0ZTpnZXQgZmlsdGVyaW5ib3g6cmVhZCBmaWx0ZXJpbmJveDpjb3VudDpyZWFkIHJvbGU6cmVhZCBpbWFnZTp1cGxvYWQiLCJpc3MiOiJmcmVzaGNoYXQiLCJ0eXAiOiJCZWFyZXIiLCJleHAiOjIwMTY2OTc2MzAsImlhdCI6MTcwMTA3ODQzMCwianRpIjoiMzhhOGUwZjUtOGI3Yi00ZDg4LWIyOWMtNGFmMDhkYWUzMDBkIn0.oWJcZ23LH7uqXB7CAqPuSLC3KZ38Zh07PmeJJwMZqW4',
      host: 'fiskusdoo-8fe7ce3f639903317008710.freshchat.com/v2/'
    })
      .subscribe(
        () => console.log('FreshChat is ready!')
      );
  }
}


