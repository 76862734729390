<app-breadcrumb [title]="'Form Wizard Two'" [items]="['Forms', 'Form Layout']" [active_item]="'Form Wizard Two'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Form Wizard And Validation</h5><span>Validation Step Form Wizard</span>
        </div>
        <div class="card-body">
          
        </div>
      </div>
    </div>
  </div>
</div>