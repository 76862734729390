<div class="card card-body mb-0">
    <div class="row border-bottom pb-3 mb-3">
        <div class="col-12">
            <form ngbAutofocus [formGroup]="form" (ngSubmit)="onSubmit()">

                <div class="col-12 mb-2">
                    <label class="font-weight-bold">{{ "PROPERTIES.Komitent" | translate }}</label>
                    <ng-select [items]="komitenti" loadingText="Ucitavanje..." [virtualScroll]="true"
                        bindLabel="komitent" bindValue="sifra" formControlName="komitent" [selectOnTab]="true"
                        [clearable]="false">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item.komitent }}</div>
                        </ng-template>
                    </ng-select>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                      control: form.controls.komitent
                    }">
                    </ng-container>
                </div>

                <div class="col-12 mb-2">
                    <label class="font-weight-bold">{{ "PROPERTIES.NaDan" | translate }}</label>
                    <div class="form-group">
                        <div>
                            <input formControlName="naDan" class="form-control digits" id="example-datetime-local-input"
                                type="datetime-local" value="2019-01-19T18:45:00" />
                        </div>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                            validation: 'required',
                            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                            control: form.controls.naDan
                          }">
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 mb-2">
                    <label class="font-weight-bold">{{ "PROPERTIES.Konto" | translate }}</label>
                    <input formControlName="konto" class="form-control" type="text" />
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                        control: this.form.controls.konto
                      }">
                    </ng-container>
                </div>


                <div class="d-flex align-items-center justify-content-end mt-4">
                    <button type="button" class="btn btn-light mr-2" (click)="closeModal()">
                        Otkaži
                    </button>

                    <button type="submit" class="btn btn-primary d-flex align-items-center justify-content-center">
                        Preuzmi IOS
                        <span class="spinner-border spinner-border-sm text-light ml-1" role="status"
                            *ngIf="loading['getIos']">
                            <span class="sr-only">Loading...</span>
                        </span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <small class="text-danger">{{ message | translate }}</small>
    </ng-container>
</ng-template>