<div class="card-body">
  <h5 class="mb-3 border-bottom">{{"DASHBOARD.Dodaj Artikal" | translate }}</h5>
  <form [formGroup]="newArticleForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-md-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.Naziv" | translate }}</label>
        <input type="text" class="form-control" formControlName="naziv" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.newArticleForm.controls.naziv
          }">
        </ng-container>
      </div>
      <div class="col-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.TipArtikla" | translate }}</label>
        <ng-select [items]="articleTypes" loadingText="Ucitavanje..." [virtualScroll]="true" bindLabel="value"
          bindValue="key" formControlName="tip" [selectOnTab]="true">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div>{{ item.value }}</div>
          </ng-template>
        </ng-select>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.newArticleForm.controls.tip
          }">
        </ng-container>
      </div>
      <div class="col-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.Podgrupa" | translate }}</label>
        <ng-select [items]="subGroups" loadingText="Ucitavanje..." [virtualScroll]="true" bindLabel="opis"
          bindValue="podgrupa" formControlName="podgrupa" [selectOnTab]="true">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div>{{ item.opis }}</div>
          </ng-template>
        </ng-select>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.newArticleForm.controls.podgrupa
          }">
        </ng-container>
      </div>
      <div class="col-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.JedinicaMjere" | translate }}</label>
        <input type="text" class="form-control" formControlName="jm" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.newArticleForm.controls.jm
          }">
        </ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'isMoreThanFourCharacters',
            message: 'VALIDATORS.Maksimalan broj karaktera je cetiri',
            control: this.newArticleForm.controls.jm
          }">
        </ng-container>
      </div>

      <div class="col-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.PDVTarifa" | translate }}</label>
        <ng-select [items]="vatRatings" loadingText="Ucitavanje..." [virtualScroll]="true" bindLabel="lookupString"
          bindValue="tarifniBroj" formControlName="pdvTarifa" (change)="setVATpercent()" [selectOnTab]="true">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div>{{ item.lookupString }}</div>
          </ng-template>
        </ng-select>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
            control: this.newArticleForm.controls.pdvTarifa
          }">
        </ng-container>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.Barkod" | translate }}</label>
        <input type="text" class="form-control" inputmode="numeric" formControlName="barKod" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'isMoreThanFifteenCharacters',
            message: 'VALIDATORS.Maksimalan broj karaktera je petnaest',
            control: this.newArticleForm.controls.barKod
          }">
        </ng-container>
      </div>

      <div class="col-6 col-md-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.KataloskiBroj" | translate }}</label>
        <input type="text" class="form-control" formControlName="katalosskiBrojevi" />
      </div>

      <div class="col-6 col-md-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.KataloskiBroj" | translate }} 2</label>
        <input type="text" class="form-control" formControlName="katalosskiBroj2" />
      </div>

      <div class="col-6 col-md-6 mb-2">
        <label class="font-weight-bold">{{ "PROPERTIES.KataloskiBroj" | translate }} 3</label>
        <input type="text" class="form-control" formControlName="katalosskiBroj3" />
      </div>

      <div class="col-12 text-right mt-4">
        <button class="btn btn-light mr-3" (click)="closeModal()" type="button">
          {{ "PROPERTIES.Otkazi" | translate }}
        </button>
        <button class="btn btn-primary"> {{ "PROPERTIES.SpremiIzmjene" | translate }}</button>
      </div>
    </div>
  </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && control.touched">
    <small class="text-danger">{{ message | translate }}</small>
  </ng-container>
</ng-template>