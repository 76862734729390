import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddNewArticleComponent } from 'src/app/shared/components/modals/add-new-article/add-new-article.component';
import { FormHelper } from 'src/app/shared/helpers/form.helper';

@Component({
  selector: 'app-obilasci-artikli-item-form',
  templateUrl: './obilasci-artikli-item-form.component.html',
  styleUrl: './obilasci-artikli-item-form.component.scss'
})
export class ObilasciArtikliItemFormComponent implements OnInit {
  private fb = new UntypedFormBuilder();
  @Input() itemsFormGroup: UntypedFormGroup;
  @Input() articles: any;
  // @Input() invoice: UnosPopisaDto;
  @Input() articleIndex: any;
  @Input() isImportCalculation: boolean = false;

  item;
  updatingItem = -1;
  isSavingFlag = false;
  mozeUMinus = true;
  currStanje = 0;
  MAX_STANJE = 9999999999;
  updating = false;
  bsModalRef: BsModalRef;

  @Output() itemFormSubmitted = new EventEmitter();
  @Output() articleAdded = new EventEmitter();

  @ViewChild('formElement') itemFormElement: HTMLElement;
  @ViewChild('artikalElement') artikalFormElement: HTMLElement;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
  }

  saveItem() {
    this.updating = true;
    if (this.itemsFormGroup.invalid) {
      this.itemsFormGroup.markAllAsTouched();
      FormHelper.handleFormInvalid(this.itemsFormGroup, this.itemFormElement);
      return;
    }

    this.emitSaveItem();
  }

  emitSaveItem() {
    this.itemFormSubmitted.emit({
      updatingItem: this.updatingItem,
      item: this.itemsFormGroup,
    });
    this.isSavingFlag = true;
    this.updating = false;
    // this.closeModal();
  }

  cancelUpdate() {
    this.closeModal();
  }

  closeModal() {
    this.modalService.hide();
  }

  clearItemForm() {
    this.itemsFormGroup = this.getForm();
    this.updatingItem = -1;
  }

  openAddNewArticleModal() {
    const initialState: ModalOptions = {
      class: "modal-dialog-centered modal-lg"
    };
    this.bsModalRef = this.modalService.show(AddNewArticleComponent, initialState);
    this.bsModalRef.content.updateInputValue.subscribe((res: any) => {
      if (res) {
        this.articleAdded.emit();
        this.itemsFormGroup.controls.artikal.setValue(res.sifra);
        this.item = res;
      }
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter(t => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach(arr_term => {
      let search = item['search_label'].toLowerCase();
      isWordThere.push(search.indexOf(arr_term) != -1);
    });

    const all_words = (this_word) => this_word;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(all_words);
  }


  @ViewChild('kolicccina') izlazElement: ElementRef;
  moveToNext($event) {
    $event.stopPropagation();

    if (this.isSavingFlag == true) {              //problem nakon dodavanja, ponovno se vraca ovdje, dodan flag
      this.isSavingFlag = false;
    }
    else {
      this.izlazElement['myInput'].nativeElement.focus();
    }
  }


  getForm(): UntypedFormGroup {
    return this.fb.group({
      sifra: [null, Validators.required],
      kolicina: [1, Validators.required],
    });
  }


}
