import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { AllFfTipoviFormiHeaderDto } from '../../models/fiskus-field/AllFfTipoviFormiHeaderDto';
import { BaseApiService } from '../base-api.service';
import { FFTipoviFormiDto } from '../../models/fiskus-field/FFTipoviFormiDto';
import { PagedResponse } from '../../models/paged-response.model';
import { FFTipoviFormiPodaciModel } from '../../models/fiskus-field/FFTipoviFormiPodaciModel';

@Injectable({
  providedIn: 'root'
})
export class FfTipoviFormiService {
  constructor(private api: BaseApiService) { }

  getAllHeader() {
    return this.api.get('fiskus-field/tipovi-formi/all-header',).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (obilasci) => new AllFfTipoviFormiHeaderDto(obilasci)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<AllFfTipoviFormiHeaderDto[]>(`fiskus-field/tipovi-formi/all-header`))
    );
  }

  getAllNeaktivneHeader() {
    return this.api.get('fiskus-field/tipovi-formi/all-header-neaktivne',).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (obilasci) => new AllFfTipoviFormiHeaderDto(obilasci)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<AllFfTipoviFormiHeaderDto[]>(`fiskus-field/tipovi-formi/all-header-neaktivne`))
    );
  }

  getPaged(params: any): Observable<PagedResponse<FFTipoviFormiDto>> {
    return this.api.post('fiskus-field/tipovi-formi', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FFTipoviFormiDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FFTipoviFormiDto(i));
        return pagedModel;
      })
    );
  }

  getBySifra(sifra: number, customContext?: any): Observable<any> {
    const options = customContext
      ? { headers: this.api.getHeaders(customContext) }
      : {};
    return this.api
      .post(
        'fiskus-field/tipovi-formi/by-sifra',
        { sifra },
        {},
        options
      )
      .pipe(
        map((res) => {
          const invoice = new FFTipoviFormiDto(res.body.result);
          invoice.ffTipoviFormiPodaci = invoice.ffTipoviFormiPodaci.map(
            (i) => new FFTipoviFormiPodaciModel(i)
          );
          return invoice;
        }),
        catchError(
          this.handleError<FFTipoviFormiDto[]>(
            `fiskus-field/tipovi-formi/by-id`
          )
        )
      );
  }

  getByAutoBr(autoBr: number, customContext?: any): Observable<any> {
    const options = customContext
      ? { headers: this.api.getHeaders(customContext) }
      : {};
    return this.api
      .post(
        'fiskus-field/tipovi-formi/by-autobr',
        { autoBr: autoBr },
        {},
        options
      )
      .pipe(
        map((res) => {
          const invoice = new FFTipoviFormiDto(res.body.result);
          invoice.ffTipoviFormiPodaci = invoice.ffTipoviFormiPodaci.map(
            (i) => new FFTipoviFormiPodaciModel(i)
          );
          return invoice;
        }),
        catchError(
          this.handleError<FFTipoviFormiDto[]>(
            `fiskus-field/tipovi-formi/by-id`
          )
        )
      );
  }

  getPodaciById(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/tipovi-formi/podaci-by-id', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/tipovi-formi/podaci-by-id`)));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('fiskus-field/tipovi-formi/create', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tipovi-formi/create')));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('fiskus-field/tipovi-formi/update', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tipovi-formi/update')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/tipovi-formi/delete', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tipovi-formi/delete')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }

}
