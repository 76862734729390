export const FILE_TYPES = [
    { type: "png", value: "image/png" },
    { type: "gif", value: "image/gif" },
    { type: "jpeg", value: "image/jpeg" },
    { type: "jpg", value: "image/jpg" },
    { type: "word", value: "application/msword" },
    { type: "word-new", value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
    { type: "pdf", value: "application/pdf" },
    { type: "excel", value: "application/vnd.ms-excel" },
    { type: "excel-new", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
];
