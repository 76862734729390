<div (click)="toggleDropdownMobile()">
    <app-feather-icons [icon]="'user'"></app-feather-icons>
</div>

<ul class="notification-dropdown onhover-show-div" [class.active]="toggleDropdownMobile">
    <li>
        <app-feather-icons [icon]="'user'"></app-feather-icons>
        <h6 class="f-18 mb-0">{{ firstName }} {{ lastName }}</h6>
    </li>

    <a routerLink="/settings" class="dropdown-item pl-3 py-3" tourAnchor="start-postavke">
        <i class="fa fa-cog fa-lg fa-fw mr-2"></i>
        Postavke
    </a>

    <a class="dropdown-item pl-3 py-3" (click)="openClipboard()">
        <i class="fa fa-clipboard fa-lg fa-fw mr-2"></i>
        {{"PROPERTIES.Međuspremnik" | translate}}
    </a>

    <button (click)="downloadTringExtension()" class="dropdown-item pl-3 py-3">
        <i class="fa fa-download fa-lg fa-fw mr-2"></i>
        TRING ekstenzija
    </button>

    <button (click)="downloadNSCExtension()" class="dropdown-item pl-3 py-3">
        <i class="fa fa-cloud-download fa-lg fa-fw mr-2"></i>
        NSC ekstenzija
    </button>

    <button (click)="logout()" class="dropdown-item pl-3 py-3">
        <i class="fa fa-sign-out fa-lg fa-fw mr-2"></i>
        Odjavi se
    </button>
</ul>