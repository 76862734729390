<div class="card-body">
    <h5 class="mb-3 border-bottom">{{ forma?.naziv }}</h5>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row  ">

            <div class="col-12 form-group">
                <label class="font-weight-bold">{{"PROPERTIES.Komitent" | translate}} </label>
                @if (komitentiFiltered == false) {
                <label class="font-weight-bold text-danger">  ( ... {{"PROPERTIES.u blizini" | translate}} ) </label>
                }
                <div class="input-group custom-group">
                    <ng-select class="col p-0" [items]="komitenti" loadingText="Ucitavanje..." [virtualScroll]="true"
                        bindLabel="komitent" bindValue="sifra" formControlName="komitent" [selectOnTab]="true"
                        [clearable]="false" (keydown)="komitentiSearchActivated($event)">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item.komitent }}</div>
                        </ng-template>
                    </ng-select>
                    <div class="input-group-append">
                        <span class="input-group-text p-0">
                            <button tabindex="-1" (click)="openAddNewClientModal()" type="button"
                                class="btn btn-link btn-sm">
                                <i class="fa fa-plus"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                    control: this.form.controls.komitent
                  }">
                </ng-container>
            </div>

            <ng-container *ngFor="let row of form.get('fFObilasciPodaci')?.controls; let index = index">

                <div class="col-12 form-group">
                    @if ( row.get('tipPolja')?.value == tipoviPolja.Text)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i
                                    class="icofont icofont-pencil-alt-5"></i></span></div>
                        <input class="form-control" type="text" [formControl]="row.get('vrijednost')" />
                    </div>

                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Broj)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i
                                    class="icon-quote-right"></i></span></div>
                        <input class="form-control" type="number" [formControl]="row.get('vrijednost')"
                            autocomplete="off">

                    </div>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Boolean)
                    {
                    <label class="col-form-label m-r-10 font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <div class="media-body text-left icon-state">
                        <label class="switch">
                            <input type="checkbox" [formControl]="row.get('vrijednost')"><span
                                class="switch-state"></span>
                        </label>
                    </div>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.DropDown)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <ng-select [items]="row.get('dropDown').value" loadingText="Ucitavanje..." [virtualScroll]="true"
                        bindLabel="item" bindValue="item" [selectOnTab]="true" [formControl]="row.get('vrijednost')">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item }}</div>
                        </ng-template>
                    </ng-select>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Datum)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i
                                    class="fa fa-calendar"></i></span></div>
                        <input class="form-control" type="datetime-local" [formControl]="row.get('vrijednost')" />
                    </div>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Tag)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <ng-select class="col p-0" [items]="tagovi" loadingText="Ucitavanje..." [virtualScroll]="true"
                        bindLabel="naziv" bindValue="naziv" [formControl]="row.get('vrijednost')" [selectOnTab]="true"
                        [multiple]="true">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>{{ item.naziv }}</div>
                        </ng-template>
                    </ng-select>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Potpis)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <div class="potpis-height">
                        <canvas-whiteboard #canvasWhiteboard [options]="canvasOptions"
                            [formControl]="row.get('vrijednost')">
                        </canvas-whiteboard>
                    </div>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Fotografija ||
                    row.get('tipPolja')?.value == tipoviPolja.Fotografija2 )
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>
                    <div class="input-group">
                        <button type="button" class="btn btn-sm btn-primary" (click)="openWebcam()">
                            <i class="fa fa-camera"></i>
                            {{ "PROPERTIES.Fotografija" | translate }}</button>

                        <button type="button" class="btn btn-sm btn-outline-primary ml-2" (click)="fileInput.click()"
                            [ngbTooltip]="'PROPERTIES.Podržani dokumenti' | translate">
                            <i class="fa fa-plus"></i>
                            <input #fileInput type="file" hidden (change)="onFileChange($event)" accept="image/png, image/gif, image/jpeg, image/jpg,
                                .doc, .docx, .pdf, .xls, .xlsx">
                            {{ "PROPERTIES.Odaberi fajl" | translate }}</button>
                    </div>
                    <div class="col-12">
                        <div class="file-content">
                            <div class="card">
                                <div class="card-body file-manager">

                                    <ul class="files">
                                        @for (item of files; track $index) {
                                        <li class="file-box me-1">
                                            <div class="file-top">
                                                @if (item.file.type == 'application/pdf') {
                                                <i class="fa fa-file-pdf-o txt-primary"></i>
                                                }

                                                @else if (item.file.type == 'application/vnd.ms-excel' ||
                                                item.file.type ==
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                                                <i class="fa fa-file-excel-o txt-primary"></i>
                                                }

                                                @else if (item.file.type == 'application/msword' || item.file.type ==
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                                                {
                                                <i class="fa fa-file-word-o txt-primary"></i>
                                                }

                                                @else {
                                                @if (item?.base64) {
                                                <img [src]="item?.base64" style="width: 80px; height: 80px;">}
                                                @else {
                                                <i class="fa fa-file-image-o txt-primary"></i>
                                                }
                                                }
                                                <i role="button" (click)="removeFile(item)"
                                                    class="fa fa-times f-14 ellips text-danger"></i>
                                            </div>
                                            <img>
                                            <div class="file-bottom">
                                                <h6>{{item.file.name}}</h6>
                                                <!--  <p class="mb-1">2.0 MB</p> -->
                                            </div>
                                        </li>
                                        }

                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    @else if (row.get('tipPolja')?.value == tipoviPolja.Artikli)
                    {
                    <label class="font-weight-bold">{{ row.get('naziv')?.value }}</label>

                    <div class="row" *ngIf="artikliArray?.length">
                        <div class="col-12">
                            <div class="table-responsive invoice-table" id="table">
                                <table class="table table-bordered table-striped my-custom-scrollbar">
                                    <thead class="sticky-row">
                                        <tr>
                                            <th class="item-column">
                                                <p class="p-2 mb-0 h6"> {{ "PROPERTIES.Artikal" | translate }}</p>
                                            </th>
                                            <th class="price-colum">
                                                <p class="p-2 mb-0 h6">{{ "PROPERTIES.Kolicina" | translate }}</p>
                                            </th>

                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody class="tbody">
                                        <tr *ngFor="let item of artikliArray.controls; let i = index">
                                            <td class="text-left p-2">
                                                {{ findArticleName( item.get("artikal").value) }}
                                            </td>
                                            <td class="text-right p-2">
                                                {{ item.get("kolicccina").value }}
                                            </td>

                                            <td class="p-2 text-right">
                                                <div class="d-flex">
                                                    <button type="button" (click)="deleteItem(i)"
                                                        class="btn btn-link btn-sm px-2 py-0">
                                                        <i class="fa fa-times fa-lg text-danger"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row" formArrayName="artikliForm">
                        <section class="m-n3 col-12">
                            <app-obilasci-artikli-item-form class="w-100" (itemFormSubmitted)="saveItem($event)"
                                (articleAdded)="getAllArticles()" [itemsFormGroup]="itemsFormGroup"
                                [articles]="articles" [articleIndex]="articleIndex">
                            </app-obilasci-artikli-item-form>
                        </section>
                    </div>
                    }

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                        control: row.get('vrijednost')
                      }">
                    </ng-container>
                </div>

            </ng-container>

        </div>

        <div class="col-12 mt-4 text-right">
            <button class="btn btn-light mr-3" type="button" (click)="forceCloseModal()">
                {{ "PROPERTIES.Otkazi" | translate }}
            </button>
            <button [disabled]="saving" (click)="onSubmit()" class="btn btn-primary">
                {{ "PROPERTIES.SpremiIzmjene" | translate }}
                @if (saving) {
                <i class="fa fa-spin fa-spinner mr-2"></i>
                }
            </button>
        </div>
    </form>

    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
        <ng-container *ngIf="control.hasError(validation) && control.touched">
            <small class="text-danger">{{ message | translate }}</small>
        </ng-container>
    </ng-template>
</div>

<!-- {{form.value |json}} -->