import { BaseModel } from "./base.model";
import { kontniPlanDto } from "./financije/kontniPlanDto";

export class AmortizacijskeSkupineDto  extends BaseModel {
    sifra: number;
    naziv: string;
    konto_sredstva: string;
    konto_amortizacije: string;
    konto_trosska_amortizacije: string;
    konto_revalorizacije: string;
    kontaSredstva: kontniPlanDto;
    kontaAmortizacije: kontniPlanDto;
    kontaTrosskaAmortizacije: kontniPlanDto;
    kontaRevalorizacije: kontniPlanDto;
}