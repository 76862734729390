          <form class="form-inline custom-datepicker custom-datepicker-dropdown">
            <div class="form-group mb-0">
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="modelFooter" ngbDatepicker
                  [footerTemplate]="footerTemplate" #f="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn calendar" (click)="f.toggle()" type="button"><i
                      class="fa fa-calendar"></i></button>
                </div>
              </div>
            </div>
          </form>
          <ng-template #footerTemplate>
            <hr class="my-0">
            <button class="btn btn-primary btn-sm m-2 float-left" (click)="model = today; f.close()">Today</button>
            <button class="btn btn-secondary btn-sm m-2 float-right" (click)="f.close()">Close</button>
          </ng-template>

  