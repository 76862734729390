export const SuperAdmin = 'SuperAdmin'
export const Admin = 'Admin'
export const AdminFirme = 'AdminFirme'
export const Korisnik = 'Korisnik'
export const Tester = 'Tester'
export const BetaKorisnik = 'BETA KORISNIK'

export const AllRoles: string[] = [SuperAdmin, Admin, AdminFirme, Korisnik, Tester, BetaKorisnik]

export enum AccountTypes {
    SuperAdmin = 1,
    Admin = 2,
    AdminFirme = 3,
    Korisnik = 4,
    Tester = 5
}