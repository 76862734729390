import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FiskusFieldService } from 'src/app/shared/services/fiskus-field.service';
import { ConfigurationsService } from 'src/app/_services/services/configurations.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FFObilasciService } from 'src/app/shared/services/fiskusfield/ff-obilasci.service';
import { ffTipoviPoljaEnum } from 'src/app/_constants/ff-tipovi-polja-constants';
import { ArticlesService } from 'src/app/shared/services/articles.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/shared/services/account.service';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-obilazak-podaci-modal',
  templateUrl: './obilazak-podaci-modal.component.html',
  styleUrls: ['./obilazak-podaci-modal.component.scss']
})
export class ObilazakPodaciModalComponent implements OnInit {
  @Input() updateInputValue = new EventEmitter();
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  infoContent: string;

  autoBr;
  podaci;
  markerPosition
  fotografija;
  potpis;
  memorandum;
  pdfExporting: boolean = false;
  sendingMail: boolean = false;
  loading: boolean = true;
  tipoviPolja = ffTipoviPoljaEnum;
  savingFile: boolean = false;
  center: google.maps.LatLngLiteral = {
    lat: 43,
    lng: 18
  };
  zoom = 7;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    clickable: true,
    animation: google.maps.Animation.DROP,
  };
  artikliArray;
  articles;
  fakturaObilazak;

  options: google.maps.MapOptions = {
    disableDefaultUI: false,
    zoomControl: true,
    mapTypeControl: true,
    streetViewControl: true,
    fullscreenControl: true
  }


  constructor(
    private fiskusFieldService: FiskusFieldService,
    private sanitizer: DomSanitizer,
    private configurationService: ConfigurationsService,
    public bsModalRefff: BsModalRef,
    private ffObilasciService: FFObilasciService,
    private articlesService: ArticlesService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private accountService: AccountService,
    private faktureService: InvoiceService,
  ) { }

  ngOnInit(): void {
    this.getObilazak();
    this.getMemorandum();
    this.getAllArticles();
  }

  get obilazak() {
    return this.podaci;
  }

  getAllArticles() {
    this.articlesService.getAllArticles().subscribe(resp => {
      this.articles = resp;

      this.articles = this.articles.map((o) => {
        o.search_label = ` ${o.sifra!} ${o.barKod!} ${o.naziv!} ${o.katalosskiBrojevi!} ${o.katalosskiBroj2!}
         ${o.katalosskiBroj3!} ${o.nPodgrupa!} ${o.nPDVTarifa!} ${o.nDobavljac!} `;
        return o;
      });
    })
  }

  getObilazak() {
    var payload = {
      autoBr: this.autoBr
    }
    this.ffObilasciService.getById(payload).subscribe(resp => {
      this.loading = false;
      this.podaci = resp.body.result;

      this.podaci.ffObilasciPodaci.forEach(element => {
        if (element.ffPolja.tipPolja == this.tipoviPolja.Fotografija ||
          element.ffPolja.tipPolja == 7) {
          if (this.podaci.ffObilasciFiles?.length == 0) {
            this.getFotografija(element.autoBr);
          }
        }
        if (element.ffPolja.tipPolja == 6) {
          if (element.potpis != '') {
            this.potpis = element.potpis;
          }
          else {
            this.getPotpis(element.autoBr);
          }
        }

        if (element.ffPolja.tipPolja == this.tipoviPolja.Artikli) {
          this.artikliArray = JSON.parse(element.vrijednost);
          this.getFakturaFromObilazak();
        }
      });

      this.markerPosition = {
        lat: parseFloat(this.podaci?.latitude),
        lng: parseFloat(this.podaci?.longitude),
      };
      if (this.markerPosition != null) {
        this.zoom = 17;
        this.center = this.markerPosition
      }
    });
  }

  closeModal() {
    this.bsModalRefff.hide();
  }

  forceCloseModal() {
    this.updateInputValue.emit(false);
    this.bsModalRefff.hide();
  }

  getFotografija(autobr) {
    this.fiskusFieldService.getFotografija(autobr).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/jpg' });
      const url = window.URL.createObjectURL(blob);
      this.fotografija = this.sanitizer.bypassSecurityTrustUrl(url);
      // window.open(url);
    });
  }

  getPotpis(autobr) {
    this.fiskusFieldService.getPotpis(autobr).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/jpg' });
      const url = window.URL.createObjectURL(blob);
      this.potpis = this.sanitizer.bypassSecurityTrustUrl(url);
      // window.open(url);
    });
  }

  getFakturaFromObilazak() {
    var payload = {
      autoBr: this.obilazak.autoBr,
      pj: this.accountService.context.businessUnit
    };
    this.faktureService.getFakturaByObilazak(payload).subscribe(resp => {
      this.fakturaObilazak = resp.body.result;
    })
  }

  exportPdf() {
    this.pdfExporting = true;
    this.ffObilasciService.getPDFObilazak(this.obilazak.autoBr).subscribe(resp => {
      this.pdfExporting = false;
      const blob = new Blob([resp.body], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);

      let fileName = 'file';
      const contentDisposition = resp.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      var anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    })
  }

  getMemorandum() {
    this.configurationService.getMemorandum().subscribe(resp => {
      const blob = new Blob([resp], { type: 'application/jpg' });
      const url = window.URL.createObjectURL(blob);
      this.memorandum = this.sanitizer.bypassSecurityTrustUrl(url);
    })
  }

  vrijednostFormatted(item) {
    var vrijednost = item.vrijednost;
    if (item.ffPolja.ffTipoviPolja.naziv == "DA/NE") {
      vrijednost = (vrijednost == "true") ? "DA" : "NE"
    }

    if (item.ffPolja.tipPolja == this.tipoviPolja.Tag) {
      vrijednost = vrijednost.replaceAll(";", ", ");
    }

    return (vrijednost == null) ? '-' : vrijednost;
  }

  getFile(file) {
    this.savingFile = true;
    this.ffObilasciService.getFile(file.filename).subscribe((res) => {
      this.savingFile = false;
      const blob = new Blob([res.body], {
        type: res.body.type,
      });
      const url = window.URL.createObjectURL(blob);

      let fileName = 'file';
      const contentDisposition = res.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      window.open(url);
    }, error => {
      this.savingFile = false;
    });
  }

  findArticleName(sifra: number): string {
    return this.articles.find((article) => article.sifra == sifra)?.naziv;
  }

  sendMail() {
    this.sendingMail = true;
    var payload = {
      autoBr: this.obilazak.autoBr
    };
    this.ffObilasciService.sendObilazakPDF(payload).subscribe(resp => {
      this.toastr.success(this.translate.instant('MESSAGE.Mail uspješno poslan'));
      this.sendingMail = false;
      this.closeModal();
    }, error => {
      if (error.error.errors[0].includes('unesite email za')) {
        this.sendingMail = false;
        this.askToEditKomitent()
      }
    })
  }

  callViber() {
    var phone = this.getPhoneNumber();
    if (phone == "") return;
    window.open(`viber://chat?number=${phone}`);
  }

  callPhone() {
    var phone = this.getPhoneNumber();
    if (phone == "") return;
    window.open(`tel:${phone}`);
  }

  callWhatsapp() {
    var phone = this.getPhoneNumber();
    if (phone == "") return;
    window.open(`https://api.whatsapp.com/send?phone=${phone}`);
  }

  getPhoneNumber() {
    var phoneNo: string = this.obilazak.komitenti.telefon;

    if (phoneNo == undefined || phoneNo == null || phoneNo == '') {
      this.toastr.error(this.translate.instant("MESSAGE.Nije unešen broj telefona za komitenta"))
      return "";
    }

    if (phoneNo.includes("+")) {
      phoneNo = phoneNo.replace("+", "00");
    }
    if (phoneNo.startsWith("387")) {
      phoneNo = phoneNo.replace("387", "00387");
    }
    if (phoneNo.startsWith("061") || phoneNo.startsWith("062") ||
      phoneNo.startsWith("063") || phoneNo.startsWith("064") ||
      phoneNo.startsWith("065") || phoneNo.startsWith("066")) {
      phoneNo = phoneNo.replace("06", "003876");
    }

    //viber i whatsapp trebaju biti +  umjesto 00 na androidu
    if (phoneNo.startsWith("00")) {
      phoneNo = phoneNo.replace("00", "%2B");
    }

    return phoneNo;
  }

  kreirajFakturuIzObilaska() {
    Swal.fire({
      title: this.translate.instant("MESSAGE.Faktura iz obilaska"),
      text: this.translate.instant("MESSAGE.Želite li kreirati fakturu iz obilaska") + `?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant("DA"),
      cancelButtonText: this.translate.instant("NE"),
    }).then(result => {
      if (result.isConfirmed) {
        var payload = {
          autoBr: this.obilazak.autoBr,
          poslovnaJedinica: this.accountService.context.businessUnit,
          dokument: 11,
        };
        this.ffObilasciService.fakturaIzObilaska(payload).subscribe(resp => {
          this.forceCloseModal();
          this.askForCheckInvoice(resp.body.result.item.autoBr);
        }, error => {
          if (!error.error) {
            this.toastr.error(error);
          }
        });
      }
    });
  }

  askForCheckInvoice(autoBr) {
    Swal.fire({
      title: this.translate.instant("MESSAGE.Proces završen"),
      text: this.translate.instant("MESSAGE.Želite li pregledati kreiranu fakturu?"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant("DA"),
      cancelButtonText: this.translate.instant("NE"),
    }).then(result => {
      if (result.isConfirmed) { window.open('/invoice/preview/' + autoBr); }
    });
  }

  openInvoiceNewTab() {
    window.open("/invoice/preview/" + this.fakturaObilazak.autoBr)
  }

  openObilasciKomitent() {
    window.open("/fiskus-field/obilasci/0/0/" + this.obilazak.komitent + "/0/0")
  }

  openObilasciForma() {
    window.open("/fiskus-field/obilasci/0/0/0/0/" + this.obilazak.tipForme)
  }

  openObilasciOperater() {
    window.open("/fiskus-field/obilasci/0/0/0/" + this.obilazak.operater + "/0")
  }

  openKomitent() {
    window.open("/clients/update/" + this.obilazak.komitent)
  }

  askToEditKomitent() {
    Swal.fire({
      title: this.translate.instant("MESSAGE.Nije unešen email za komitenta") + ". ",
      text: this.translate.instant("MESSAGE.Želite li unijeti email") + "?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.translate.instant("DA"),
      cancelButtonText: this.translate.instant("NE"),
    }).then(result => {
      if (result.isConfirmed) { window.open("/clients/update/" + this.obilazak.komitent) }
    });
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();
    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    }
    else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        if (direction[0] < 0) {
          //next
          this.forceCloseModal();
        } else {
          //previous
          this.forceCloseModal();
        }
      }
    }
  }

  openInfo(marker: MapMarker, content: string) {
   /*  this.infoContent = content;
    this.infoWindow.open(marker); */
  }
}
