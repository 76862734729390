import { ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class FormHelper {

  static generateFormData(object): FormData {
    const formData = new FormData();
    const entries = Object.entries(object);
    entries.forEach(entry => {
      FormHelper.addToPayload(formData, entry[1], entry[0]);
    });
    return formData;
  }

  private static addToPayload(formData: FormData, values: any, name: string) {
    if (!values && values != 0 && name) {
      formData.append(name, '');
    } else {
      if (values instanceof File) {
        formData.append(name, values, values.name);
      } else if (typeof values == 'object') {
        for (const key in values) {
          if (typeof values[key] == 'object') {
            FormHelper.addToPayload(formData, values[key], name + '[' + key + ']');
          } else {
            formData.append(name + '[' + key + ']', values[key]);
          }
        }
      } else {
        formData.append(name, values);
      }
    }
    return formData;
  }

  static handleFormInvalid(formGroupRef: UntypedFormGroup, formElement: ElementRef | HTMLElement): void {
    formGroupRef.markAllAsTouched();
    formGroupRef.updateValueAndValidity();
    FormHelper.focusError(formElement);
  }

  static focusError(formElement: ElementRef | HTMLElement): void {
    let invalidControl;
    if ((formElement as ElementRef).nativeElement) {
      invalidControl = (formElement as ElementRef).nativeElement.querySelector('.ng-invalid');
    } else {
      invalidControl = (formElement as HTMLElement).querySelector('.ng-invalid');
    }
    if (invalidControl) {
      invalidControl.focus();
      FormHelper.scrollIntoView(invalidControl);
    }
  }

  static focusField(formElement: ElementRef | HTMLElement): void {
    let invalidControl;
    if ((formElement as ElementRef).nativeElement) {
      invalidControl = (formElement as ElementRef).nativeElement.querySelector('.ng-invalid');
    } else {
      invalidControl = formElement; //(formElement as HTMLElement).querySelector('.ng-invalid');
    }
    if (invalidControl) {
      invalidControl.focus();
      FormHelper.scrollIntoView(invalidControl);
    }
  }

  static hasValidator(control: AbstractControl, validatorName: string): boolean {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator[validatorName]) {
        return true;
      }
    }
    if (control['controls']) {
      for (const controlName in control['controls']) {
        if (control['controls'][controlName]) {
          if (FormHelper.hasValidator(control['controls'][controlName], controlName)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private static scrollIntoView(el, attempt = 0): boolean {
    if (el) {
      el.scrollIntoView({
        block: 'center'
      });
      return true;
    }
    if (attempt < 3) {
      setTimeout(() => {
        this.scrollIntoView(el, ++attempt);
      }, 1000);
    }
    return false;
  }

}
