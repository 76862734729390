import { Routes } from '@angular/router';
import { Error404Component } from 'src/app/pages/error/error404/error404.component';
import { ContentComponent } from '../components/layout/content/content.component';
import { AuthGuard } from '../guard/auth.guard';
import {
  Korisnik,
  SuperAdmin,
  Tester,
} from '../../_constants/ApplicationRoles';
import { RoleGuard } from '../guard/role.guard';
import {
  ARTIKLI_PREVIEW,
  KOMITENTI_PREVIEW,
  STOCK_LIST_PREVIEW,
  USER_ADMIN_PREVIEW,
} from './permissions';

export const childRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'invoice',
    data: {
      roles: [Korisnik, Tester],
    },
    loadChildren: () =>
      import('../../pages/invoice/invoice.module').then((m) => m.InvoiceModule),
  },
  {
    path: '',
    data: {
      permissions: [ARTIKLI_PREVIEW, KOMITENTI_PREVIEW, STOCK_LIST_PREVIEW],
    },
    loadChildren: () =>
      import('../../pages/menus/menu.module').then((m) => m.MenuModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./../../pages/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
  },
  {
    path: '',
    data: {
      permissions: [USER_ADMIN_PREVIEW],
    },
    loadChildren: () =>
      import(
        '../../pages/users-administration/usersAdministration.module'
      ).then((m) => m.UsersAdministrationModule),
  },


  {
    path: '',
    data: {
      permissions: [USER_ADMIN_PREVIEW],
    },
    loadChildren: () =>
      import(
        '../../pages/email-log/email-log.module'
      ).then((m) => m.EmailLogModule),
  },

  {
    path: '',
    data: {
      permissions: [USER_ADMIN_PREVIEW],
    },
    loadChildren: () =>
      import(
        '../../pages/buy-log/buy-log.module'
      ).then((m) => m.BuyLogModule),
  },

  {
    path: '',
    data: {
      permissions: [USER_ADMIN_PREVIEW],
    },
    loadChildren: () =>
      import(
        '../../pages/roles-administration/roles-administration.module'
      ).then((m) => m.RolesAdministrationModule),
  },

  {
    path: '',
    data: {
      permissions: [USER_ADMIN_PREVIEW],
    },
    loadChildren: () =>
      import(
        '../../pages/firms-administration/firms-administration.module'
      ).then((m) => m.FirmsAdministrationModule),
  },

  {
    path: '',
    loadChildren: () =>
      import('./../../pages/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },


  {
    path: 'financije',

    loadChildren: () =>
      import('./../../pages/financije/financije.module').then(
        (m) => m.FinancijeModule
      ),
  },

  {
    path: 'import-data',
    loadChildren: () =>
      import('./../../pages/import-document/import-document.module').then((m) => m.ImportDocumentModule),
  },

  {
    path: 'woo-sync',
    loadChildren: () =>
      import('./../../pages/woo-sync/woo-sync.module').then((m) => m.WooSyncModule),
  },

  {
    path: 'licence',
    loadChildren: () =>
      import('./../../pages/licence/licence.module').then((m) => m.LicenceModule),
  },

  {
    path: 'fiskus-field',
    loadChildren: () =>
      import('./../../pages/fiskus-field/fiskus-field.module').then((m) => m.FiskusFieldModule),
  },

  {
    path: 'fiskus-field',
    loadChildren: () =>
      import('./../../pages/fiskus-field/pages/ff-polja/ff-polja.module').then((m) => m.FfPoljaModule),
  },

  {
    path: 'fiskus-field',
    loadChildren: () =>
      import('./../../pages/fiskus-field/pages/ff-tipovi-formi/ff-tipovi-formi.module').then((m) => m.FfTipoviFormiModule),
  },

  {
    path: 'fiskus-field',
    loadChildren: () =>
      import('./../../pages/fiskus-field/pages/ff-tasks/ff-tasks.module').then((m) => m.FfTasksModule),
  },

  {
    path: 'fiskus-field',
    loadChildren: () =>
      import('./../../pages/fiskus-field/pages/ff-tagovi/ff-tagovi.module').then((m) => m.FfTagoviModule),
  },

  {
    path: 'fiskus-field',
    loadChildren: () =>
      import('./../../pages/fiskus-field/pages/ff-calendar/ff-calendar.module').then((m) => m.FfCalendarModule),
  },

  {
    path: 'pdv',
    loadChildren: () =>
      import('./../../pages/pdv/pdv.module').then((m) => m.PdvModule),
  },

  {
    path: 'infos',
    loadChildren: () =>
      import('./../../pages/infos/infos.module').then((m) => m.InfosModule),
  },
  {
    path: 'rim',
    loadChildren: () =>
      import('./../../pages/rim/rim.module').then((m) => m.RimModule),
  },

  {
    path: 'financije',
    loadChildren: () =>
      import('./../../pages/financije//fin-dokumenti/fin-dokumenti.module').then((m) => m.FinDokumentiModule),
  },

  {
    path: 'financije',
    loadChildren: () =>
      import('./../../pages/financije/fin-nalozi/fin-nalozi.module').then((m) => m.FinNaloziModule),
  },


  {
    path: 'financije',
    loadChildren: () =>
      import('./../../pages/financije/fin-nalozi-podaci/fin-nalozi-podaci.module').then((m) => m.FinNaloziPodaciModule),
  },

  {
    path: 'financije',
    loadChildren: () =>
      import('./../../pages/financije/fin-auto-knjizenja-robno/fin-auto-knjizenja-robno.module').then((m) => m.FinAutoKnjizenjaRobnoModule),
  },

  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./../../pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },

  {
    path: 'faq',
    loadChildren: () =>
      import('./../../pages/faq/faq.module').then((m) => m.FaqModule),
  },

  {
    path: 'dms',
    loadChildren: () =>
      import('./../../pages/dms/dms-tipovi/dms-tipovi.module').then((m) => m.DmsTipoviModule),
  },

  {
    path: 'dms',
    loadChildren: () =>
      import('./../../pages/dms/dms-infos/dms-infos.module').then((m) => m.DmsInfosModule),
  },

  {
    path: 'dms',
    loadChildren: () =>
      import('./../../pages/dms/dms-list/dms-list/dms-list.module').then((m) => m.DmsListModule),
  },

  {
    path: 'financije',
    loadChildren: () =>
      import('./../../pages/financije/fin-projekt/fin-projekt.module').then((m) => m.FinProjektModule),
  },
  {
    path: 'amortizacijske-skupine',
    loadChildren: () =>
      import('./../../pages/menus/amortizacijske-skupine/amortizacijske-skupine.module').then((m) => m.AmortizacijskeSkupineModule),
  },
  {
    path: 'stalna-sredstva',
    loadChildren: () =>
      import('./../../pages/menus/stalna-sredstva/stalna-sredstva.module').then((m) => m.StalnaSredstvaModule),
  },
  {
    path: 'obracuni-placa',
    loadChildren: () =>
      import('./../../pages/menus/hr/hr.module').then((m) => m.HrModule),
  },

];

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/default',
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./../../pages/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: '',
    component: ContentComponent,
    children: childRoutes,
    canActivate: [AuthGuard, RoleGuard],
    canActivateChild: [AuthGuard, RoleGuard],
    // data: {
    //  role: [SuperAdmin, Admin, AdminFirme, Korisnik]
    // }
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./../../pages/error/error.module').then((m) => m.ErrorModule),
  },

  {
    path: 'buy',
    loadChildren: () =>
      import('./../../pages/buy/buy.module').then((m) => m.BuyModule),
  },



  {
    path: '**',
    component: Error404Component,
  },
];
