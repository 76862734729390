<div class="card card-body mb-0" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">

    <google-map [center]="center" [zoom]="zoom" width="auto" [options]="options">
        <map-marker #somemarker="mapMarker" [position]="markerPosition" [options]="markerOptions"
            (mapClick)="openInfo(somemarker, 'marker.info')"></map-marker>

        <map-info-window>{{ infoContent }}</map-info-window>
    </google-map>

    <div class="col-12 text-right mt-4 mb-4">
        <button class="btn btn-primary mr-2" (click)="sendMail()" type="button">
            <i *ngIf="!sendingMail" class="fa fa-mail-forward fa-fw fa-lg mr-2"></i>
            <i *ngIf="sendingMail" class="fa fa-spin fa-spinner mr-2"></i> e-mail
        </button>

        <button class="btn btn-primary mr-2" (click)="exportPdf()" type="button">
            <i *ngIf="!pdfExporting" class="fa fa-file-pdf-o fa-fw fa-lg mr-2"></i>
            <i *ngIf="pdfExporting" class="fa fa-spin fa-spinner mr-2"></i> PDF
        </button>
    </div>

    <div class="row" *ngIf="podaci" id="report-export">
        <div class="col-12 obilazak" id="report-export-memorandum" hidden>
            <div class="col-12" *ngIf="memorandum">
                <div class="form-group">
                    <img [src]='memorandum' class="oversize" />
                </div>
            </div>
        </div>

        <div class="col-12 obilazak">
            <h6 class="sub-title mt-0 text-center">{{"PROPERTIES.FieldObilazak" | translate}}</h6>
        </div>

        <div class="col-12 form-group">
            <label class="font-weight-bold"> {{"PROPERTIES.Komitent" | translate}}
                <i class="icofont icofont-brand-viber ml-2" (click)="callViber()"> Viber</i>
                <i class="icofont icofont-brand-whatsapp ml-2" (click)="callWhatsapp()"> Whatsapp</i>
                <i class="fa fa-phone ml-2" (click)="callPhone()"> {{"Mobilni" | translate }}</i>
            </label>
            <div class="input-group custom-group">
                <input class="form-control digits" disabled value="{{obilazak?.komitenti?.komitent}}" />

                <div class="input-group-append">
                    <span class="input-group-text p-0">
                        <button tabindex="-1" (click)="openObilasciKomitent()" type="button" class="btn btn-link btn-sm"
                            [ngbTooltip]="'PROPERTIES.Pregledaj obilaske za komitenta' | translate">
                            <i class="fa fa-angle-double-right txt-primary"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-12 form-group">
            <label class="font-weight-bold"> {{"PROPERTIES.Forma" | translate}} </label>

            <div class="input-group custom-group">
                <input class="form-control digits" disabled value="{{obilazak?.ffTipoviFormi?.naziv}}" />
                <div class="input-group-append">
                    <span class="input-group-text p-0">
                        <button tabindex="-1" (click)="openObilasciForma()" type="button" class="btn btn-link btn-sm"
                            [ngbTooltip]="'PROPERTIES.Pregledaj obilaske za formu' | translate">
                            <i class="fa fa-angle-double-right txt-primary"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
            <label class="font-weight-bold"> {{"PROPERTIES.Operator" | translate}} </label>
            <div class="input-group custom-group">
                <input class="form-control digits" disabled value="{{obilazak?.user}}" />
                <div class="input-group-append">
                    <span class="input-group-text p-0">
                        <button tabindex="-1" (click)="openObilasciOperater()" type="button" class="btn btn-link btn-sm"
                            [ngbTooltip]="'PROPERTIES.Pregledaj obilaske za operatera' | translate">
                            <i class="fa fa-angle-double-right txt-primary"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
            <label class="font-weight-bold"> {{"PROPERTIES.Datum" | translate}} </label>
            <input class="form-control digits" disabled
                value="{{obilazak.datumIVrijeme | date: 'dd.MM.yyyy. HH:mm:ss'}}" />
        </div>

        <ng-container *ngFor="let item of podaci.ffObilasciPodaci">
            @if (item.ffPolja.tipPolja == '1' || item.ffPolja.tipPolja == '2' ||
            item.ffPolja.tipPolja == '3' || item.ffPolja.tipPolja == '4') {
            <div class="col-12">
                <div class="form-group">
                    <label class="font-weight-bold"> {{item?.ffPolja?.naziv}} </label>
                    <div class="form-group">
                        <div *ngIf="vrijednostFormatted(item).length  <= 50">
                            <input class="form-control digits" disabled value="{{vrijednostFormatted(item)}}" />
                        </div>

                        <div *ngIf="vrijednostFormatted(item).length  > 50">
                            <textarea class="form-control digits" disabled rows="4"
                                value="{{vrijednostFormatted(item)}}"></textarea>
                        </div>

                    </div>
                </div>
            </div>
            }
            @else if (item.ffPolja.tipPolja == tipoviPolja.Datum) {
            <div class="col-12">
                <div class="form-group">
                    <label class="font-weight-bold"> {{item?.ffPolja?.naziv}} </label>
                    <div class="form-group">
                        <input class="form-control digits" disabled
                            value="{{vrijednostFormatted(item) | date: 'dd.MM.yyyy. HH:mm:ss'}}" />
                    </div>
                </div>
            </div>
            }

            @else if (item.ffPolja.tipPolja == tipoviPolja.Tag) {
            <div class="col-12">
                <div class="form-group">
                    <label class="font-weight-bold"> {{item?.ffPolja?.naziv}} </label>
                    <div class="form-group">
                        <input class="form-control digits" disabled value="{{vrijednostFormatted(item) }}" />
                    </div>
                </div>
            </div>
            }

            @else if (item.ffPolja.tipPolja == tipoviPolja.Potpis) {
            <div class="col-12">
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Potpis" | translate}} </label>
                    <img class="oversize" [src]='potpis' alt="" />
                </div>
            </div>
            <p style="page-break-after: always;">&nbsp;</p>
            <p style="page-break-before: always;">&nbsp;</p>
            }

            @else if (item.ffPolja.tipPolja == tipoviPolja.Fotografija || item.ffPolja.tipPolja == '7') {
            <div class="col-12">
                @if (fotografija) { <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Fotografija" | translate}} </label>
                    <img [src]='fotografija' class="oversize" />
                </div>
                }

                @if (this.podaci.ffObilasciFiles?.length > 0) {
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Dokumenti" | translate}} </label>
                </div>

                <div class="file-content">
                    <div class="card">
                        <div class="card-body file-manager">
                            <ul class="files">
                                @for (file of this.podaci.ffObilasciFiles; track $index) {
                                <li class="file-box me-1" (click)="getFile(file)">
                                    <div role="button" class="file-top">
                                        @if (file.type == '.pdf') {
                                        <i class="fa fa-file-pdf-o txt-primary"></i>
                                        }

                                        @else if (item.type == '.xlsl' || item.type == '.xlsx') {
                                        <i class="fa fa-file-excel-o txt-primary"></i>
                                        }

                                        @else if (item.type == '.doc' || item.type == '.docx')
                                        {
                                        <i class="fa fa-file-word-o txt-primary"></i>
                                        }

                                        @else {
                                        <i class="fa fa-file-image-o txt-primary"></i>
                                        }

                                        @if (savingFile == false) {
                                        <i class="fa fa-download txt-primary ml-2 mt-1"></i>
                                        }
                                        @else {
                                        <i class="fa fa-spin fa-spinner txt-primary ml-2"></i>
                                        }

                                    </div>
                                    <div class="file-bottom">
                                        <h6>{{file.originalFileName}}</h6>
                                        <!--  <p class="mb-1">2.0 MB</p> -->
                                    </div>

                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                }

            </div>
            <p style="page-break-after: always;">&nbsp;</p>
            <p style="page-break-before: always;">&nbsp;</p>
            }

            @else if (item.ffPolja.tipPolja == tipoviPolja.Artikli) {
            <div class="col-12">
                <div class="form-group">
                    <label class="font-weight-bold"> {{item?.ffPolja?.naziv}} </label>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped my-custom-scrollbar">
                            <thead class="sticky-row">
                                <tr>
                                    <th class="item-column">
                                        <p class="p-2 mb-0 h6"> {{ "PROPERTIES.Artikal" | translate }}</p>
                                    </th>
                                    <th>
                                        <p class="p-2 mb-0 h6">{{ "PROPERTIES.Kolicina" | translate }}</p>
                                    </th>

                                </tr>
                            </thead>

                            <tbody class="tbody">
                                <tr *ngFor="let item of artikliArray; let i = index">
                                    <td class="text-left p-2">
                                        {{ findArticleName( item.artikal) }}
                                    </td>
                                    <td class="text-right p-2">
                                        {{ item.kolicccina }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        @if (fakturaObilazak) {
                        <div class="col-12 text-right mt-2 mb-0">
                            <button class="btn btn-primary" type="button" (click)="openInvoiceNewTab()">
                                <i class="fa fa-eye fa-lg fa-fw text-light"></i>
                                {{ "PROPERTIES.Pregledaj fakturu" | translate }}
                            </button>
                        </div>
                        }
                        @else {
                        <div class="col-12 text-right mt-2 mb-0">
                            <button class="btn btn-primary" type="button" (click)="kreirajFakturuIzObilaska()"
                                [disabled]="fakturaObilazak != null" [disableTooltip]="!fakturaObilazak"
                                [ngbTooltip]="'MESSAGE.Faktura iz obilaska već kreirana' | translate">
                                <i class="fa fa-angle-double-right fa-lg fa-fw text-light"></i>
                                {{ "PROPERTIES.Kreiraj fakturu" | translate }}
                            </button>
                        </div>
                        }

                    </div>
                </div>
            </div>
            }


        </ng-container>

        <div *ngIf="loading" class="loader-box text-center">
            <div class="loader-2"></div>
        </div>


        <div class="col-12 text-right mt-2 mb-0">
            <button class="btn btn-light mr-2" (click)="forceCloseModal()" type="button">
                {{"PROPERTIES.Zatvori" | translate}}
            </button>
        </div>

    </div>