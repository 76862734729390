import { replaceAllBH } from "./fiskusCF";

export function ValidationErrorsHelper(error: any) {
    var errors: string[] = [];
    var tmp = JSON.stringify(error);
    // var chars = { '{': '', '}': '', '[': '', ']': '' };

    tmp = tmp.replaceAll("{", "").replaceAll("}", "");
    tmp = tmp.replaceAll("[", "").replaceAll("]", "").replaceAll(/['"]+/g, " "); //replace double quotes

    errors = tmp.split(",");
    return errors;
}

export function validateValidationError(error: any, tip: string) {
    let validationErrorsDto: ValidationErrorsDto[] = [];
    var index = 0;

    for (var i = 0; i < error.length; i++) {
        try {
            var parts: string[] = error[i].split(':');

            var currentIndex: number = Number(
                error[i].substring(0, error[i].indexOf(':')).match(/\d+/g)?.toString()
            ); // izdvojiti 2 iz ovog stringa artikli2.Dobavljaccc : Obavezno polje

            var column = replaceAllBH(
                parts[0].substring(parts[0].indexOf('.') + 1)
            );
            var description = parts[1].trimStart().trimEnd(); // + ' - ' + parts[2]?.trimStart().trimEnd();
            var message = `Red ${currentIndex + 1}; Kolona ${column} - ${description}`;

            validationErrorsDto.push(new ValidationErrorsDto(currentIndex, column, description, message));

            index += 1;
        } catch (e) {
            index += 1;
        }
    }
    return validationErrorsDto;
}

export class ValidationErrorsDto {
    row: number;
    property: string;
    error: string;
    message: string;

    constructor(row: number, property: string, error: string, message: string) {
        this.row = row;
        this.property = property;
        this.error = error;
        this.message = message;
    }
}