import { ArticleModel, ArticleTypesModel } from '../models/article.model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiResponseFactory } from '../../models/response/apiResponseFactory';
import { BaseApiService } from './base-api.service';
import { SubGroupModel } from '../models/subGroup.model';

@Injectable({
  providedIn: 'root',
})
export class SubGroupService {
  constructor(private api: BaseApiService) {}

  getAllSubGroups(): Observable<SubGroupModel[]> {
    return this.api.get('podgrupe/all').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map(
            (article) => new SubGroupModel(article)
          );
        }
        return undefined;
      }),
      catchError(this.handleError<SubGroupModel[]>(`podgrupe/all`))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
