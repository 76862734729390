<div class="card card-body mb-0">
    <form [formGroup]="reportForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Od" | translate}} </label>
                    <div class="form-group">
                        <div>
                            <input formControlName="od" class="form-control digits" id="example-datetime-local-input"
                                type="datetime-local" />
                        </div>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                  control: this.reportForm.controls.od
                }">
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="form-group">
                    <label class="font-weight-bold"> {{"PROPERTIES.Do" | translate}} </label>
                    <div class="form-group">
                        <div>
                            <input formControlName="do" class="form-control digits" id="example-datetime-local-input"
                                type="datetime-local" />
                        </div>
                        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'VALIDATORS.PoljeNeSmijeBitiPrazno',
                  control: this.reportForm.controls.do
                }">
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-12 text-right mt-2 mb-0">
                <button class="btn btn-light mr-2" (click)="closeModal()" type="button">
                    {{"PROPERTIES.Otkazi" | translate}}
                </button>
                <button class="btn btn-primary" type="submit">
                    {{"PROPERTIES.SpremiIzmjene" | translate}}
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <small class="text-danger">{{ message }}</small>
    </ng-container>
</ng-template>