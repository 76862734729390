<app-breadcrumb [title]="'Form Wizard Three'" [items]="['Forms', 'Form Layout']" [active_item]="'Form Wizard Three'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Form Wizard with icon</h5>
        </div>
      
      </div>
    </div>
  </div>
</div>