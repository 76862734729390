import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { FFTasksDto } from '../../models/fiskus-field/ffTasksDto';
import { Observable, catchError, map, throwError } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { PagedResponse } from '../../models/paged-response.model';

@Injectable({
  providedIn: 'root'
})
export class FfTasksService {
  constructor(private api: BaseApiService) { }

  getDateRangeCurrentUser(params: any = {}): Observable<FFTasksDto[]> {
    return this.api.post('fiskus-field/tasks/date-range-current-user', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FFTasksDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FFTasksDto[]>(`fiskus-field/tasks/date-range-current-user`))
    );
  }

  getDateRangeAllUsers(params: any = {}): Observable<FFTasksDto[]> {
    return this.api.post('fiskus-field/tasks/date-range-all-users', params).pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FFTasksDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FFTasksDto[]>(`fiskus-field/tasks/date-range-all-users`))
    );
  }

  getPaged(params: any): Observable<PagedResponse<FFTasksDto>> {
    return this.api.post('fiskus-field/tasks', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FFTasksDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FFTasksDto(i));
        return pagedModel;
      })
    );
  }

  getPagedCurrentUser(params: any): Observable<PagedResponse<FFTasksDto>> {
    return this.api.post('fiskus-field/tasks/paged-current-user', params).pipe(
      map((response) => {
        const pagedModel = new PagedResponse<FFTasksDto>(
          response.body.result
        );
        pagedModel.items = pagedModel.items?.map((i) => new FFTasksDto(i));
        return pagedModel;
      })
    );
  }

  getById(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/tasks/by-id', params)
      .pipe(catchError(this.handleError<[]>(`fiskus-field/tasks/by-id`)));
  }

  create(params: any = {}): Observable<any> {
    return this.api
      .post('fiskus-field/tasks/create', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tasks/create')));
  }

  update(params: any = {}): Observable<any> {
    return this.api
      .post('fiskus-field/tasks/update', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tasks/update')));
  }

  delete(params: any): Observable<any> {
    return this.api
      .post('fiskus-field/tasks/delete', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tasks/delete')));
  }

  endTask(params: any = {}): Observable<any> {
    return this.api
      .post('fiskus-field/tasks/zavrsi-task', params)
      .pipe(catchError(this.handleError<[]>('fiskus-field/tasks/zavrsi-task')));
  }

  getUndodeCurentUser(): Observable<FFTasksDto[]> {
    return this.api.get('fiskus-field/tasks/unfinished-current-user').pipe(
      map((response: HttpResponse<apiResponseFactory>) => {
        if (response.status == 200 && response?.body?.result) {
          return response.body.result.map((client) => new FFTasksDto(client));
        }
        return undefined;
      }),
      catchError(this.handleError<FFTasksDto[]>(`fiskus-field/tasks/unfinished-current-user`))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;

      return throwError(error);
    };
  }
}
